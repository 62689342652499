import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import queryString from 'query-string';
import ag_logo from './assets/logo.png'

import Home from './routes/Home';
import Payment from './routes/Payment';
import Onboard from './routes/Onboard';


//LOGO for top menu bar
export const logo = (<img src={ag_logo} alt="AGLogo" height="48" style={{background: 'white', padding: 4, borderRadius: 8}}/>);



function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <Router>
            <Switch>

                <Route path='/' exact={true} render={ () => {
                    return <Redirect to="/actportal"/>;
                }}/>
              
                     
                <Route path='/actportal' exact={true} render={ (props) => {
                    return <Home/>;  //arrive to Access Granted portal                      
                }}/>

                <Route path='/payment' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    return <Payment accountNo={query.act} hmac={query.hm}/>;                       
                }}/>

                <Route path='/onboard' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    return <Onboard id={query.id} hmac={query.hm} extime={query.extime} database={query.database}/>;                       
                }}/>      
                        
            </Switch>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;


//Use this to send to an external website (safely!) in a new tab
export function ExternalLink(props) {
    
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.target}</a>;
    
}


//Be careful here! XSS attack possible with url.  Use only on same site.  newWindow.opener is not cleared by this function
export function OpenInNewTab(url) {
    const newWindow = window.open(url);
    newWindow.applicationTab = window;
}


export function OpenExternalInNewTab(url) {
    window.open(url, "_blank", "noopener,noreferrer");
}