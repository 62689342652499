import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Tooltip, Grid, Typography, Paper, IconButton, TextField, Checkbox, Button, CircularProgress, Link } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReplyIcon from '@material-ui/icons/Reply';
import LinkIcon from '@material-ui/icons/Link';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { ThemeColors } from '../Theme'
import { Account, AccountSnapshot, AccountAutoPayment, PayoutAct } from '../models/Account'
import { Global } from '../models/Global'
import { Subscription } from '../models/Subscription';
import { Fee } from '../models/Fee';
import { Tax1099 } from '../models/Tax1099';

import { TaxYearPopover } from '../components/TaxYearPopover';
import { SubscriptionSelectPopover } from '../components/SubscriptionSelectPopover';
import { FeeSelectPopover } from '../components/FeeSelectPopover';
import { AccountSnapshotView } from '../components/AccountSnapshotView';
import { PayoutActSelectPopover } from '../components/PayoutActSelectPopover';
import { NewConnectedAccountRequestPopover } from '../components/NewConnectedAccountRequestPopover';
import { AddressFields } from '../components/AddressFields';
import { CompleteTaxForm } from '../components/CompleteTaxForm';

import { RestComponent, Currency, DateUtils, ListSelectPopover, RichTextEditor, plaintextToRichTextJson, TextEntryPopover } from 'react-frontend-utils' 
import { OpenInNewTab, OpenExternalInNewTab } from '../App'



export class AccountPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 5,
            marginBottom: 10,
            color: ThemeColors.appBarBackground,
            fontSize: '14pt',
            flexGrow: 1
        },
        paper: {
            padding: 10
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 100,
            float: 'right',
            textTransform: 'uppercase',
            padding: 5, 
            marginRight: 10,
            marginTop: 2,
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20
        },
        containerLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        },
        sectionLabel: {
            fontSize: 16, 
            fontWeight: 'bold',
            color: ThemeColors.appBarBackground,
            marginLeft: 5
        },
        viewLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            paddingRight: 10,
            verticalAlign: 'center'
        }
    };
    
    _payoutCheckMailingAddress = React.createRef();
    _billingMailingAddress = React.createRef();
    _taxMailingAddress = React.createRef();

    _newHocId;

    constructor(props) {
        super(props);

        this.state.selectedAccount = null;        //The current Account object   
        this.state.accountSnapshot = null;
        this.state.accountAutoPayment = null;
        this.state.accountFeatures = null;
        this.state.subscriptionPopoverOpen = false;
        this.state.feePopoverOpen = false;
        this.state.accountRepSelectPopoverOpen = false;
        this.state.hocSelectPopoverOpen = false;
        this.state.feeSelectType = null;            //A FeeType enum
        this.state.availableSubscriptions = [];

        this.state.accountNotes = "";
        this.state.billingNotes = "";
        this.state.nextActionNotes = "";
        this.state.yearAcquired = 0;
        this.state.billingEmails = null;
        this.state.autoStatements = false;

        this.state.editingNotes = false;

        this.state.payoutMode = null;
        this.state.stripeConnectedAccountId = null;
        this.state.payoutAccounts = [];
        this.state.payoutAccountPopoverOpen = false;
        this.state.connectedPayoutAct = null;

        this.state.hocCCEmails = null;

        this.state.taxpayerID = "";
        this.state.taxClassification = null;
        this.state.exemptFrom1099 = false;

        this.state.refundLimit = null;

        this.state.requestNewAccountPopoverOpen = false;
        this.state.taxYearPopoverOpen = false;

        this.state.availableAccountReps = [];
        this.state.fetchingReps = false;

        this.state.availableHocs = [];
        this.state.fetchingHocs = false;

        this.state.accountState = null;
        this.state.isSeasonal = false;

        this.state.payoutBusy = false;      // when payout section is being updated
        this.state.billingBusy = false;     // when billing section is being updated

        this.state.collectTaxFormOpen = false;
    }
    

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("accountChangeEvent", this._accountHasChanged);

        if (!Global.isAGSAccountAdmin()) {  //add some padding because there's no large icon
            this.styles.tableData.paddingTop = 5;
            this.styles.tableData.paddingBottom = 5;
        }

        this._fetchAccount();

        if (Global.isAGSAccountAdmin())
            this._fetchConnectedAccountList();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("accountChangeEvent", this._accountHasChanged);
    }


    _clearAccount = () => {
        if ( this._payoutCheckMailingAddress.current)
            this._payoutCheckMailingAddress.current.clear();
        if (this._billingMailingAddress.current)
            this._billingMailingAddress.current.clear();
        if (this._taxMailingAddress.current)
            this._taxMailingAddress.current.clear();
        this.setState({selectedAccount: null, accountSnapshot: null, hocCCEmails: null, billingEmails: null, accountNotes: "", billingNotes: "", nextActionNotes: "",
                       yearAcquired: 0, autoStatements: false, payoutMode: null, stripeConnectedAccountId: null, accountState: null, isSeasonal: false,
                       taxMailingAddress: "", taxpayerID: "", taxClassification: "", exemptFrom1099: false, effectiveDate: null});
    }

    //callback when account has changed
    _accountHasChanged = () => {
        this._clearAccount();
        if (Global.accountID)  //has a new specific account
            this._fetchAccount();  
    }


    _fetchConnectedAccountList = () => {
        this.secureJSONFetch("/act/payoutAccounts", {}, this._fetchPayoutAccountsListCallback, this._fetchErrorCallback); 
    }


    _fetchPayoutAccountsListCallback = (response) => {
        if (response) {
            let payoutAccounts = response.map(json => new PayoutAct(json));
            payoutAccounts.sort((a, b) => a.compare(b));
            this.setState({payoutAccounts: payoutAccounts});    // set our available payout accounts
        }
    }

    _fetchAccount = () => {
        
        this.secureJSONFetch("/act/account/" + Global.accountID, {}, this._fetchAccountCallback, this._fetchErrorCallback); 

        this.secureJSONFetch("/act/account/" + Global.accountID + "/snapshot", {}, this._fetchAccountSnapshotCallback, this._fetchErrorCallback); 

        this.secureJSONFetch("/act/account/" + Global.accountID + "/features", {}, this._fetchAccountFeaturesCallback, this._fetchErrorCallback); 
        
    }

    _fetchAccountCallback = (response) => {
        if (response) {            
            const act = new Account(response);
            const taxInfo = act.currentTaxInfo();
            this.setState({selectedAccount: act, billingEmails: act.billingEmails, hocCCEmails: act.hocCCEmails, autoStatements: act.autoStatements,
                           accountNotes: act.frontendAccountNotes, billingNotes: act.frontendBillingNotes, nextActionNotes: act.frontendNextActionsNotes,
                           yearAcquired: act.frontendYearAcquired, payoutMode: act.payoutMode, stripeConnectedAccountId: act.stripeConnectedAccountId, 
                           refundLimit: act.resetRefundLimit, accountState: act.state, isSeasonal: act.seasonal,
                           taxpayerID: taxInfo.taxpayerID, taxClassification: taxInfo.taxClassification, exemptFrom1099: taxInfo.exemptFrom1099 ? true : false, 
                           effectiveDate: taxInfo.effectiveDate});

            this._payoutCheckMailingAddress.current.setPiecesFromString(act.payoutCheckMailingAddress);  // split the mailing address string into state elements for each piece
            this._billingMailingAddress.current.setPiecesFromString(act.billingMailingAddress);   
            this._taxMailingAddress.current.setPiecesFromString(taxInfo.taxMailingAddress);      

            this._fetchConnectedAccount(act.stripeConnectedAccountId);

            if (act.autoPaymentEnabled)
                this.secureJSONFetch("/act/account/" + Global.accountID + "/autoPay", {}, this._fetchAutoPaymentInfoCallback, this._fetchErrorCallback); 

        }            
    }

    _fetchAutoPaymentInfoCallback = (response) => {
        if (response) {
            const info = new AccountAutoPayment(response);
            this.setState({accountAutoPayment: info});
        }
    }



    _fetchAccountSnapshotCallback = (response) => {
        if (response) {            
            const snap = new AccountSnapshot(response);
            this.setState({accountSnapshot: snap});
        }            
    }

    _fetchAccountFeaturesCallback = (response) => {
        if (response) {            
            this.setState({accountFeatures: response});
        }            
    }
    

    _fetchErrorCallback = (error) => {
        this.setState({payoutBusy: false, billingBusy: false});
        this.showConfirmAlert("Error", error, 'red');
    }
  
 
  
    _checkEPassesCallback = (response) => {
        console.log(response)
        this.setState({ePassesEnabled: true});
    }

    _checkEPassesErrorCallback = () => {
        this.setState({ePassesEnabled: false});
    }

    _addSubscriptionButtonPressed = () => {
        this.secureJSONFetch("/act/subscription", {}, this._fetchSubscriptionsCallback, this._fetchErrorCallback); 
    }

    _fetchSubscriptionsCallback = (response) => {
        if (response) {
            let subscriptions = response.map(json => new Subscription(json));

            subscriptions = subscriptions.filter(subscription => !this.state.selectedAccount.hasSubscription(subscription));  //remove subscriptions we have already
            if (subscriptions.length === 0) {
                this.showConfirmAlert("Error", "Account already has all available subscriptions", 'red');
                return;
            }
            this.setState({availableSubscriptions: subscriptions}); 

            //show the popup, after state is updated
            setTimeout(() => {this.setState({subscriptionPopoverOpen: true})}, 0);
        }
    }

    //Add the selected subscription to the account, and refresh
    _addSubscription = (subscription) => {
        this.setState({subscriptionPopoverOpen: false});
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/subscription/" + subscription.name, {method: "POST"},
                             this._fetchAccount, this._fetchErrorCallback); 
    }


    _removeSubscription = (subscription) => {
        this.showConfirmAlert("Confirm", 
                              "Really remove Subscription \"" + subscription.name + "\" from this Account?",
                              'red',
                              "Cancel", 
                              () => this._deleteSubscription(subscription),
                              "Remove",
                              'red');
    }

    //Delete the selected subscription from the account, and refresh
    _deleteSubscription = (subscription) => {
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/subscription/" + subscription.name, {method: "DELETE"},
                             this._fetchAccount, this._fetchErrorCallback); 
    }


    //Fetch all the fees for the selected type
    _changeFeeButtonPressed = (feeType) => {
        this.setState({feeSelectType: feeType});
        this.secureJSONFetch("/act/fee?type=" + feeType.enumName, {},
                             this._fetchFeesCallback, this._fetchErrorCallback); 
    }

    _fetchFeesCallback = (response) => {
        if (response) {
            let fees = response.map(json => new Fee(json));
            this.setState({availableFees: fees}); 

            //show the popup, after state is updated
            setTimeout(() => {this.setState({feePopoverOpen: true})}, 0);
        }
    }

    //Switch the feeType, to the one specified in feeName (if null, use default)
    _switchFee = (feeType, feeName) => {
        this.setState({feePopoverOpen: false});
        const feeToSwitchTo = feeName ? feeName : "Default";
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/fee/" + feeType.enumName + "/" + feeToSwitchTo, {method: "POST"},
                             this._fetchAccount, this._fetchErrorCallback); 
    }


    _selectAccountRep = () => { 
        this.setState({fetchingReps: true});
        // fetch available account reps
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/availableReps", {},
                            this._selectAccountRepCallback, (error) => {this.setState({fetchingReps: false}); this._fetchErrorCallback(error);}); 
    }

    _selectAccountRepCallback = (response) => {
        this.setState({fetchingReps: false});
        // response is an array of strings, user-id, space, friendly name
        this.setState({availableAccountReps: response, accountRepSelectPopoverOpen: true});
    }


    _updateAccountRep = (selectedRep) => {
        this.setState({accountRepSelectPopoverOpen: false});

        const repId = selectedRep.split(' ')[0];   // the first piece is the ID (followed by friendly name)

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/accountRep", {method: "POST", body: repId},
                            this._updateAccountRepOrHocCallback, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _selectAccountHoc = () => {
        this.setState({fetchingHocs: true});
        // fetch available HOCs
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/availableHocs", {},
                            this._selectAccountHocCallback, (error) => {this.setState({fetchingHocs: false}); this._fetchErrorCallback(error);});
    }

    _selectAccountHocCallback = (response) => {
        this.setState({fetchingHocs: false});
        // response is an array of strings, user-id, space, friendly name
        this.setState({availableHocs: response, hocSelectPopoverOpen: true});
    }

    _updateHoc = (selectedHoc) => {
        this._newHocId = selectedHoc.split(' ')[0];   // the first piece is the ID (followed by friendly name)
        this.setState({hocSelectPopoverOpen: false, hocCommentPopupOpen: true});
    }

    _hocCommentOk = (comment) => {

        const body = {hocId: this._newHocId, comment: comment};

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/hoc", {method: "POST", body: JSON.stringify(body)},
                            this._updateAccountRepOrHocCallback, (error) => {this._fetchErrorCallback(error); this._fetchAccount()});
    }

    _updateAccountRepOrHocCallback = (response) => {
        this.showConfirmAlert("Success", "Account updated" , 'green'); 
        this.setState({hocCommentPopupOpen: false});
        this._fetchAccount();
    }

    _updateHocCC = () => {
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/hocCC", {method: "POST", body: this.state.hocCCEmails},
                            (response) => {this._updateCallback(); this._fetchAccount()}, 
                            (error) => {this._fetchErrorCallback(error); this._fetchAccount()});
    }

    
    _updateNotes = () => {
        this.setState({editingNotes: false});
        const updateBody = {accountNotes: this.state.accountNotes, billingNotes: this.state.billingNotes, nextActionNotes: this.state.nextActionNotes, yearAcquired: parseInt(this.state.yearAcquired)};

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/notes", {method: "POST", body: JSON.stringify(updateBody)},
                            this._updateCallback, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _updateCallback = () => {
        this.showConfirmAlert("Success", "Account updated" , 'green'); 
    }


    _updateBilling = () => {

        if (!this.state.autoStatements) {
            this.showConfirmAlert("Confirm", 
            "Auto-statements are off - this is not common. Monthly auto-pay and monthly electronic payouts will not work.",
            'red',
            "Cancel", 
            this._confirmUpdateBilling,
            "Update Anyway",
            'red');
        }
        else
            this._confirmUpdateBilling();
    }


    _confirmUpdateBilling = () => {

        if (!this._billingMailingAddress.current.isEmpty() && this._billingMailingAddress.current.isMissingEntries()) {
            this.showConfirmAlert("Really Apply New Billing Settings?", 
                                   "The mailing address is incomplete. To use community address, make all fields blank",
                                    'black',
                                    "Cancel", 
                                    this._confirmUpdateBillingFinal,
                                    "Continue",
                                    'red');
        }
        else
            this._confirmUpdateBillingFinal();
    }



    _confirmUpdateBillingFinal = () => {

        const billingInfo = {
            automaticStatementGeneration: this.state.autoStatements ? true : false,
            billingEmailString: this.state.billingEmails,
            billingMailingAddress: this._billingMailingAddress.current.toString()
        };

        this.setState({billingBusy: true});
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/billing", {method: "POST", body: JSON.stringify(billingInfo)},
                             this._updateBillingCallback, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _updateBillingCallback = () => {
        this.setState({billingBusy: false});
        this.showConfirmAlert("Success", "Billing information updated" , 'green'); 
        this._fetchAccount();
    }


    _updateTax = () => {

        const taxInfo = {
            taxClassification: this.state.taxClassification,
            taxMailingAddress: this._taxMailingAddress.current.toString(),
            exemptFrom1099: this.state.exemptFrom1099,
            effectiveDate: DateUtils.jsonDateString(DateUtils.startOfYear(), false)  // this is ignored when updating, but required
        };

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/tax?correction=true", {method: "POST", body: JSON.stringify(taxInfo)},
                             this._updateTaxCallback, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _showTaxHistory = () => {

        const history = this.state.selectedAccount.taxHistory.map((taxInfo, index) => {
            return <div key={index} style={{marginBottom: 20}}>
                        <div style={{fontWeight: 'bold'}}>Effective Date: {DateUtils.dateFormat(taxInfo.effectiveDate)}</div>
                        <div>Taxpayer ID: {taxInfo.taxpayerID}</div>
                        <div>Entity Name: {taxInfo.entity()}</div>
                        <div>Tax Classification: {taxInfo.taxClassification}</div>
                        <div>Tax Mailing Address: {taxInfo.formatAddress()}</div>
                        <div>Exempt from 1099: {taxInfo.exemptFrom1099 ? "Yes" : "No"}</div>
                        {taxInfo.signature ?
                            <Fragment>
                                <div>Signature on file:</div>
                                <img style ={{padding: 10}} src={taxInfo.signature} alt="Signature" height={100}/>
                            </Fragment>
                            : <div>No signature on file</div>
                        }
                    </div>;

        });
        const message = <span>{history}</span>;
      
        this.showConfirmAlert("Tax Form Submissions", message , 'black');
    }

    _updateTaxCallback = () => {
        this.showConfirmAlert("Success", "Tax information updated" , 'green'); 
        this._fetchAccount();
    }

    // Called when the user Ok's the tax year popover
    _taxYearPopoverOkCallback = (year, threshold) => {
        this.setState({taxYearPopoverOpen: false});
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/tax?taxYear=" + year, {},
                             this._showTaxDataCallback, this._fetchErrorCallback);
    }

    // The tax data response has several fields we don't care about, but includes a "taxData" field which is an array of TaxData objects. That object contains a field
    // "patronSales" which is a 
    _showTaxDataCallback = (response) => {
        if (response) {
            const tax1099 = new Tax1099(response);

            const list = tax1099.taxData.map((data, index) => {
                return <div key={index} style={{marginBottom: 30}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', color: ThemeColors.appBarBackground, gap: 60, marginBottom: 5}}>
                                <div>Taxpayer ID: {data.taxpayerID}</div>
                                <div>Effective Date: {DateUtils.dateFormat(data.effectiveDate)}</div>
                            </div>
                            {data.patronSales.map((month, index) => {
                                return <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div style={{width: 50}}>{DateUtils.monthNames[index]}</div> 
                                            <div style={{width: 100, textAlign: 'right'}}>$ {Currency.round(month.sales)}</div>
                                            <div style={{width: 200, textAlign: 'right'}}>{month.transactionCount} transactions</div>
                                        </div>;
                            })}
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', borderTop: '1px solid gray'}}>
                                <div style={{width: 50}}>Total</div>
                                <div style={{width: 100, textAlign: 'right'}}>$ {Currency.round(data.totalSales)}</div>
                                <div style={{width: 200, textAlign: 'right'}}>{data.totalTransactionCount} transactions</div>
                            </div>
                        </div>;
    
            });
            const message = <span>{list}</span>;
          
            this.showConfirmAlert("1099 Tax Totals for " + tax1099.taxYear, message , 'black');

        }
    }


    _updateState = () => {

        if (!this.state.accountState) {
            this.showConfirmAlert("Error", "No state selected", 'red');
            return;
        }

        this.showConfirmAlert("Really Apply New Account State?", 
                              "This may enable or disable the account in a few moments depending on the new state and the season",
                              'black',
                              "Cancel", 
                              this._confirmUpdateState,
                              "Continue",
                              'green');
    }

    _confirmUpdateState = () => {

        const newState = this.state.accountState.enumName;
        const seasonal = this.state.isSeasonal ? "true" : "false";

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/state?newState=" + newState + "&seasonal=" + seasonal, {method: "POST"},
                            this._updateStateCallback, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _updateStateCallback = () => {
        this.showConfirmAlert("Success", "State information updated. Account Features will take several minutes to update." , 'green'); 
        this._fetchAccount();
    }


    _needsConnectedAccount = () => {
        return Account.payoutModeIsElectronic(this.state.payoutMode) && !this.state.stripeConnectedAccountId;
    }

    _updatePayouts = (justActivatedNewPayoutAccount = false) => {

        // one or the other, both can't be true
        let message = null;
        if (this._needsConnectedAccount())
            message = "An electronic payment was selected but there is no connected account.";
        else if (Account.payoutModeIsMailedCheck(this.state.payoutMode) && this._payoutCheckMailingAddress.current.isMissingEntries())
            message = "An mailed check payment was selected the mailing address is incomplete.";


        if (message) {
            this.showConfirmAlert("Really Apply New Payout Settings?", 
                                    message,
                                    'black',
                                    "Cancel", 
                                    () => this._confirmUpdatePayout(justActivatedNewPayoutAccount),
                                    "Continue",
                                    'red');
        }
        else 
            this._confirmUpdatePayout(justActivatedNewPayoutAccount);
    }

    _confirmUpdatePayout = (justActivatedNewPayoutAccount) => {

        const payoutInfo = {
            payoutMode: this.state.payoutMode.enumName,
            connectedAccountId: this.state.stripeConnectedAccountId,
            checkMailingAddress: this._payoutCheckMailingAddress.current.toString()
        };

        this.setState({payoutBusy: true});
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/payout", {method: "POST", body: JSON.stringify(payoutInfo)},
                             (response) => this._updatePayoutsCallback(response, justActivatedNewPayoutAccount), 
                             (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _updatePayoutsCallback = (response, justActivatedNewPayoutAccount) => {
        this.setState({payoutBusy: false});

        if (justActivatedNewPayoutAccount) {

            const message = <span>
                                <div>Payout information updated.</div>
                                <div style={{marginTop: 5}}>Creating a Connected Account requires on-boarding with Stripe. Would you like to onboard with Stripe now?</div>
                                <div style={{marginTop: 10, color: 'gray', fontStyle: 'italic'}}>You can do this later or have someone else complete it by using the Link button anytime to create a unique on-boarding link.</div>
                            </span>;

            this.showConfirmAlert("Success", 
                   message,
                  'black',
                  "Cancel", 
                  this._onBoardNow,
                  "Onboard Now",
                  'green');
        }
        else
            this.showConfirmAlert("Success", "Payout information updated" , 'green'); 
        this._fetchAccount();
    }


    _fetchConnectedAccount = (id) => {
        if (id == null)
            this.setState({stripeConnectedAccountId: null, connectedPayoutAct: null});
        else
            this.secureJSONFetch("/act/payoutAccounts/" + id + "?accountNo=" + this.state.selectedAccount.accountNo, {}, 
                                 this._fetchPayoutActCallback, this._fetchErrorCallback); 
    }

    _fetchPayoutActCallback = (response) => {
        if (response) {
            const act = new PayoutAct(response);
            this.setState({connectedPayoutAct: act});    // set our available payout accounts and open the popup
        }
        else {
            this.setState({connectedPayoutAct: null});   // account no longer exists
            if (this.state.stripeConnectedAccountId)
                this.showConfirmAlert("Warning", "Payout account was deleted or no longer accessible.", 'red');
        }
    }


    _unenrollAutoPayment = () => {
        this.showConfirmAlert("Confirm Unenroll", 
                               "Automatic Payments will be discontinued. To re-enroll, the next time you make a payment, select \"Setup Automatic Payments\".",
                                'black',
                                "Cancel", 
                                this._unenrollAutoPaymentConfirm,
                                "Unenroll",
                                'red');
    }
    _unenrollAutoPaymentConfirm = () => {
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/unenrollAutoPayment", {method: "POST"},
                              this._fetchAccount, this._fetchErrorCallback); 
    }


    _enrollAutoPayment = () => {
        this.showConfirmAlert("Enroll in Auto Payment", 
                              "You can enroll in Auto-Payments the next time you make a payment. Would you like to go to the payment page now?",
                                'black',
                                "Cancel", 
                                this._gotoPayment,
                                "Goto Payment Page",
                                'green');
    }


    _requestNewConnectedAccount = (dba, countryCode) => {

        this.setState({requestNewAccountPopoverOpen: false});
        const body = {dba: dba, countryCode: countryCode};

        this.setState({payoutBusy: true});
        this.secureJSONFetch("/act/payoutAccounts/requestNew?database=" + this.state.selectedAccount.groupName, {method: "POST", body: JSON.stringify(body)},
                             this._requestNewConnectedAccountCallback, (error) => {this._fetchErrorCallback(error);}); 
    }    

    _requestNewConnectedAccountCallback = (response) => {
        this.setState({payoutBusy: false});
        if (response) {
            let newPayoutAccount = new PayoutAct(response);
            this._setPayoutAct(newPayoutAccount, false);       // update with the new account

            if (Global.isAGSAccountAdmin())
                this._fetchConnectedAccountList();        // update the list of accounts
            this._updatePayouts(true);
        }
    }

    _addConnectedAccount = () => {
        this.setState({requestNewAccountPopoverOpen: true});
    }

    _changeConnectedAccount = () => {
        this.setState({payoutAccountPopoverOpen: true});
    }

    _removePayoutAccount = () => {
        this.setState({stripeConnectedAccountId: null, connectedPayoutAct: null});
    }

    _setPayoutAct = (payoutAct, andFetch = true) => {
        this.setState({stripeConnectedAccountId: payoutAct.id, payoutAccountPopoverOpen: false, connectedPayoutAct: null});
        if (andFetch)
            this._fetchConnectedAccount(payoutAct.id);
    }
 

    _jumpToStripeAccount = () => {
        const istest = window.location.hostname.startsWith("localhost") ? "test/" : "";
        OpenExternalInNewTab("https://dashboard.stripe.com/" + istest + "connect/accounts/" + this.state.stripeConnectedAccountId);         
    }

    _jumpToStripeCustomer = (customer) => {
        const istest = window.location.hostname.startsWith("localhost") ? "test/" : "";
        OpenExternalInNewTab("https://dashboard.stripe.com/" + istest + "customers/" + customer);         
    }

    _onBoardNow = () => {
        this._getOnboardingLink(true);
    }

    _getOnboardingLink = (andVisit) => {
        this.secureJSONFetch("/act/payoutAccounts/" + this.state.stripeConnectedAccountId + "/onboardUrl?database=" + this.state.selectedAccount.groupName, {}, 
                            (response) => this._fetchOnboardingLinkCallback(response, andVisit), this._fetchErrorCallback); 
    }

    _fetchOnboardingLinkCallback = (response, andVisit) => {
        if (response) {
            const url = "https://" + window.location.hostname + ":" + window.location.port + "/onboard" + response;

            if (andVisit)
                OpenExternalInNewTab(url);
            else {
                const message = <span>
                                    <a href={url} target="_blank" rel="noreferrer">{url}</a>
                                    <div style={{marginTop: 10, color: 'gray', fontStyle: 'italic'}}>This link expires in 7 days.</div>
                                </span>;

                this.showConfirmAlert("Link Generated", message , 'green'); 
            }
        }
    }

    _deleteConnectedAccount = () => {
        this.showConfirmAlert("Confirm Delete Connected Account", 
                               this.state.stripeConnectedAccountId + " will be permanently deleted. Accounts assigned to this Payout Account will no longer receive automatic payouts.",
                                'black',
                                "Cancel", 
                                this._confirmDeleteConnectedAccount,
                                "Delete",
                                'red');
    }

    _confirmDeleteConnectedAccount = () => {
        this.setState({payoutBusy: true});
        this.secureJSONFetch("/act/payoutAccounts/" + this.state.stripeConnectedAccountId, {method: "DELETE"}, 
                             this._deleteConnectedAccountCallback, this._fetchErrorCallback);
    }

    _deleteConnectedAccountCallback = () => {
        this.setState({payoutBusy: false});
        this._fetchConnectedAccountList();
        this._fetchAccount();
    }


    _completeTaxForms = () => {
        const message = <span>
                            <div style={{marginBottom: 5, color: ThemeColors.appBarBackground, fontStyle: 'italic'}}>Why are we asking for this information?</div>
                            <div style={{marginBottom: 15}}>The IRS considers Access Granted Systems a 3rd-party payment processor if we accept payments from patrons 
                                on your behalf and remit them to you. We are required to send form <span style={{fontWeight: 'bold'}}>1099-K</span> to the IRS and a copy to you. The form 1099-K reports gross proceedes from patron payments.
                                We ask for this information to meet IRS reporting requirements. 
                            </div>
                            <div style={{marginBottom: 5, color: ThemeColors.appBarBackground, fontStyle: 'italic'}}>Who should complete this form?</div>
                            <div style={{marginBottom: 15}}>If you are using AGS to collect money from your patrons (through our Application portal, Marketplace, or patron billing services),
                                you should complete this form unless you are subject to one of the uncommon exceptions below. If you do not collect money from patrons, you do not need to complete this form.
                            </div>
                            <div style={{marginBottom: 5, color: ThemeColors.appBarBackground, fontStyle: 'italic'}}>Exceptions</div>
                            <div style={{marginBottom: 15}}>If one of the exceptions below applies to you, <span style={{color: 'red', fontWeight: 'bold'}}>STOP</span>. Do not complete this form. Contact your account representative for assistance.</div>
                            <ul/>
                                <li>Your entity is the United States or any of its agencies or instrumentalities, a US state, the District of Columbia, a US commonwealth, or any of their political subdivisions or instrumentalities.</li>
                                <li>You are a foreign entity (not a US individual, entity, or resident alien).</li>
                                <li>You are subject to IRS backup withholding because you have failed to report all interest and dividends on your tax return.</li>
                                <li>You do not have a Taxpayer Identification Number (TIN) or you have applied for one but not yet received it.</li>
                            <ul/>
                            <div style={{marginBottom: 5, color: ThemeColors.appBarBackground, fontStyle: 'italic'}}>I have already completed the form. Can I submit this form again?</div>
                            <div style={{marginBottom: 15}}>Submit this form only if you are changing your tax identification number (TIN). For corrections to your address or tax classification, simply edit that information directly on your account page.
                            </div>
                            <div style={{height: 20}}/>
                        </span>;

        this.showConfirmAlert("Please Read These Instructions Carefully", message , ThemeColors.appBarBackground,  "Cancel", 
                                this._confirmCompleteForm,
                                "Continue to Form",
                                'green');
    }

    _confirmCompleteForm = () => {
        this.setState({collectTaxFormOpen: true});
    }

    _exemptReasons = ["Government Entity", "Foreign Entity", "Exempt - Other"];

    _completeExemptTaxForm = (exemptReason) => {
        this.setState({exemptReasonPopoverOpen: false});

        const taxData = {
            taxpayerID: "00-0000000",
            tinType: "EIN",
            // semicolon seperated field: payee;line1;line2;city;state;zip;country
            taxMailingAddress: ";;;;;;",
            taxClassification: exemptReason,
            exemptFrom1099: true,
            effectiveDate: DateUtils.jsonDateString(DateUtils.startOfYear(), false),
            signature: null
        };

        this._taxFormCompleted(taxData);
    }


    _taxFormCompleted = (taxFormData) => {
        this.setState({collectTaxFormOpen: false});

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/tax?correction=false", {method: "POST", body: JSON.stringify(taxFormData)},
                                 this._updateTaxFormCallback, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _updateTaxFormCallback = () => {
        this.showConfirmAlert("Success", "Tax Form Processed" , 'green'); 
        this._fetchAccount();
    }

    _updateRefundLimit = () => {
        const val = this.state.refundLimit;
        if (val < 0 || val == null || isNaN(val)) {
            this.showConfirmAlert("Error", "Value for Refund Limit invalid or negative", 'red');
            return;
        }

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/refundLimit?refundLimit=" + val, {method: "POST"},
                             this._fetchAccount, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _resetRefundLimit = () => {

        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/refundLimit/reset", {method: "POST"},
                             this._fetchAccount, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }

    _forceUnlock = () => {

        this.showConfirmAlert("Confirm Force Unlock", 
                                Global.accountName + " will be forceably unlocked. This is only needed if the account was stuck in a locked state due to a server crash. You might wait a few moments to see if the lock disappears.",
                                'black',
                                "Cancel", 
                                this._confirmForceUnlock,
                                "Confirm",
                                'red');
    }

    _confirmForceUnlock = () => {
        this.secureJSONFetch("/act/account/" + this.state.selectedAccount.accountNo + "/forceUnlock", {method: "POST"},
                             this._fetchAccount, (error) => {this._fetchErrorCallback(error); this._fetchAccount()}); 
    }


    _renderSubscriberRow = (subscriber, key) => {
        
        //Render the header
        if (!subscriber) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    {Global.isAGSAccountAdmin() ?  <th style={this.styles.tableHeader}>Name</th>  : null}
                    <th style={this.styles.tableHeader}>Subscription</th>
                    <th style={this.styles.tableHeader}>Detail</th>
                    <th style={this.styles.tableHeader}>Last Issued (UTC)</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Amount (USD)</th>
                    {Global.isAGSAccountAdmin() ?
                        <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                        : null
                    }
                </tr>
            );
        }
                            
        const lastIssueDate = subscriber.lastTransactionIssueDate ? DateUtils.dateFormat(subscriber.lastTransactionIssueDate) : "Never";    // UTC

        return (
            <Fragment key={key}>
                <tr style={this.styles.tableDataWithBorder}>
                    {Global.isAGSAccountAdmin() ? <td style={this.styles.tableData}>{subscriber.subscription.name}</td>  : null}
                    <td style={this.styles.tableData}>{subscriber.subscription.description}</td>  
                    <td style={this.styles.tableData}>{subscriber.subscription.describe()}</td>
                    <td style={this.styles.tableData}>{lastIssueDate}</td>
                    <td style={{...this.styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(subscriber.subscription.amount)}</td>
                    {Global.isAGSAccountAdmin() ?
                        <td style={{...this.styles.tableData, paddingRight: 0}}>
                            <div style={{display: 'flex', justifyContent: 'right'}}>           
                                <div style={{marginLeft: 10}}/>
                                <Tooltip title="Remove Subscription">
                                    <IconButton onClick={() => this._removeSubscription(subscriber.subscription)}>
                                        <CancelIcon style={{color: 'red'}}/>
                                    </IconButton>
                                </Tooltip>                        
                            </div>
                        </td> : null
                    }
                </tr>
            </Fragment>
        );
    }

    _renderFeeRow = (fee, key) => {
        
        //Render the header
        if (!fee) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={this.styles.tableHeader}>Fee Type</th>
                    {Global.isAGSAccountAdmin() ?
                        <th style={this.styles.tableHeader}>Fee Name</th>
                        : null
                    }
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Amount Multiplier</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Fixed Amount (USD)</th>
                    {Global.isAGSAccountAdmin() ?
                        <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                        : null
                    }
                </tr>
            );
        }
                            
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableDataWithBorder}>
                    <td style={this.styles.tableData}>{fee.type.label}</td>  
                    {Global.isAGSAccountAdmin() ?
                        <td style={this.styles.tableData}>{fee.name}</td>  
                        : null
                    }        
                    <td style={{...this.styles.tableData, textAlign: 'right'}}>{Currency.round(fee.multipler, 3)}</td>
                    <td style={{...this.styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(fee.fixed)}</td>
                    {Global.isAGSAccountAdmin() ?
                        <td style={{...this.styles.tableData, paddingRight: 0}}>
                            <div style={{display: 'flex', justifyContent: 'right'}}>           
                                <div style={{marginLeft: 10}}/>
                                <Tooltip title="Change Fee">
                                    <IconButton onClick={() => this._changeFeeButtonPressed(fee.type)}>
                                        <SettingsIcon style={{color: 'gray'}}/>
                                    </IconButton>
                                </Tooltip>                        
                            </div>
                        </td> : null
                    }
                </tr>
            </Fragment>
        );
    }
   

    _gotoPayment = () => {
        let url = "//" + window.location.hostname + ":" + window.location.port + "/payment?act=" + this.state.selectedAccount.accountNo + "&hm=" + this.state.selectedAccount.paymentHmac;
        OpenInNewTab(url);   
    }

    _renderFeature = (enabled, featureTitle, textEnabled, textDisabled) => {

        let text = "Unknown";
        let color = 'black';
        text = enabled ? textEnabled : textDisabled;
        if (enabled)
            color = 'green';

        return <Typography variant="body1" style={{color: color}}>{featureTitle + ": " + text}</Typography>                  
    }
    

    _renderNotes = (label, json, propertyStateName) => {
        return (
            <Fragment>
                <Typography variant="body2" style={{marginBottom: 5, marginTop: 10, fontWeight: 'bold'}}>{label}</Typography>
                <div style={{border: '1px solid #CCCCCC', borderRadius: '4px', padding: 10, paddingBottom: 15}}>
                    <RichTextEditor
                        draftContent={json}     
                        editing={this.state.editingNotes}  
                        onChange={ (draftJson) => {
                            this.setState({[propertyStateName]: draftJson});
                        }}  
                    />
                </div>
            </Fragment>
        );
    }

    _payoutBusy = () => {
        if (this.state.payoutBusy)
            return  <CircularProgress size={30} style={{marginLeft: 'auto', marginRight: 20}}/>
        else
            return null;
    }

    _billingBusy = () => {
        if (this.state.billingBusy)
            return  <CircularProgress size={30} style={{marginLeft: 'auto', marginRight: 20}}/>
        else
            return null;
    }

    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        const selectedAccount = this.state.selectedAccount;

        const redirectIcon =  <ReplyIcon style={{transform: 'scaleX(-1) rotate(20deg)'}}/>;

        let payoutModes = Object.keys(Account.PayoutMode).map(key => Account.PayoutMode[key]);
        
        // remove payout modes that are not electronic, if the account needs a connected account
        if (!this.state.stripeConnectedAccountId)
            payoutModes = payoutModes.filter(mode => !Account.payoutModeIsElectronic(mode));

        let accountStates = Object.keys(Account.State).map(key => Account.State[key]);

        const refundLimitRemain = selectedAccount ? Currency.round(selectedAccount.currentRefundLimit) : null;

        let epasses, ppasses, mailing, membership, checkIn, marketplace, isAcceptingApplications, applicationsFullService, mergedDocuments;

        if (selectedAccount && this.state.accountFeatures) {

            let suspendedReason = "Disabled";
            if (this.state.accountFeatures.isSuspended) {   // determine why suspended  

                switch (selectedAccount.state) {
                    case Account.State.ACTIVE:
                    case Account.State.TRIAL:
                    case Account.State.TEST:
                        if (selectedAccount.seasonal)
                            suspendedReason += " (Off-Season)";
                        break;
                    case Account.State.SUSPENDED:
                    case Account.State.CLOSED:
                    case Account.State.VOID:
                        suspendedReason += " (Account Suspended/Closed)";
                        break;
                    default:
                        break;
                }
            }
                
            membership = this._renderFeature(!this.state.accountFeatures.isSuspended, "Membership Portal", "Enabled", suspendedReason);
            checkIn = this._renderFeature(!this.state.accountFeatures.isCheckInDisabled, "Check-In", "Enabled", "Disabled (Free Plan)");
            marketplace = this._renderFeature(this.state.accountFeatures.isMarketplaceEnabled, "Check-In Marketplace Service", "Available", "Disabled");
            epasses = this._renderFeature(this.state.accountFeatures.isEPassesEnabled, "ePasses", "Enabled", "Disabled");
            ppasses = this._renderFeature(this.state.accountFeatures.isPassPrintingEnabled, "Classic Pass Printing", "Enabled", "Disabled");

            let mailText;
            if (this.state.accountFeatures.printSpecialInstructions)
                mailText = "Special Instructions: " + this.state.accountFeatures.printSpecialInstructions;
            else
                mailText = this.state.accountFeatures.isBulkMailed ? "Bulk to Community (on Request)" : "Direct to Patron";
            mailing = this._renderFeature(this.state.accountFeatures.isPassPrintingEnabled, "Pass Mailing", mailText, "None (Pass Printing Disabled)");


            // if applicationAcceptingStatus is null, the Community isn't even set up in the Applications portal
            const applicationsEnabled = this.state.accountFeatures.applicationAcceptingStatus ? true : false;

            isAcceptingApplications = this._renderFeature(applicationsEnabled, "Application Portal", this.state.accountFeatures.applicationAcceptingStatus, "Disabled");
            
            if (applicationsEnabled) {
                applicationsFullService = this._renderFeature(this.state.accountFeatures.fullService, "Application Processing", "Full Service", "Self Service");
                mergedDocuments = this._renderFeature(this.state.accountFeatures.mergedDocuments, "Application Merged Documents", "Enabled", "Disabled");
            }
        }

        const connectedAct = this.state.stripeConnectedAccountId ? this.state.stripeConnectedAccountId : "None";

        const missingConnectedAct = this.state.stripeConnectedAccountId && !this.state.connectedPayoutAct;

        const stripeCustomerLink = (stripeID) => {
            const linkStyle = {marginLeft: 8, cursor: 'pointer', fontFamily: 'monospace', textTransform: 'none', textDecoration: 'underline', color: 'blue', fontWeight: 'normal', fontSize: 14};
            return (
                <div style={{display: 'flex', marginRight: 'auto'}}>
                    <Typography variant='body2'>Stripe Customer ID:</Typography> 
                    <div style={linkStyle} onClick={() => this._jumpToStripeCustomer(stripeID)}>{stripeID}</div>                
                </div>
            ); 
        }

        const missingAccountRep = selectedAccount && !selectedAccount.accountRepName;
        const missingHoc = selectedAccount && !selectedAccount.hocName;

        // Upgrade plaintext account notes to rich text, if they didn't start with a { they are not json
        let accountNotesJson = this.state.accountNotes;
        if (accountNotesJson && !accountNotesJson.startsWith("{")) {
            accountNotesJson = plaintextToRichTextJson(accountNotesJson);
        }

        let billingNotesJson = this.state.billingNotes;
        if (billingNotesJson && !billingNotesJson.startsWith("{")) {
            billingNotesJson = plaintextToRichTextJson(billingNotesJson);
        }

        let nextActionNotesJson = this.state.nextActionNotes;
        if (nextActionNotesJson && !nextActionNotesJson.startsWith("{")) {
            nextActionNotesJson = plaintextToRichTextJson(nextActionNotesJson);
        }

        // Only AGS Account Admins can edit payouts, unless the user who can edit the account has completed the tax forms
        const canEditPayouts = Global.isAGSAccountAdmin() || (Global.canEditAccount && this.state.taxpayerID)

        let repMailTo;
        let hocMailTo;
        if (selectedAccount) {

            // remove ampersands and questions marks from the extended name
            const extendedName = selectedAccount.extendedName.replace(/&/g, "").replace(/\?/g, "");

            if (!missingAccountRep)
                repMailTo = "mailto: " + selectedAccount.accountRepEmail + "?subject=" + extendedName + " - Access Granted Systems Account&body=" + selectedAccount.accountRepName + ",";
        
            if (!missingHoc) {     
                let cc = selectedAccount.hocCCEmails ? ("cc=" + selectedAccount.hocCCEmails.replace(/\s/g, "") + "&") : "";  // replace whitespace with nothing
                hocMailTo = "mailto: " + selectedAccount.hocEmail + "?" + cc + "subject=" + extendedName + " - Access Granted Systems&body=" + selectedAccount.hocName + ",";
            }
        }

        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                
                <SubscriptionSelectPopover isOpen={this.state.subscriptionPopoverOpen} okCallback={(subscription) => this._addSubscription(subscription)} 
                                            availableSubscriptions={this.state.availableSubscriptions}
                                            cancelCallback={() => this.setState({subscriptionPopoverOpen: false})}/>   

                <FeeSelectPopover isOpen={this.state.feePopoverOpen} okCallback={(fee) => this._switchFee(this.state.feeSelectType, fee.name)} 
                                            defaultCallback={() => this._switchFee(this.state.feeSelectType, "")}
                                            availableFees={this.state.availableFees}
                                            feeType={this.state.feeSelectType}
                                            cancelCallback={() => this.setState({feePopoverOpen: false})}/>   
                
                <PayoutActSelectPopover isOpen={this.state.payoutAccountPopoverOpen} okCallback={(payoutAct) => this._setPayoutAct(payoutAct)} 
                                            payoutAccounts={this.state.payoutAccounts}
                                            cancelCallback={() => this.setState({payoutAccountPopoverOpen: false})}/>  

                <NewConnectedAccountRequestPopover isOpen={this.state.requestNewAccountPopoverOpen} okCallback={(dba, countryCode) => this._requestNewConnectedAccount(dba, countryCode)} 
                                                    cancelCallback={() => this.setState({requestNewAccountPopoverOpen: false})}/> 

                <ListSelectPopover isOpen={this.state.accountRepSelectPopoverOpen} title="Select New Account Representative" items={this.state.availableAccountReps}
                                   okCallback={(selected) => this._updateAccountRep(selected)}
                                   cancelCallback={() => this.setState({accountRepSelectPopoverOpen: false})}/>

                <ListSelectPopover isOpen={this.state.hocSelectPopoverOpen} title="Select New Head of Community" items={this.state.availableHocs}
                                   okCallback={(selected) => this._updateHoc(selected)}
                                   cancelCallback={() => this.setState({hocSelectPopoverOpen: false})}/>

                <CompleteTaxForm isOpen={this.state.collectTaxFormOpen} currentTaxpayerID={this.state.taxpayerID} currentEffectiveDate={this.state.effectiveDate} communityName={selectedAccount ? selectedAccount.extendedName : ""} 
                                 okCallback={(taxFormData) => this._taxFormCompleted(taxFormData)}
                                 cancelCallback={() => this.setState({collectTaxFormOpen: false})}/>

                <TaxYearPopover isOpen={this.state.taxYearPopoverOpen} 
                                okCallback={this._taxYearPopoverOkCallback} 
                                cancelCallback={() => this.setState({taxYearPopoverOpen: false})}/>    

                <TextEntryPopover isOpen={this.state.hocCommentPopupOpen} showSkip={false} multiline={true} title="Reason for HOC Change" 
                                  okCallback={this._hocCommentOk} cancelCallback={() => this.setState({hocCommentPopupOpen: false})}/>

                <ListSelectPopover isOpen={this.state.exemptReasonPopoverOpen} title="1099 Exempt Reason" items={this._exemptReasons}
                                   okCallback={(selected) => this._completeExemptTaxForm(selected)}
                                   cancelCallback={() => this.setState({exemptReasonPopoverOpen: false})}/>

                {selectedAccount ?

                    <Fragment>
            
                        <Paper style={this.styles.paper}>   
                            
                            <div style={{display: 'flex', justifyContent: Global.user.databases.length > 1 ? 'space-between' : 'center', alignItems: 'center', marginLeft: 10, marginRight: 10}}>
                                {Global.user.databases.length > 1 ?
                                    <Tooltip title="Goto Previous Account">
                                        <IconButton onClick={this.props.prevAccountRequest}>
                                            <ArrowBackIosIcon fontSize='small'/> 
                                        </IconButton>
                                    </Tooltip> 
                                    : null
                                }

                                <Typography align='center' style={{color: ThemeColors.appBarBackground, fontSize: '16pt'}} variant="h6">{selectedAccount.extendedName}</Typography> 
                                
                                {Global.user.databases.length > 1 ?

                                    <Tooltip title="Goto Next Account">
                                        <IconButton onClick={this.props.nextAccountRequest}>
                                            <ArrowForwardIosIcon fontSize='small'/> 
                                        </IconButton>
                                    </Tooltip>
                                    : null
                                }
                            </div>

                            <div style={{borderTop: '3px solid ' + ThemeColors.appBarBackground, marginBottom: 40}}/>

                            <AccountSnapshotView accountSnapshot={this.state.accountSnapshot}/>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                <Button variant='contained' style={{color: 'green', fontSize: '12pt'}} onClick={this._gotoPayment} >Make a Payment</Button>
                            </div>

                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    
                                    <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Community Info</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        <Typography variant="body1" >{"Account Number: " + selectedAccount.accountNo}</Typography>                  
                                        <Typography variant="body1" >{"Database Name: " + selectedAccount.groupName}</Typography>                  
                                        <Typography variant="body1" >{"Community Currency: " + selectedAccount.isoCurrency}</Typography>                  
                                        <Typography variant="body1" >{"Community Address: " + selectedAccount.address}</Typography>                  
                                    </div>
                                </Grid>

                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>State</Typography> 
                                    </div>        

                                    <div style={this.styles.roundedContainer}>

                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 10, gap: 30}}>
                                            <Autocomplete
                                                style={{maxWidth: 400, minWidth: 300}}
                                                disabled={!Global.isAGSAccountAdmin()}
                                                size='small'
                                                value={this.state.accountState}
                                                getOptionLabel={(state) => state.label }
                                                onChange={(event, newValue) => { this.setState({accountState: newValue}); }}
                                                inputValue={this.state.accountStateValue}
                                                onInputChange={(event, newValue) => { this.setState({accountStateValue: newValue}); }}
                                                options={accountStates}
                                                blurOnSelect
                                                renderOption={(option) => {
                                                    return (
                                                      <span style={{ color: option.color }}>
                                                        {option.label}
                                                      </span>
                                                    );
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Account State" variant="outlined" InputProps={{ ...params.InputProps, style: {color: this.state.accountState.color, fontWeight: 'bold'} }} InputLabelProps={{ shrink: true }} />}
                                            />

                                            <Typography variant="body1" style={{color: 'gray', fontStyle: 'italic'}}>{this.state.accountState.tooltip}</Typography>
                                                
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10}}>
                                            <Checkbox checked={this.state.isSeasonal} color='primary' disabled={!Global.isAGSAccountAdmin()}
                                                      onChange={(event) => { this.setState({isSeasonal: event.target.checked})}}/>   
                                            <Typography variant='body2' align='left' style={{color: 'gray'}}>Seasonal Account</Typography>   
                                        </div>

                                        {Global.isAGSAccountAdmin() ?
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                                <Button variant='contained' onClick={this._updateState}>Apply State Changes</Button>
                                            </div> 
                                            : null
                                        }
                                    </div>

                                </Grid>


                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginBottom: 0}}>Account Representative</Typography> 
                                    </div>        

                                    <div style={this.styles.roundedContainer}>

                                        <Typography variant="body1" style={{color: missingAccountRep ? 'red' : 'black'}}>{missingAccountRep ? selectedAccount.accountRep : selectedAccount.accountRepName}</Typography>                  
                                        
                                        <div style={{display: 'flex', alignItems: 'top', justifyContent: 'space-between'}}>
                                            {missingAccountRep ? <div style={{marginTop: 60}}/> :
                                                <div>
                                                    <Typography variant="body1">Email: <Link href={repMailTo} variant="body1">{selectedAccount.accountRepEmail}</Link></Typography>       
                                                    <Typography variant="body1">Schedule a Meeting: <Link rel="noopener noreferrer" target="_blank" href={"http://" + selectedAccount.accountRepUrl} variant="body1">{selectedAccount.accountRepUrl ? selectedAccount.accountRepUrl : ""}</Link></Typography>             
                                                </div>
                                            }            
                                            {this.state.fetchingReps ? 
                                                    <CircularProgress style={{marginRight: 20}} size={30}/>
                                                    : null
                                            }
                                        </div>
                                        {Global.isAGSAccountAdmin() ?
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                                <Button variant='contained' onClick={this._selectAccountRep}>Select New Representative...</Button>
                                            </div>   
                                            : null
                                        }
                                    </div>
                                </Grid>

                                
                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginBottom: 0}}>Head of Community</Typography> 
                                    </div>        

                                    <div style={this.styles.roundedContainer}>

                                        <Typography variant="body1" style={{color: missingHoc ? 'red' : 'black'}}>{missingHoc ? selectedAccount.hoc : selectedAccount.hocName}</Typography>                  

                                        <div style={{display: 'flex', alignItems: 'top', justifyContent: 'space-between'}}>
                                            {missingHoc ? <div style={{marginTop: 60}}/> :
                                                <div style={{marginBottom: 20}}>
                                                    <Typography variant="body1">Email: <Link href={hocMailTo} variant="body1">{selectedAccount.hocEmail}</Link></Typography>       
                                                </div>
                                            }            
                                            {this.state.fetchingHocs ? 
                                                    <CircularProgress style={{marginRight: 20}} size={30}/>
                                                    : null
                                            }
                                        </div>
                                        <Tooltip title="Enter email addresses to receive copies of communication to the Head of Community, separated by semicolons">
                                            <TextField label="CC'ed Emails" size="small" variant='outlined' fullWidth={true} value={this.state.hocCCEmails} style={{marginTop: 10}}
                                                        onChange={(event) => { this.setState({hocCCEmails: event.target.value})}}
                                                        inputProps={{readOnly: !Global.canEditAccount()}}
                                                        InputLabelProps={{ shrink: true }} />
                                        </Tooltip>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20, marginTop: 10}}>
                                            {Global.isAGSAccountAdmin() ? <Button variant='contained' onClick={this._selectAccountHoc}>Select New HOC...</Button> : null}
                                            <Button variant='contained' onClick={this._updateHocCC}>Apply CC Changes</Button>
                                        </div>   
                                
                                    </div>
                                </Grid>
                                

                                {Global.isAGSAccountAdmin() ? 
                                    <Grid item xs={12}>     

                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant="body2" style={{...this.styles.sectionLabel, marginBottom: 0}}>Account Notes (not visible to client)</Typography> 
                                        </div>        

                                        <div style={this.styles.roundedContainer}>
                                            {this.state.editingNotes ? null :
                                                <Button variant='outlined' onClick={() => this.setState({editingNotes: true})}>Edit Notes</Button>
                                            }

                                            {this._renderNotes("General Account Notes", accountNotesJson, "accountNotes")}
                                            {this._renderNotes("Billing Notes", billingNotesJson, "billingNotes")}
                                            {this._renderNotes("Next Action Notes", nextActionNotesJson, "nextActionNotes")}
                                            
                                            <TextField label="Year Acquired" variant='outlined' multiline={false} fullWidth={false} value={this.state.yearAcquired} style={{marginTop: 20, width: '30%'}}
                                                            onChange={(event) => { this.setState({yearAcquired: event.target.value})}}
                                                            InputLabelProps={{ shrink: true }} />

                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                                <Button variant='contained' onClick={this._updateNotes}>Apply Changes</Button>
                                            </div>   
                                        </div>
                                    </Grid>
                                    : null
                                }


                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Billing / Invoices</Typography> 
                                    </div>            

                                    <div style={this.styles.roundedContainer}>

                                        <div style={{display: 'flex', gap: 20, justifyContent: 'left', alignItems: 'top'}}>
                                            <Typography variant='body1' align='left' style={{marginLeft: 10, color: selectedAccount.autoPaymentEnabled ? 'green' : 'black'}}>{"Invoice Auto Payment: " + (selectedAccount.autoPaymentEnabled ? "enrolled" : "not enrolled")}</Typography> 
                                            {selectedAccount.autoPaymentEnabled ? 
                                                <div>
                                                    <Tooltip title="Remove saved card and unenroll in Auto payment">
                                                        <Button variant='outlined' style={{color: 'red'}} onClick={this._unenrollAutoPayment}>Unenroll Auto Payment</Button>
                                                    </Tooltip>
                                                </div>
                                                : 
                                                <div>
                                                    <Tooltip title="Enroll in Auto payment">
                                                        <Button variant='outlined' style={{color: 'green'}} onClick={this._enrollAutoPayment}>Enroll Auto Payment</Button>
                                                    </Tooltip>
                                                </div>
                                            }
                                            {this._billingBusy()}
                                        </div>
                                       
                                       
                                        {selectedAccount.autoPaymentEnabled && this.state.accountAutoPayment ?
                                            <div style={{marginLeft: 15}}>
                                                {Global.isAGSAccountAdmin() ? stripeCustomerLink(this.state.accountAutoPayment.stripeCustomerId): null}
                                                <Typography variant='body2'>{"Auto-payment details: " + this.state.accountAutoPayment.paymentDetails}</Typography> 
                                                <Typography variant='body2'>{"Auto-payment enrolled on: " + this.state.accountAutoPayment.acceptedOn}</Typography> 
                                            </div>
                                            : null
                                        }
                                
                                        <div style={{marginBottom: 30}}/>

                                        <Grid container direction="row" spacing={4}>

                                            <Grid item xs={12} lg={6}>
                                                <Tooltip title="Enter email addresses to receive billing updates, separated by semicolons">
                                                    <TextField label="Billing Email Contacts" size="small" variant='outlined' fullWidth={true} value={this.state.billingEmails} style={{marginTop: 10}}
                                                                onChange={(event) => { this.setState({billingEmails: event.target.value})}}
                                                                inputProps={{readOnly: !Global.canEditAccount()}}
                                                                InputLabelProps={{ shrink: true }} />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Typography variant='body1' align='left' style={{margin: 10}}>Invoice Mailing Address: (leave blank to use Community address)</Typography> 
                                                <AddressFields ref={this._billingMailingAddress} nameLabel="Attention" readOnly={!Global.canEditAccount()}/>
                                            </Grid>

                                        </Grid>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginRight: '25%', border: this.state.autoStatements ? 'none' : '1px solid red'}}>
                                            <Checkbox checked={this.state.autoStatements} color='primary' disabled={!Global.isAGSAccountAdmin()}
                                                      onChange={(event) => { this.setState({autoStatements: event.target.checked})}}/>   
                                            <Typography variant='body2' align='left' style={{color: 'gray'}}>Automatic Statement Generation</Typography>   
                                        </div>

                                        {Global.canEditAccount() ?                                        
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                                <Button variant='contained' onClick={this._updateBilling}>Apply Billing Changes</Button>
                                            </div> 
                                            : null
                                        }
                                            
                                    </div>
                                </Grid>


                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Payouts</Typography> 
                                    </div>        

                                    <div style={this.styles.roundedContainer}>

                                        <div style={{display: 'flex', justifyContent: 'left', gap: 20, alignItems: 'center'}}>
                                            <Autocomplete
                                                style={{marginTop: 10, maxWidth: 400, minWidth: 300, 
                                                        padding: (this._needsConnectedAccount() ? 2 : 0),
                                                        paddingTop: (this._needsConnectedAccount() ? 5 : 0),
                                                        border: (this._needsConnectedAccount() ? '1px solid red' : 'none')}}
                                                disabled={!canEditPayouts}
                                                size='small'
                                                value={this.state.payoutMode}
                                                getOptionLabel={(mode) => mode.label }
                                                onChange={(event, newValue) => { this.setState({payoutMode: newValue}); }}
                                                inputValue={this.state.payoutModeValue}
                                                onInputChange={(event, newValue) => { this.setState({payoutModeValue: newValue}); }}
                                                options={payoutModes}
                                                blurOnSelect
                                                renderInput={(params) => <TextField {...params} label="Payout Method" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                            /> 

                                            <Typography variant='body2' style={{marginLeft: 10, fontStyle: 'italic'}}>{this.state.payoutMode ? this.state.payoutMode.tooltip : ""}</Typography> 
                                                
                                            {this._payoutBusy()}
                                        </div>

                                        {Global.canEditAccount && !this.state.taxpayerID ?
                                            <Typography variant='body2' style={{marginTop: 10, color: 'red', fontStyle: 'italic'}}>Payouts cannot be configured until the Tax form is completed</Typography> 
                                            : null
                                        }
                                        <div style={{marginBottom: 30}}/>

                                        <Grid container direction="row" spacing={2}>

                                            <Grid item xs={12} lg={6}>
                                                <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>

                                                    <Typography variant='body1' align='left' style={{marginLeft: 10}}>{"Connected Account ID: "}</Typography> 
                                                    <Typography variant="body1" style={{fontFamily: 'monospace', marginLeft: 15, fontSize: 18, color: missingConnectedAct ? 'red' : 'black'}}>{connectedAct}</Typography> 
                                                
                                                    {this.state.stripeConnectedAccountId || !canEditPayouts ? null : 
                                                        <Tooltip title="Add New Connected Account">
                                                            <IconButton edge="end" style={{marginLeft: 10}} onClick={this._addConnectedAccount}>
                                                                <AddCircleOutlineIcon style={{color: ThemeColors.addColor}} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }

                                                    {Global.isAGSAccountAdmin() ?
                                                        <Tooltip title="Change Connected Account to another existing one">
                                                            <IconButton edge="end" style={{marginLeft: 10}} onClick={this._changeConnectedAccount}>
                                                                <CreateIcon style={{fontSize: 18, color: ThemeColors.editGreen}} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        : null
                                                    }

                                                    {this.state.stripeConnectedAccountId ?
                                                        <Fragment>
                                                            <Tooltip title="Remove Connected Account">
                                                                <IconButton edge="end" onClick={this._removePayoutAccount}>
                                                                    <CancelIcon style={{color: 'red'}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {Global.isAGSAccountAdmin() ?
                                                                <Tooltip title="Delete Connected Account">
                                                                    <IconButton edge="end" onClick={this._deleteConnectedAccount}>
                                                                        <DeleteIcon style={{color: 'red'}}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                : null
                                                            }
                                                            <Tooltip title="View Account in Stripe">
                                                                <IconButton onClick={this._jumpToStripeAccount}>
                                                                    {redirectIcon}
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Get a new onboarding link for this Connected Account">
                                                                <IconButton onClick={() => this._getOnboardingLink(false)}>
                                                                    <LinkIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Fragment>
                                                        :
                                                        null
                                                    } 
                                                </div>
                                                
                                                {this.state.connectedPayoutAct ?
                                                    <div style={{marginLeft: 10}}>
                                                        <Typography variant='body2'>{"Doing Business as: " + this.state.connectedPayoutAct.dbaName}</Typography> 
                                                        <Typography variant='body2'>{"Account Type: " + this.state.connectedPayoutAct.type + " / " + this.state.connectedPayoutAct.country.label}</Typography> 
                                                        <Typography variant='body2'>{"Created on: " + DateUtils.dateFormat(this.state.connectedPayoutAct.created)}</Typography> 
                                                        <Typography variant='body2' style={{color: this.state.connectedPayoutAct.payoutsEnabled ? 'green' : 'red'}}>{"Payouts to External Bank: " + (this.state.connectedPayoutAct.payoutsEnabled ? "enabled" : "disabled")}</Typography> 

                                                        {this.state.connectedPayoutAct.externalAccounts.map(account => 
                                                            <Typography variant='body2'>{"Connected to: " + account}</Typography>)
                                                        }
                                                        
                                                    </div>
                                                    : null
                                                }

                                            </Grid>

                                            <Grid item xs={12} lg={6}>
                                                <Typography variant='body1' align='left' style={{margin: 10}}>Check Payout Mailing Address:</Typography> 
                                                <AddressFields ref={this._payoutCheckMailingAddress} nameLabel="Payee" readOnly={!canEditPayouts}/>
                                            </Grid>

                                        </Grid>
                                        

                                        {canEditPayouts ?
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30}}>
                                                <Button variant='contained' onClick={() => this._updatePayouts(false)}>Apply Payout Changes</Button>
                                            </div>
                                            : null
                                        }
                                            
                                    </div>
                                </Grid>

                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Tax Information</Typography> 
                                    </div>        

                                    <div style={this.styles.roundedContainer}>

                                        <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 20}}>
                                            <Tooltip title="To make changes to the TIN, complete new tax form">
                                                <TextField label="Taxpayer Identification Number (SSN or EIN)" size="small" variant='outlined' value={this.state.taxpayerID} style={{marginTop: 10, minWidth: '50%', marginRight: 20}}
                                                            onChange={(event) => { this.setState({taxpayerID: event.target.value})}}
                                                            inputProps={{readOnly: true}}
                                                            InputLabelProps={{ shrink: true }} />
                                            </Tooltip>

                                            <Button variant='outlined' style={{color: 'green', marginTop: 7}} onClick={this._completeTaxForms}>{this.state.taxpayerID ? "Complete new Tax Form for TIN change" : "Complete Initial Tax Form"}</Button>
                                            {this.state.taxpayerID ?
                                                <Button variant='outlined' style={{marginTop: 7}} onClick={this._showTaxHistory}>View Tax Form History</Button>
                                                : null
                                            }

                                            {Global.isAGSAccountAdmin() && !this.state.taxpayerID ?
                                                <Tooltip title="Create a void entry for a 1099-exempt entity, such as a government or foreign entity">
                                                    <Button variant='outlined' style={{color: 'red', marginTop: 7}} onClick={() => this.setState({exemptReasonPopoverOpen: true})}>Create Exempt Entry</Button>
                                                </Tooltip>
                                                : null
                                            }

                                        </div>

                                        <TextField label="Entity Classification" size="small" variant='outlined' fullWidth={false} value={this.state.taxClassification} style={{marginTop: 20, marginBottom: 20, minWidth: '50%'}}
                                                    onChange={(event) => { this.setState({taxClassification: event.target.value})}}
                                                    inputProps={{readOnly: !Global.canEditAccount() || !this.state.taxpayerID}}
                                                    InputLabelProps={{ shrink: true }} />

                                        <AddressFields ref={this._taxMailingAddress} nameLabel="Name of the Individual/Entity (Box 1 on W-9)" readOnly={!Global.canEditAccount() || !this.state.taxpayerID}/>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginRight: '25%'}}>
                                            <Checkbox checked={this.state.exemptFrom1099} color='primary' disabled={!Global.isAGSAccountAdmin() || !this.state.taxpayerID}
                                                      onChange={(event) => { this.setState({exemptFrom1099: event.target.checked})}}/>   
                                            <Typography variant='body2' align='left'>Exempt from receiving 1099-K</Typography>   
                                        </div>

                                        {Global.isAGSAccountAdmin() ?
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: -20, marginBottom: -20}}>
                                                <Button variant='outlined' onClick={() => this.setState({taxYearPopoverOpen: true})}>View Tax Data</Button>
                                            </div> 
                                            : null
                                        }
                                        {Global.canEditAccount() && this.state.taxpayerID ?                                        
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                                <Button variant='contained' onClick={this._updateTax}>Apply Tax Changes</Button>
                                            </div> 
                                            :
                                            <div style={{marginTop: 20}}/>
                                        }
                                            
                                    </div>
                                </Grid>

                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Refunds</Typography> 
                                    </div>        

                                    <div style={this.styles.roundedContainer}>
                                        <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center',  gap: 20, marginTop: 10}}>
                                            <Tooltip title="The maximum amount of refunds that can be issued in any given month">
                                                <TextField label="Monthly Maximum Refund Limit" variant='outlined' fullWidth={false} size="small" style={{minWidth: 300}} value={this.state.refundLimit}
                                                            inputProps={{readOnly: !Global.isAGSAccountAdmin()}}
                                                            onChange={(event) => { this.setState({refundLimit: event.target.value})}}
                                                            InputLabelProps={{ shrink: true }} />
                                            </Tooltip>
                                            {Global.isAGSAccountAdmin() ?
                                                <Button variant='outlined' onClick={this._updateRefundLimit}>Apply Refund Limit Change</Button>
                                                : null
                                            }
                                        </div>

                                        <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center',  gap: 20, marginTop: 20}}>
                                            <Typography variant='body1' align='left' style={{color: refundLimitRemain <= 0 ? 'red' : 'black'}}>{"Remaining Limit on Refunds this Month: $" + refundLimitRemain}</Typography> 

                                            {Global.isAGSAccountAdmin() ?
                                                <Button variant='outlined' onClick={this._resetRefundLimit}>Reset Remaining Limit</Button>
                                                : null
                                            }                                            
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Account Setup</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        {membership}
                                        {checkIn}
                                        {marketplace}
                                        {epasses}
                                        {ppasses}
                                        {mailing}
                                        {isAcceptingApplications}
                                        {applicationsFullService}
                                        {mergedDocuments}
                                    </div>

                                </Grid>

                                <Grid item xs={12}>     

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Subscriptions</Typography> 
                                        {Global.isAGSAccountAdmin() ? 
                                            <Tooltip title="Add a Subscription">
                                                <IconButton edge="end" onClick={this._addSubscriptionButtonPressed} style={{marginTop: -4, marginRight: 12}}>
                                                    <AddCircleOutlineIcon style={{color: ThemeColors.addColor}} />
                                                </IconButton>
                                            </Tooltip> 
                                            : null
                                        }
                                    </div>        

                                    <div style={this.styles.roundedContainer}>
                                        <table style={this.styles.table}>
                                            <thead>
                                                {this._renderSubscriberRow(null, 0) /*render header*/ }
                                            </thead>
                                            <tbody>
                                                {selectedAccount.subscribers.map((subscriber, index) => this._renderSubscriberRow(subscriber, index+1))}  
                                            </tbody>
                                        </table>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>     
                                    <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Fee Schedule</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        <table style={this.styles.table}>
                                            <thead>
                                                {this._renderFeeRow(null, 0) /*render header*/ }
                                            </thead>
                                            <tbody>
                                                {selectedAccount.fees.map((fee, index) => this._renderFeeRow(fee, index+1))}  
                                            </tbody>
                                        </table>
                                    </div>
                                </Grid>

                            </Grid>

                            <div style={{marginTop: 30}}/>
                  
                            {Global.isSuperAdmin() ?
                                <div style={{display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'center', marginTop: 10}}>
                                    <Button variant='outlined' style={{color: 'blue', fontSize: '12pt'}} onClick={this._forceUnlock} >Force Unlock</Button>
                                </div>
                                : null
                            }
                  
                        </Paper>
                    </Fragment>                
                    : null
                }
                    
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(AccountPage));




