import React from 'react';
import { withCookies } from 'react-cookie';
import stripebadge from '../assets/stripebadge.png';

import { Typography, TextField, Paper, Button, Tooltip } from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { RestComponent, StripeManager } from 'react-frontend-utils'


/**
 * 
 * Checkout Component when Payment button is clicked
 * 
 * The caller must pass the props:
 * 
 * returnToAccount:        a function to be called to go back to the Accounts page
 * paymentComplete:        callback function when charging succeeds
 * paymentDescription:     String describing the payment (for instance the amount)
 * purchaseComplete:       a function to be called when purchasing is complete
 * clientSecret:           the clientSecret retrieved from the backend from Stripe
 * email:                  pre-populate the email for the credit card
 * payDate:                the date the payment was was requested
 * 
 * The StripeManager component takes care of the purchase. 
 *
 */

const STALE_TIME = 2 * 3600 * 1000;  //2 hours to complete payment

export class PaymentCheckout extends RestComponent {

    styles = {
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        }
    }
        
    constructor(props) {
        super(props);
        this.state.name = "";                   //Name for the account
        this.state.email = props.email;         //Email for the account - pre populate
        this.state.cardChargeFailure = false;
    }

    _returnToAccount = () => {
        this.setState({cardChargeFailure: false});
        this.props.returnToAccount();
    }


    //Called when payer finishes filling out card details and presses pay.  We have the opportunity to take some action here.  As long as the page isn't too stale,
    // we immediately call complete purchase
    _payNowPressed = (completePurchase, cancelPurchase) => {

        const now = (new Date()).getTime();
        if (now - this.props.submitDate.getTime() > STALE_TIME) {
            this.showConfirmAlert("Payment Failed", "Too much time has elapsed.  Please refresh your browser and start over", 'red');
            cancelPurchase(); 
        }
        else
            completePurchase(this.props.clientSecret);
    }
    
    _chargeSuccess = () => {
        this.props.paymentComplete();
    }

    _chargeFailure = (message) => {
        
        this.showConfirmAlert("Payment Failed", message + " You can try entering your payment information again.", 'red'); 
        this.setState({cardChargeFailure: true});
    }
    
    _fetchPublishableKey = (callback) => {
        this.secureJSONFetch("/payments/stripe/pubkey", {}, 
                             (response) => callback(response), 
                             (error) => this.showConfirmAlert("Failed to Load Payment System", error, 'red')
                             );
    }


    render() {
        
        return (
            <div style={this.props.disabled ? {pointerEvents: "none", opacity: "0.5"} : null}>

                {this.getConfirmAlertComponent()}

                <Paper style={{padding: 20}}>

                    <Typography variant="h6" align='center' style={{marginBottom: 40}}>{this.props.paymentDescription}</Typography>                           

                    <Button color="primary" style={{marginBottom: 20}} onClick={this._returnToAccount}>
                        <ArrowBackIosIcon fontSize='small'/> 
                        <Typography variant="body1">Cancel and Go Back to Account</Typography>   
                    </Button>  

                
                    <div style={{display: 'flex', marginBottom: 25}}>
                        <Tooltip title="Cardholder name, required.">
                            <TextField size="small" label="Cardholder Name" variant="outlined" 
                                        onChange={(event) => {this.setState({name: event.target.value});}}
                                        value={this.state.name} 
                                        fullWidth={true} 
                                        InputLabelProps={{ shrink: true}} />
                        </Tooltip>

                        <div style={{margin: 10}}/>

                    </div>                 
                            
                    <StripeManager name={this.state.name} email={this.state.email}
                                    fetchPublishableKey={this._fetchPublishableKey}
                                    purchaseRequest={this._payNowPressed} 
                                    cardChargeSuccess={this._chargeSuccess}
                                    cardChargeFailure={this._chargeFailure}/>
                </Paper>

                <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>

                    <a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
                        {<img src={stripebadge} alt="stripe badge" width='400px'/>}
                    </a>

                </div>                

            </div>
        );
    }

}

export default withCookies(PaymentCheckout);
