import { DateUtils } from 'react-frontend-utils'

export class TaxData {

    taxpayerID;
    effectiveDate;
    patronSales = [];       // array of 12 entries, one for each month of the year, each entry is an object with sales and transactionCount properties
    totalSales;
    transactionCount;


    constructor(json, taxYear) {
        this.taxpayerID = json.taxInfo ? json.taxInfo.taxpayerID : "Not Provided";
        const jsonEffectiveDate = json.taxInfo && json.taxInfo.effectiveDate ? json.taxInfo.effectiveDate : (taxYear + "-01-01");
        this.effectiveDate = DateUtils.parseJsonDate(jsonEffectiveDate, false);
        this.patronSales = json.patronSales;
        this.totalSales = json.patronSales.reduce((acc, month) => acc + month.sales, 0);
        this.totalTransactionCount = json.patronSales.reduce((acc, month) => acc + month.transactionCount, 0);
    }
}

export class Tax1099 {

    accountNo;
    taxYear;
    taxData;    // array of TaxData objects

    constructor(json) {
        this.accountNo = json.accountNo;
        this.taxYear = json.taxYear;
        this.taxData = json.taxData.map(data => new TaxData(data, this.taxYear));
    }

}