import React, { useState } from 'react';
import { Container, Popover, Button, Box, TextField, } from '@material-ui/core'

import { DateUtils } from 'react-frontend-utils' 
import Autocomplete from '@material-ui/lab/Autocomplete';

/**
 * Popover for selecting a tax year, and optionally a reporting threshold. User should pass the following props:
 *      isOpen - boolean, if true the popover is displayed
 *      promptForThreshold - boolean, if true a field for entering a reporting threshold is displayed    
 *      okCallback - function(taxYear, threshold) - called when user clicks the Ok button
 *      cancelCallback - function() - called when user clicks the Cancel button
 */

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        marginTop: 20,
        marginBottom: 10
    },
};



export function TaxYearPopover(props) {

    const [taxYear, setTaxYear] = useState(DateUtils.currentYear());
    const [threshold, setThreshold] = useState(0);

    let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
    if (maxWidth > 500)
        maxWidth = 500;

    const onThresholdChange = (event) => {    
        let val = event.target.value ? parseInt(event.target.value) : 0;
        if (val < 0)
            val = 0;
        setThreshold(val);
    }

    const onSelect = () => {
        props.okCallback(taxYear, props.promptForThreshold ? threshold : null);
    }

    const onCancel = () => {
        props.cancelCallback();
    }   

    const taxYears = [];
    for (let year = DateUtils.currentYear(); year >= 2024; year--)
        taxYears.push(year.toString());

    return (
        <Popover open={props.isOpen} 
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
            
            <Box style={{margin: 20, width: maxWidth}}>
                
                <div style={{display: 'flex', textAlign: 'center', fontWeight: 'bold', marginBottom: 20}}>Select Tax Year</div>
                <Autocomplete size='small'
                    id="tax-year"
                    options={taxYears}
                    value={taxYear.toString()}
                    onChange={(event, newValue) => { setTaxYear(parseInt(newValue)) }}
                    style={{ width: 300 }}
                    blurOnSelect
                    renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{ shrink: true }} />}
                />

                {props.promptForThreshold ?
                    <TextField style={{marginTop: 20, width: 300}} variant='outlined' label="Reporting Threshold (USD)" type="number" value={threshold} inputProps={{style: {fontSize: 18}}}
                               onChange={onThresholdChange} InputLabelProps={{ shrink: true}} />
                    : null
                }
                
                <Container style={styles.container}>    
                    <Button onClick={onSelect} color="primary" variant="outlined">Ok</Button>
                    <Button onClick={onCancel} color="primary" variant="outlined">Cancel</Button>
                </Container>
            </Box>
        </Popover>
    );


}