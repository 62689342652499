import { DateUtils } from 'react-frontend-utils'
import { Transaction } from './Transaction';


export class Statement {
      
    id;
    invoiceNo;
    closeDate;               //Converted to local
    accountNo;
    type;                       // "INVOICE", "PAYOUT_NOTICE", or "STATEMENT" 
    transactionSum;
    balanceForward;
    pastDue;
    prevBalance;
    patronSales;
    chargesAndCredits;
    autoPayEnabled;
    manuallyTriggeredNote;  //null if not manually triggered
    coveredTransactions;    //only if specifically pulled

    constructor(json) {
        if (json) {        
            this.id = json.id;
            this.invoiceNo = json.invoiceNo;
            this.closeDate = DateUtils.parseJsonDate(json.closeDate, true); //convert from UTC            
            this.accountNo = json.accountNo;
            this.type = json.type;
            this.transactionSum = json.transactionSum;
            this.balanceForward = json.balanceForward;
            this.prevBalance = json.prevBalance;
            this.pastDue = json.pastDue;
            this.patronSales = json.patronSales;
            this.chargesAndCredits = json.chargesAndCredits;
            this.autoPayEnabled = json.autoPayEnabled;
            this.manuallyTriggeredNote = json.manuallyTriggeredNote;
            if (json.coveredTransactions)
                this.coveredTransactions = json.coveredTransactions.map(t => new Transaction(t));              
        }
    }

}