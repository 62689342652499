
export class Fee {

    static Type = {

        FULL_APPLICATION_PROCESSING:   {enumName: "FULL_APPLICATION_PROCESSING", bit: 0, label: "Full Service Application Processing"}, 
        SELF_APPLICATION_PROCESSING:   {enumName: "SELF_APPLICATION_PROCESSING", bit: 1, label: "Self Service Application Processing"}, 
        APPLICATION_MERGED_DOC:        {enumName: "APPLICATION_MERGED_DOC", bit: 2, label: "Merged Document Creation"}, 
        PAYMENT_PROCESSING:            {enumName: "PAYMENT_PROCESSING", bit: 3, label: "Patron Payment Processing"}, 
        PAPER_APPLICATION:             {enumName: "PAPER_APPLICATION", bit: 4, label: "Paper Application Processing"},
        PASS_PRINTING:                 {enumName: "PASS_PRINTING", bit: 5, label: "Classic Pass Printing"},
        PATRON_PASS_MAILING:           {enumName: "PATRON_PASS_MAILING", bit: 6, label: "Daily Pass Mailing"},
        BULK_MAILING:                  {enumName: "BULK_MAILING", bit: 7, label: "On Request Pass Mailing"},
        PATRON_DISPUTE:                {enumName: "PATRON_DISPUTE", bit: 8, label: "Patron Dispute"}
    };
    
    
      
    name;
    type;
    multipler;
    fixed;
    agsCost     //not always available
    

    constructor(json) {
        if (json) {        
            this.name = json.name;
            this.type = Fee.typeFromString(json.type);  
            this.multipler = json.multipler;
            this.fixed = json.fixed;
            this.agsCost = json.frontendCost;               
        }
    }

    
    
    static typeFromString(str) {
        for (let index in Fee.Type) {
            const type = Fee.Type[index];

            if (type.enumName === str) {
                return type;
            }
        }  
        return null;
    }
}