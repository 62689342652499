import React, { useState } from 'react';

import { Grid, Typography, Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chart from "react-apexcharts";

import { ThemeColors } from '../Theme'
import { Currency } from 'react-frontend-utils' 



export function MonthlyGraphWidget(props) {

    const styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'black',
            fontSize: '14pt',
            fontWeight: 'bold',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 50
        }
    };

    const standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
    };

    //Create an array of the categories (Marketplace, Invoice, etc.) in the Map
    const allKeys = props.pivotitemmap ? Array.from(props.pivotitemmap.keys()) : [];   //array of all keys, or empty array if no pivot item
    allKeys.sort((a, b) => {    //order alphabetically, but "All" comes first
        if (a === "All")
            return -1;
        else if (b === "All")
            return 1;
        else if (a === b)
            return 0;
        else 
            return a < b ? -1 : 1;
    }); 
    const initialKey = allKeys[0];

    // -------------- Pie Chart --------------------------

    const pieChartOptions = {
      
        chart: standardChartOptions,
        labels: [],
        colors: ThemeColors.pieChartColors,
        legend : {
            show: true,
            position: 'bottom',
            formatter: function (seriesName, opts) {
                const amount = opts.w.config.series[opts.seriesIndex];
                return opts.w.config.labels[opts.seriesIndex] + " ($" + Currency.round(amount) + ")";  //use the rounded amount
            }
        },
        tooltip: {
            y: {
                formatter: (value) => { return "$" + Currency.round(value) }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return val.toFixed(0) + "%";  //use the percent
            }
        }
    }

    //Pie labels are the keys, and the pie values are the "total" property of each key-value object
    const pieKeys = allKeys.filter(key => key !== "All");   //remove the "All" key, not part of pie chart
    const pieLabels = pieKeys.map(option => option.replaceAll("_", " "));
    const pieValues = [];
    for (const key of pieKeys)
        pieValues.push(props.pivotitemmap.get(key).total);

            
    // --------------- Bar Graph --------------------------

    const barChartOptions = {
      
        chart: standardChartOptions,
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "50%"
            }
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            tickPlacement: 'on',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {year: 'yyyy', month: 'MMM yyyy', day: 'MMM yyyy'}
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return "$" + Currency.round(value) }
            }
        },
        tooltip: {
            x: {
                format: "MMMM yyyy"
            },
            y: {
                formatter: (value) => { return "$" + Currency.round(value) } 
            }
        }
    };

    //When a new key is selected from the dropdown, update the month series
    const updateChartForNewKey = (key) => {
        setMonthSeries(props.pivotitemmap.get(key).monthSeries);
    }
    
    //use the first key in the Map for our current key
    const [currentKey, setCurrentKey] = useState(initialKey); 
    const [currentKeyVal, setCurrentKeyVal] = useState(initialKey);

    //use the month series for the first key in the Map, or if there is no data in the map, none
    const initialValue = props.pivotitemmap ? props.pivotitemmap.get(initialKey) : null;
    const [monthSeries, setMonthSeries] = useState(initialValue ? initialValue.monthSeries : []); 
   
    //Let's set the bar chart color to match the color of the pie slice for the selected key, we need to find it in the array of pie chart colors 
    let keyIndex = 0;
    let found = false;
    for (let key of pieKeys) {
        if (key === currentKey) { 
            found = true;
            break;
        }
        keyIndex++;
    }

    const defaultAllColor = props.defaultAllColor ? props.defaultAllColor : ThemeColors.barChartGrey;    // gray for "All" if not supplied
    const barChartColor = found ? ThemeColors.pieChartColors[keyIndex] : defaultAllColor;


    if (!props.pivotitemmap || props.pivotitemmap.size === 0) { //no data
        if (currentKey)
            setCurrentKey(null);
        return null;
    }

    //if there was previously no data, and now there is, reset the state
    if (currentKey == null && initialKey) {
        setCurrentKey(initialKey);
        setCurrentKeyVal(initialKey);
        updateChartForNewKey(initialKey);
    }

    //Get the month earlier or later than this one
    const earlierorLaterMonth = (thisMonth, earlier) => { 
        let d = new Date(thisMonth);
        d.setMonth(d.getMonth() + (earlier ? -1 : 1));
        return d;
    }

    //Add empty months on either side to get some proper x-label placement, with too few entries there are duplicate x-axis labels for months
    let series = [...monthSeries];
    const monthsToShow = 12;    //show a full years worth of months
    if (series.length > 0 && series.length < monthsToShow) {
        const earlierMonthsToAdd = Math.floor((monthsToShow - series.length)/2);
        const laterMonthsToAdd = monthsToShow - series.length - earlierMonthsToAdd;

        let earliestMonth = series[0][0];
        for (let i=0; i<earlierMonthsToAdd; i++) {
            earliestMonth = earlierorLaterMonth(earliestMonth, true);
            series.unshift([earliestMonth, 0]);      //add to front
        }

        let latestMonth = series[series.length-1][0];
        for (let i=0; i<laterMonthsToAdd; i++) {
            latestMonth = earlierorLaterMonth(latestMonth, false);
            series.unshift([latestMonth, 0]);      //add to back
        }
    }

    const barChartData = [
        {
          name: "Monthly Total (" + currentKey + ")",
          data: series
        }
    ];


    return (
        <Paper style={styles.paper}>
                    
            <Typography variant="body2" style={styles.paperLabel}>{props.label}</Typography>  
            {props.hideKeyDropDown ? null :
                 <Autocomplete
                        size='small'
                        style={{margin: 20, marginLeft: 'auto',  maxWidth: 300}}
                        value={currentKey}
                        onChange={(event, newValue) => { setCurrentKey(newValue); updateChartForNewKey(newValue) }}
                        inputValue={currentKeyVal}
                        onInputChange={(event, newValue) => { setCurrentKeyVal(newValue);}}
                        options={allKeys}
                        getOptionLabel={(option) => {return option.replaceAll("_", " ")}}
                        blurOnSelect
                        disableClearable
                        openText="Select Type to Graph"
                        renderInput={(params) => <TextField {...params} label="Category" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />
            }

            <Grid container direction="row" spacing={3} style={{padding: 20}}>

                {pieValues.length > 0 ?
                    <Grid item md={4} sm={12}>
                        <Chart options={{...pieChartOptions, labels: pieLabels}}
                                series={pieValues}
                                type="pie"
                                height={window.innerHeight*0.50}
                            />  

                    </Grid>
                    : null
                }
                <Grid item md={pieValues.length > 0 ? 8 : 12} sm={12}>
                    <div style={{display: barChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                        <Chart options={ {...barChartOptions, colors: [barChartColor]} } 
                            series={barChartData}
                            type="bar"
                            height={window.innerHeight*0.40}
                        />  
                    </div>

                </Grid>
            </Grid>
        </Paper>
    );

}

