import React from 'react';

import { Typography } from '@material-ui/core'
import Chart from "react-apexcharts";


export function AccountStatWidget(props) {

    const style = {
        title: {
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '14pt',
            fontWeight: 'bold',
        },
    };

    const standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
    };

    const pieChartOptions = {
      
        chart: standardChartOptions,
        labels: [],
        colors: props.pieColors,
        legend : {
            show: true,
            position: 'bottom',
            formatter: function (seriesName, opts) {
                const count = opts.w.config.series[opts.seriesIndex];
                return opts.w.config.labels[opts.seriesIndex] + " (" + count + ")";  // use the actual count
            }
        },
        tooltip: {
            y: {
                formatter: (value) => { return value }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return val.toFixed(0) + "%";  //use the percent
            }
        }
    }

    const pieLabels = props.items.map(item => item.label.replaceAll("_", " "));
    const pieValues = props.items.map(item => item.value)

    return (

        <div style={{textAlign: 'center', marginTop: 20}}> 
            <Typography variant="body2" style={style.title}>{props.label}</Typography>  
            <Chart options={{...pieChartOptions, labels: pieLabels}}
                    series={pieValues}
                    type="pie"
                            />  
        </div>


    )
}

   

            
   