import React from 'react';
import { Container, Popover, Button, Box, Typography, Tooltip } from '@material-ui/core'
import { ThemeColors } from '../Theme';

import { Currency } from 'react-frontend-utils' 

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 10
    },
    button: {
        marginLeft: 10,
        marginRight: 10
    },
    status: {
        width: 150,
        textTransform: 'uppercase',
        padding: 2, 
        borderRadius: 2, 
        color: 'white', 
        textAlign: 'center'
    },
};



export function TransactionViewPopover(props) {

    let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
    if (maxWidth > 700)
        maxWidth = 700;

    if (!props.transaction)
        return null;

    const detailSubType = props.transaction.detail.subType ? (": " + props.transaction.detail.subType) : "";

    return (
        <Popover open={props.isOpen} 
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
            
            <Box style={{margin: 20, width: maxWidth}}>
                <div style={{marginBottom: 20, display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid ' + ThemeColors.appBarBackground}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Transaction: " + props.transaction.id}</Typography>  
                    <Typography variant="body1" style={{}}>{"Account: " + props.transaction.accountNo}</Typography>  
                </div>                    

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Tooltip title={props.transaction.type.tooltip}>
                        <div style={{...styles.status, backgroundColor: props.transaction.type.backgroundColor}}>
                            {props.transaction.type.label}
                        </div>
                    </Tooltip>
                    {props.transaction.detail.detailClass + detailSubType}
                    {props.transaction.renderBadges()}
                </div>

                <div style={{marginTop: 20}}/>
                
                <Typography variant="h5" style={{marginBottom: 10, fontWeight: 'bold'}}>{"$ " + Currency.round(props.transaction.amount)}</Typography>  

                {props.transaction.renderDetailForPopup(props.onTransactionClick)}
                
                <Container style={styles.container}>    
                    <Button onClick={props.onClose} color="primary" variant="outlined" style={styles.button}>Close</Button>
                </Container>
            </Box>
        </Popover>
    );


}