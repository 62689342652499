import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Journal } from '../models/Journal';



export class ManualJournalPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

 
    _okCallback;
    _cancelCallback;
    
    _initialSeverity = Journal.Severity.NOTICE;

    state = {
        severity: this._initialSeverity,
        severityValue: this._initialSeverity,
        account: null,
        entry: null
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    _accountFieldChanged = (event) => {
        
        const val = parseInt(event.target.value);            
        this.setState({account: val});
    }
    
    _entryFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({entry: val});
    }
    
    
    _okButtonPressed = () => {
                
        this._okCallback(this.state.severity.enumName, this.state.account, this.state.entry);
        this.setState({account: null, entry: null});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({account: null, entry: null});  //clear text for next time
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 700)
            maxWidth = 700;
                
        let severities = Object.keys(Journal.Severity).map(key => Journal.Severity[key]);

        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Journal User Entry</Typography>  
                    <div style={{marginTop: 20}}/>

                    <Autocomplete
                        size='small'
                        value={this.state.severity}
                        getOptionLabel={(severity) => severity.label }
                        onChange={(event, newValue) => { this.setState({severity: newValue}); }}
                        inputValue={this.state.severityValue}
                        onInputChange={(event, newValue) => { this.setState({severityValue: newValue}); }}
                        options={severities}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Severity" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    /> 

                    <Container style={this.styles.container}>    
                        <TextField label="Account" value={this.state.account} onChange={this._accountFieldChanged} variant="outlined" fullWidth={false} InputLabelProps={{ shrink: true}} />
                    </Container>

                    <TextField label="Journal Entry" autoFocus multiline={true} rows={5} value={this.state.entry} onChange={this._entryFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
        
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!this.state.entry} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}