import { DateUtils, Permissions } from 'react-frontend-utils'
import store from 'store'


/**
 * Holds the global variables for the frontend
 */
export const Global = {
    
    user: null,               //object of type User, the currently logged in user or null if none
    accountID: null,          //specific account ID, or null for all
    accountName: null,        //specific account name, or null for all
    isClientView: false,      //true if the user is in client view mode
    
    isAGSAccountAdmin: () => {
        if (!Global.user)
            return false;

        return Global.user.hasPermissionTo(Permissions.ADMINISTER_ACCOUNTS) && !Global.isClientView; 
    },

    isSuperAdmin: () => {
        if (!Global.user)
            return false;

        return Global.user.isSuperAdmin() && !Global.isClientView;
    },

    canEditAccount: () => {
        if (!Global.user)
            return false;

        return Global.user.hasPermissionTo(Permissions.MANAGE_ACCOUNT); 
    },

    getDateFormat: () => {
        const fmt = store.get("dateFormat");
        if (!fmt)
            return DateUtils.DateFormatType.ISO8601;
        else
            return fmt;    
    },
    
    setDateFormat: (format) => {
        if (!DateUtils.DateFormatType.isOneOf(format)) {
            console.log("Invalid DateFormatType: " + format);
            return;
        }
        store.set("dateFormat", format);    
    },
    
    
    
    dateFormat: (date) => {
        return DateUtils.dateFormat(date, Global.getDateFormat());
    },
    
    checkInTimeFormat: (date) => {
        return DateUtils.timeFormat(date, Global.getDateFormat());
    },

    getLastDatabase: () => {
        return store.get("database");
    },
    
    setLastDatabase: (database) => {
        store.set("database", database);    
    },
    
};
