import { DateUtils } from 'react-frontend-utils'

export class CashFlow {

    date;

    collectable;      //total amount owed to AGS 
    pastDue;          //total past due amount
    futurePayouts;    //total amount to be paid out in the future

    stripeAvailable;
    stripePending;
   
    constructor(json) {
        if (json) {
            this.date = DateUtils.parseJsonDate(json.date, true); //convert from UTC            
            this.collectable = json.collectable;
            this.pastDue = json.pastDue;
            this.futurePayouts = json.futurePayouts;
            this.stripeAvailable = json.stripeAvailable;
            this.stripePending = json.stripePending;
        }
    }

    compareByDate = (other) => {
        return this.date - other.date;
    }

    


}

         