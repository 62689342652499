import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Grid, Paper, Typography, TextField, Button, Divider, Tooltip} from '@material-ui/core'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { DefaultFeeDisplayPopover } from '../components/DefaultFeeDisplayPopover';
import { ThemeColors } from '../Theme'
import { Fee } from '../models/Fee' 
import { RestComponent } from 'react-frontend-utils' 


/**
 *  The FeePage Page provides a list of all fees, only accessible to superAdmins.  New fees can be added,
 *  properties changed, and deleted.
 *    
 */
export class FeePage extends RestComponent {
  
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        form: {
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        textfield: {
            marginRight: 5,
            marginBottom: 10,
            float: 'right'
        },
        rightGridStyle: {
            marginLeft: 20, 
            marginRight: 20,
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            maxWidth: '100%'        
        },                
    }
  
  
    _editPaperRef = React.createRef();
    _feePaperRef = React.createRef();
    
    constructor(props) {
        super(props);
        
        this.state.feeList = [];
        this.state.filteredList = [];
        this.state.feeToEdit = null;
        this.state.isNewFee = false;
        
        //Editing values
        this.state.name = null;
        this.state.type = false;
        this.state.multipler = 0.0;
        this.state.fixed = 0.0;
        this.state.agsCost = 0.0;
        
        this.state.confirmDeletePopoverOpen = false;
        this.state.defaultFeeDisplayIsOpen = false;

        this.state.defaultFees = null;
    }


    /**
     * When the page loads, fetch all fees
     */
    componentDidMount() {
        super.componentDidMount();
        this._fetchFees();
    }
    
    
    _clearFee = () => {
        this.setState({feeToEdit: null, 
                       isNewFee: false, 
                       name: "", 
                       type: null,
                       multipler: 0.0,
                       fixed: 0,
                       agsCost: 0});
    }
    
    _fetchFees = (isNew = false) => {
        this.setBusy(true);  
        this._clearFee();
        this.setState({feeList: [], filteredList: []}); 
        this.secureJSONFetch("/act/fee", {}, this._fetchCallback, this._fetchError); 
    }
    
    _fetchCallback = (response) => {
        if (response) {
            const feeList = response.map((json) => new Fee(json));
            this.setState({feeList: feeList, filteredList: feeList, feeToEdit: null, isNewFee: false, name: "", type: null, multipler: 0, fixed: 0, agsCost: 0});  //re-render            
        }
        this.setBusy(false);
    }

    _fetchError = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    

    
    _filterFieldChanged = (event) => {
        let searchString = event.target.value;
        
        if (searchString)  { //has a search string   
            
            searchString = searchString.toLowerCase();
            this.state.filteredList = this.state.feeList.filter((fee) => {

                return fee.name.toLowerCase().includes(searchString);         
            });
        }
        else
            this.state.filteredList = this.state.feeList;
        
        this.forceUpdate(); //re-render            
    }
    
    
     _addFee = () => {
        window.scrollTo(0, this._editPaperRef.current.offsetTop);  //jump to the top
        this._clearFee();  //clear all fields
        this.setState({feeToEdit: {name: "New"}, isNewFee: true});   
    }
    
    _editFee = (feeToEdit) => {
        window.scrollTo(0, this._editPaperRef.current.offsetTop);  //jump to the top
        this.setState({feeToEdit: feeToEdit, 
                       isNewFee: false, 
                       name: feeToEdit.name, 
                       type: feeToEdit.type,
                       multipler: feeToEdit.multipler,
                       fixed: feeToEdit.fixed,
                       agsCost: feeToEdit.agsCost});
    }
    
    _discardChanges = () => {
        window.scrollTo(0, this._feePaperRef.current.offsetTop);  //jump to the top
        this._clearFee();
    }
    
   
    /**
     * Called when the fee name field value changes - only fires when fee is "new"
     * @param {type} event the TextField
     */
    _feeNameFieldChanged = (event) => {
        
        if (!this.state.isNewFee) //should always be new but just in-case, we never want to rename a fee
            return;
        
        const name = event.target.value;
        const filteredName = name.replace(/\W/g, '');  //remove all non alphanumeric characters except underscore
        
        this.setState({name: filteredName});
    }
    

    /**
     * A decimal value is changed
     * @param {String} name name of the state variable
     * @param {String} newValue 
     */
    _valueChanged = (name, newValue) => {
        this.state[name] = newValue;
        this.forceUpdate();
    }


    _checkFee = (label, val) => {
    
        val = parseFloat(val);
        if (val < 0 || val == null || isNaN(val)) {
            this.showConfirmAlert("Error", "Value for " + label + " invalid or negative", 'red');
            return false;
        }
        return true;            
    }

    
    //Post an update to add or change the fee.  On success, refetch fee to update the list
    _updateFee = () => {
        
        if (!this._checkFee("Multipler", this.state.multipler) ||
            !this._checkFee("Fixed Value", this.state.fixed) ||
            !this._checkFee("AGS Cost", this.state.agsCost))
            return;

        if (!this.state.type) {
            this.showConfirmAlert("Error", "No Type selected", 'red');
            return;
        }
        if (!this.state.name) {
            this.showConfirmAlert("Error", "No Name provided", 'red');
            return;
        }


        const feeToChange = new Fee();
        feeToChange.name = this.state.name;
        feeToChange.type = this.state.type.enumName;
        feeToChange.multipler = this.state.multipler;
        feeToChange.fixed = this.state.fixed;
        feeToChange.agsCost = this.state.agsCost;

        //Post the change - refetch on success, or show error on error
        this.secureJSONFetch("/act/fee", 
                             {method: "PUT", body: JSON.stringify(feeToChange)},
                             () => this._fetchFees(this.state.isNewFee),
                             this._fetchError);                         
    }
    

   
    _deleteFeePrompt = () => {

        //Check required information
        this.showConfirmAlert("Confirm", 
                              "Really delete fee \"" + this.state.feeToEdit.name + "\" permanently?",
                              'red',
                              "Cancel", 
                              this.confirmDeleteFee,
                              "Delete",
                              'red');
    }

    
        
    //Post an delete to remove the fee.  On success, refetch fees to update the list        
    confirmDeleteFee = () => {
        
        //Post the delete - refetch on success, or show error on error
        this.secureJSONFetch("/act/fee/" + this.state.feeToEdit.name, 
                             {method: "DELETE"},
                             this._fetchFees,
                             this._fetchError);       
        
    }

    _showDefaultFees = () => {
        this.secureJSONFetch("/act/fee/Default", {}, this._fetchDefaultCallback, this._fetchError); 
    }
    

    _fetchDefaultCallback = (response) => {
        if (response) {
            const defaultFees = response.map((json) => new Fee(json));
            this.setState({defaultFees: defaultFees, defaultFeeDisplayIsOpen: true});
        }
    }
    
    render() {
        
        const filterColor = this.state.filteredList.length > 0 ? 'primary' : 'secondary';

        let types = Object.keys(Fee.Type).map(key => Fee.Type[key]);

        return (
            <Fragment>

                {this.getConfirmAlertComponent()}

                <DefaultFeeDisplayPopover isOpen={this.state.defaultFeeDisplayIsOpen} defaultFees={this.state.defaultFees} onClose={() => this.setState({defaultFeeDisplayIsOpen: false})}/>

                <Tooltip title="Filter on fee name">
                    <TextField label="Filter" onChange={this._filterFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />
                </Tooltip>

                <Tooltip title="Show default fees for Accounts not assigned custom fees">
                    <Button onClick={this._showDefaultFees} variant="outlined" color='primary'>Show Defaults</Button>
                </Tooltip>
                
                <Grid container direction="row" spacing={2} >
                    
                    { /*---------------------------- LEFT GRID FEES -------------------------------------------*/ null }

                   <Grid item sm={6} xs={12}>

                     <Paper style={{...this.styles.paper, marginBottom: 0}} ref={this._feePaperRef}>

                        <div style={{display: 'flex', height: 40}}>

                            <Typography variant="body2" style={this.styles.paperLabel}>{"Fees (" + this.state.filteredList.length + ")"}</Typography>  

                            <Tooltip title="Create a new Fee">
                                <IconButton edge="end" onClick={this._addFee} style={{marginTop: -4, marginRight: 4}}>
                                    <AddCircleOutlineIcon style={{color: ThemeColors.addColor}} />
                                </IconButton>
                            </Tooltip>

                        </div>

                        {this.getBusyComponent()}

                        <List dense>
                            {this.state.filteredList.map((fee, index) =>
                                (<div key={index}>
                                    {index > 0 ? <Divider style={this.styles.divider}/> : <Divider/>  /*Smaller dividers except the first*/}
                                    <ListItem>       
                                        <ListItemText primary={fee.name} 
                                                      secondary={(<div>
                                                                    <div>{fee.type.label}</div>
                                                                  </div>)}/>
                                                                                            
                                        <ListItemSecondaryAction>
                                            <Tooltip title={"Edit " + fee.name}>
                                                <IconButton edge="end" onClick={() => {this._editFee(fee);}}>
                                                    <CreateIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>                                                   
                                    </ListItem>
                                 </div>)                       
                            )}
                        </List>
                    </Paper>

                </Grid>

                { /*---------------------------- RIGHT GRID EDIT DATABASE -------------------------------------------*/ null }


                <Grid item sm={6} xs={12}>
                    <Paper style={this.styles.paper} ref={this._editPaperRef} >
                        <div style={{display: 'flex', height: 40}}>

                            <Typography variant="body2" style={this.styles.paperLabel}>{this.state.feeToEdit ? (this.state.isNewFee ? "Add New Fee" : ("Edit \"" + this.state.feeToEdit.name + "\" Fee")) : "Edit Fee"}</Typography> 

                            <Tooltip title="Discard Changes">
                                <IconButton edge="end" disabled={this.state.feeToEdit === null} onClick={this._discardChanges} style={{marginTop: -4, marginRight: 4}}>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>

                        </div>
                        
                        <Grid container direction='row' style={{marginTop: 10}} alignContent='center' spacing={3} >

                            {this.state.isNewFee ?
                                <Grid item xs={12} style={this.styles.rightGridStyle}>
                                    <TextField label="Name" value={this.state.name} onChange={this._feeNameFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true }} />
                                </Grid>
                                : null
                            }

                            <Grid item xs={12} style={this.styles.rightGridStyle}>
                                <Autocomplete
                                    size='small'
                                    style={{width: 400}}
                                    disabled={this.state.feeToEdit === null}
                                    value={this.state.type}
                                    getOptionLabel={(type) => type.label }
                                    onChange={(event, newValue) => { this.setState({type: newValue}); }}
                                    inputValue={this.state.typeValue}
                                    onInputChange={(event, newValue) => { this.setState({typeValue: newValue}); }}
                                    options={types}
                                    blurOnSelect
                                    renderInput={(params) => <TextField {...params} label="Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                />     
                            </Grid>
                            

                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="Multiplier (Decimal)" variant='outlined' fullWidth={true} value={this.state.multipler}
                                            disabled={this.state.feeToEdit === null}
                                            onChange={(event) => { this._valueChanged("multipler", event.target.value)}}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="Fixed Amount (USD)" variant='outlined' fullWidth={true} value={this.state.fixed}
                                            disabled={this.state.feeToEdit === null}
                                            onChange={(event) => { this._valueChanged("fixed", event.target.value)}}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="AGS Cost (USD)" variant='outlined' fullWidth={true} value={this.state.agsCost}
                                            disabled={this.state.feeToEdit === null}
                                            onChange={(event) => { this._valueChanged("agsCost", event.target.value)}}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>
                            
                            
                            <Grid item xs={12} style={{...this.styles.rightGridStyle, marginTop: 30}}>
                                <Grid container direction='column' alignContent='center' spacing={3} >
                   
                        
                                    <Grid item>
                                        <Button onClick={this._updateFee} variant="outlined" fullWidth={true} color='primary' disabled={this.state.feeToEdit === null} style={this.styles.button}>{this.state.isNewFee ? "Add New Fee" : "Apply Changes"}</Button>
                                    </Grid>

                                    {this.state.isNewFee ? null :
                                        <Grid item>                        
                                            <Button onClick={this._deleteFeePrompt} variant="outlined" fullWidth={true} disabled={this.state.feeToEdit === null} color='secondary' style={this.styles.button}>Delete Fee</Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>

                        <div style={{padding: 20}}/>
                        {this.getBusyComponent("center", {marginBottom: 15})}

                    </Paper>
                </Grid>
             </Grid>
             
            </Fragment>
        );

    }
    
}export default withCookies(FeePage);