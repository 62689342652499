import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Grid, Paper, Typography, TextField, Button, Divider, Tooltip} from '@material-ui/core'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeColors } from '../Theme'
import { Subscription } from '../models/Subscription' 
import { RestComponent, DateUtils } from 'react-frontend-utils' 


/**
 *  The SubscriptionPage Page provides a list of all Subscriptions, only accessible to superAdmins.  New Subscriptions can be added,
 *  properties changed, and deleted.
 *    
 */
export class SubscriptionPage extends RestComponent {
  
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        form: {
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        textfield: {
            marginRight: 5,
            marginBottom: 10,
            float: 'right'
        },
        rightGridStyle: {
            marginLeft: 20, 
            marginRight: 20,
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            maxWidth: '100%'        
        },                
    }
  
  
    _editPaperRef = React.createRef();
    _subscriptionPaperRef = React.createRef();
    
    constructor(props) {
        super(props);
        
        this.state.subscriptionList = [];
        this.state.filteredList = [];
        this.state.subscriptionToEdit = null;
        this.state.isNewSubscription = false;
        
        //Editing values
        this.state.name = null;
        this.state.description = "";
        this.state.recurMonth = 0;
        this.state.recurDay = 1;
        this.state.firstPayment = false;
        this.state.amount = 0.0;
        this.state.agsCost = 0.0;
    }


    /**
     * When the page loads, fetch all subscriptions
     */
    componentDidMount() {
        super.componentDidMount();
        this._fetchSubscriptions();
    }
    
    
    _clearSubscription = () => {
        this.setState({subscriptionToEdit: null, 
                       isNewSubscription: false, 
                       name: "", 
                       description: "",
                       recurMonth: "",
                       recurDay: "",
                       firstPayment: null,
                       amount: "",
                       agsCost: ""});
    }
    
    _fetchSubscriptions = (isNew = false) => {
        this.setBusy(true);  
        this._clearSubscription();
        this.setState({subscriptionList: [], filteredList: []}); 
        this.secureJSONFetch("/act/subscription", {}, this._fetchCallback, this._fetchError); 
    }
    
    _fetchCallback = (response) => {
        if (response) {
            const subscriptionList = response.map((json) => new Subscription(json));
            this.setState({subscriptionList: subscriptionList, filteredList: subscriptionList, subscriptionToEdit: null, isNewSubscription: false});  //re-render            
        }
        this.setBusy(false);
    }

    _fetchError = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    

    
    _filterFieldChanged = (event) => {
        let searchString = event.target.value;
        
        if (searchString)  { //has a search string   
            
            searchString = searchString.toLowerCase();
            this.state.filteredList = this.state.subscriptionList.filter((subscription) => {

                return subscription.name.toLowerCase().includes(searchString);         
            });
        }
        else
            this.state.filteredList = this.state.subscriptionList;
        
        this.forceUpdate(); //re-render            
    }
    
    
     _addSubscription = () => {
        window.scrollTo(0, this._editPaperRef.current.offsetTop);  //jump to the top
        this._clearSubscription();  //clear all fields
        this.setState({subscriptionToEdit: {name: "New"}, isNewSubscription: true});   
    }
    
    _editSubscription = (subscriptionToEdit) => {
        window.scrollTo(0, this._editPaperRef.current.offsetTop);  //jump to the top
        this.setState({subscriptionToEdit: subscriptionToEdit, 
                       isNewSubscription: false, 
                       name: subscriptionToEdit.name, 
                       description: subscriptionToEdit.description,
                       recurMonth: subscriptionToEdit.recurMonth,
                       recurDay: subscriptionToEdit.recurDay,
                       firstPayment: subscriptionToEdit.firstPayment,
                       amount: subscriptionToEdit.amount,
                       agsCost: subscriptionToEdit.agsCost});
    }
    
    _discardChanges = () => {
        window.scrollTo(0, this._subscriptionPaperRef.current.offsetTop);  //jump to the top
        this._clearSubscription();
    }
    
   
    /**
     * Called when the subscription name field value changes - only fires when subscription is "new"
     * @param {type} event the TextField
     */
    _subscriptionNameFieldChanged = (event) => {
        
        if (!this.state.isNewSubscription) //should always be new but just in-case, we never want to rename a subscription
            return;
        
        const name = event.target.value;
        const filteredName = name.replace(/\W/g, '');  //remove all non alphanumeric characters except underscore
        
        this.setState({name: filteredName});
    }
    

    /**
     * A decimal value is changed
     * @param {String} name name of the state variable
     * @param {String} newValue 
     */
    _valueChanged = (name, newValue) => {
        this.state[name] = newValue;
        this.forceUpdate();
    }


    _checkFloat = (label, val, allowNeg = false) => {
    
        val = parseFloat(val);
        if (val == null || isNaN(val)) {
            this.showConfirmAlert("Error", "Value for " + label + " invalid or negative", 'red');
            return false;
        }
        if (!allowNeg && val < 0) {
            this.showConfirmAlert("Error", "Value for " + label + " is negative", 'red');
            return false;
        }

        return true;            
    }

    
    //Post an update to add or change the subscription.  On success, refetch subscription to update the list
    _updateSubscription = () => {
        
        if (!this._checkFloat("Amount", this.state.amount, true))
            return;

        if (!this._checkFloat("AGS Cost", this.state.agsCost))
            return;

        if (this.state.recurMonth < 0 || this.state.recurMonth > 12)
            this.showConfirmAlert("Error", "Invalid Recur Month", 'red');

        if (this.state.recurDay < 1 || this.state.recurDay > 30) {
            this.showConfirmAlert("Error", "Invalid Recur Day", 'red');
            return;
        }
        if (!this.state.name) {
            this.showConfirmAlert("Error", "No Name provided", 'red');
            return;
        }


        const subscriptionToChange = new Subscription();
        subscriptionToChange.name = this.state.name;
        subscriptionToChange.description = this.state.description;
        subscriptionToChange.recurMonth = this.state.recurMonth;
        subscriptionToChange.recurDay = this.state.recurDay;
        subscriptionToChange.firstPayment = this.state.firstPayment.enumName;
        subscriptionToChange.amount = this.state.amount;
        subscriptionToChange.agsCost = this.state.agsCost;

        //Post the change - refetch on success, or show error on error
        this.secureJSONFetch("/act/subscription", 
                             {method: "PUT", body: JSON.stringify(subscriptionToChange)},
                             () => this._fetchSubscriptions(this.state.isNewSubscription),
                             this._fetchError);                         
    }
    

   
    _deleteSubscriptionPrompt = () => {

        //Check required information
        this.showConfirmAlert("Confirm", 
                              "Really delete subscription \"" + this.state.subscriptionToEdit.name + "\" permanently?",
                              'red',
                              "Cancel", 
                              this.confirmDeleteSubscription,
                              "Delete",
                              'red');
    }

    
        
    //Post an delete to remove the subscription.  On success, refetch subscriptions to update the list        
    confirmDeleteSubscription = () => {
        
        //Post the delete - refetch on success, or show error on error
        this.secureJSONFetch("/act/subscription/" + this.state.subscriptionToEdit.name, 
                             {method: "DELETE"},
                             this._fetchSubscriptions,
                             this._fetchError);       
        
    }
    
    _triggerSubscriptionPrompt = () => {
        this.showConfirmAlert("Confirm", 
                                "Really trigger subscription processing? This cannot be stopped",
                                'red',
                                "Cancel", 
                                this._confirmTriggerSubscriptionProcessing,
                                "Trigger",
                                'blue');
    }

    _confirmTriggerSubscriptionProcessing = () => {
        
        //Post the trigger
        this.secureJSONFetch("/act/subscriptions", 
                             {method: "POST"},
                             null,
                             this._fetchError);       
        
    }

    static getMonth(val) {
        if (val === 0)
            return "Each Month";
        else
            return DateUtils.monthNames[val-1];
    }

   
    render() {
        
        const filterColor = this.state.filteredList.length > 0 ? 'primary' : 'secondary';

        let firstPayments = Object.keys(Subscription.FirstPayment).map(key => Subscription.FirstPayment[key]);

        let infoText = null;
        if (this.state.subscriptionToEdit)
            infoText = Subscription.describe(this.state.recurMonth, this.state.recurDay, this.state.firstPayment);

        return (
            <Fragment>

                {this.getConfirmAlertComponent()}

                <Tooltip title="Filter on subscription name">
                    <TextField label="Filter" onChange={this._filterFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />
                </Tooltip>

                <Tooltip title="Manually Trigger Subscription Process No">
                    <Button onClick={this._triggerSubscriptionPrompt} variant="outlined" color='primary'>Trigger Subscriptions</Button>
                </Tooltip>
                
                <Grid container direction="row" spacing={2} >
                    
                    { /*---------------------------- LEFT GRID SUBSCRIPTIONS -------------------------------------------*/ null }

                   <Grid item sm={6} xs={12}>

                     <Paper style={{...this.styles.paper, marginBottom: 0}} ref={this._subscriptionPaperRef}>

                        <div style={{display: 'flex', height: 40}}>

                            <Typography variant="body2" style={this.styles.paperLabel}>{"Subscriptions (" + this.state.filteredList.length + ")"}</Typography>  

                            <Tooltip title="Create a new Subscription">
                                <IconButton edge="end" onClick={this._addSubscription} style={{marginTop: -4, marginRight: 4}}>
                                    <AddCircleOutlineIcon style={{color: ThemeColors.addColor}} />
                                </IconButton>
                            </Tooltip>

                        </div>

                        {this.getBusyComponent()}

                        <List dense>
                            {this.state.filteredList.map((subscription, index) =>
                                (<div key={index}>
                                    {index > 0 ? <Divider style={this.styles.divider}/> : <Divider/>  /*Smaller dividers except the first*/}
                                    <ListItem>       
                                        <ListItemText primary={subscription.name} 
                                                      secondary={(<div>
                                                                    <div>{"$" + subscription.amount}</div>
                                                                  </div>)}/>
                                                                                            
                                        <ListItemSecondaryAction>
                                            <Tooltip title={"Edit " + subscription.name}>
                                                <IconButton edge="end" onClick={() => {this._editSubscription(subscription);}}>
                                                    <CreateIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>                                                   
                                    </ListItem>
                                 </div>)                       
                            )}
                        </List>
                    </Paper>

                </Grid>

                { /*---------------------------- RIGHT GRID EDIT SUBSCRIPTIONS -------------------------------------------*/ null }


                <Grid item sm={6} xs={12}>
                    <Paper style={this.styles.paper} ref={this._editPaperRef} >
                        <div style={{display: 'flex', height: 40}}>

                            <Typography variant="body2" style={this.styles.paperLabel}>{this.state.subscriptionToEdit ? (this.state.isNewSubscription ? "Add New Subscription" : ("Edit \"" + this.state.subscriptionToEdit.name + "\" Subscription")) : "Edit Subscription"}</Typography> 

                            <Tooltip title="Discard Changes">
                                <IconButton edge="end" disabled={this.state.subscriptionToEdit === null} onClick={this._discardChanges} style={{marginTop: -4, marginRight: 4}}>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>

                        </div>
                        
                        <Grid container direction='row' style={{marginTop: 10}} alignContent='center' spacing={3} >

                            {this.state.isNewSubscription ?
                                <Grid item xs={12} style={this.styles.rightGridStyle}>
                                    <TextField label="Name" value={this.state.name} onChange={this._subscriptionNameFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true }} />
                                </Grid>
                                : null
                            }

                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="Description (Client Visible)" variant='outlined' fullWidth={true} value={this.state.description}
                                            disabled={this.state.subscriptionToEdit === null}
                                            onChange={(event) => { this._valueChanged("description", event.target.value)}}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <Autocomplete
                                        size='small'
                                        style={{width: 400}}
                                        disabled={this.state.subscriptionToEdit === null}
                                        value={this.state.recurMonth}
                                        onChange={(event, newValue) => { this.setState({recurMonth: newValue}); }}
                                        inputValue={this.state.recurMonthValue}
                                        onInputChange={(event, newValue) => { this.setState({recurMonthValue: newValue}); }}
                                        options={Array.from(Array(13).keys())} 
                                        getOptionLabel={(val) => SubscriptionPage.getMonth(val) }
                                        blurOnSelect
                                        renderInput={(params) => <TextField {...params} label="Recur Month" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                />    

                            </Grid>
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <Autocomplete
                                        size='small'
                                        style={{width: 400}}
                                        disabled={this.state.subscriptionToEdit === null}
                                        value={this.state.recurDay}
                                        onChange={(event, newValue) => { this.setState({recurDay: newValue}); }}
                                        inputValue={this.state.recurDayValue}
                                        onInputChange={(event, newValue) => { this.setState({recurDayValue: newValue}); }}
                                        options={Array.from(Array(30).keys()).map(v => v+1)} 
                                        getOptionLabel={(val) => val.toString() }
                                        blurOnSelect
                                        renderInput={(params) => <TextField {...params} label="Recur Day" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                /> 
  
                            </Grid>

                            <Grid item xs={12} style={this.styles.rightGridStyle}>
                                <Autocomplete
                                    size='small'
                                    style={{width: 400}}
                                    disabled={this.state.subscriptionToEdit === null}
                                    value={this.state.firstPayment}
                                    getOptionLabel={(firstPayment) => firstPayment.label }
                                    onChange={(event, newValue) => { this.setState({firstPayment: newValue}); }}
                                    inputValue={this.state.firstPaymentValue}
                                    onInputChange={(event, newValue) => { this.setState({firstPaymentValue: newValue}); }}
                                    options={firstPayments}
                                    blurOnSelect
                                    renderInput={(params) => <TextField {...params} label="First Payment" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                />     
                            </Grid>
                            
                            
                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="Amount (USD)" variant='outlined' fullWidth={true} value={this.state.amount}
                                            disabled={this.state.subscriptionToEdit === null}
                                            onChange={(event) => { this._valueChanged("amount", event.target.value)}}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>

                            <Grid item xs={12} style={this.styles.rightGridStyle}>

                                <TextField label="AGS Cost (USD)" variant='outlined' fullWidth={true} value={this.state.agsCost}
                                            disabled={this.state.subscriptionToEdit === null}
                                            onChange={(event) => { this._valueChanged("agsCost", event.target.value)}}
                                            InputLabelProps={{ shrink: true }} />
                            </Grid>


                            <Grid item xs={12} style={this.styles.rightGridStyle}>
                                <Typography variant="body1" >{infoText}</Typography> 
                            </Grid>

                            
                            <Grid item xs={12} style={{...this.styles.rightGridStyle, marginTop: 30}}>
                                <Grid container direction='column' alignContent='center' spacing={3} >
                   
                                    <Grid item>
                                        <Button onClick={this._updateSubscription} variant="outlined" fullWidth={true} color='primary' disabled={this.state.subscriptionToEdit === null} style={this.styles.button}>{this.state.isNewSubscription ? "Add New Subscription" : "Apply Changes"}</Button>
                                    </Grid>

                                    {this.state.isNewSubscription ? null :
                                        <Grid item>                        
                                            <Button onClick={this._deleteSubscriptionPrompt} variant="outlined" fullWidth={true} disabled={this.state.subscriptionToEdit === null} color='secondary' style={this.styles.button}>Delete Subscription</Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>

                        <div style={{padding: 20}}/>
                        {this.getBusyComponent("center", {marginBottom: 15})}

                    </Paper>
                </Grid>
             </Grid>
             
            </Fragment>
        );

    }
    
}export default withCookies(SubscriptionPage);