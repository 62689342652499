import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Grid } from '@material-ui/core'
import { TextField, Typography, Paper } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';

import Chart from "react-apexcharts";

import { MonthlyGraphWidget } from '../components/MonthlyGraphWidget'
import { TaxYearPopover } from '../components/TaxYearPopover';

import { Aggregate, PivotTable, AggregateSum } from '../models/Aggregate';
import { Global } from '../models/Global'
import { Account } from '../models/Account'

import { RestComponent, SummaryWidget, Currency, PopupMenu } from 'react-frontend-utils' 
import { ThemeColors } from '../Theme';
import { AccountViewPopover } from '../components/AccountViewPopover';




export class AggregatePage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        }
    };

    _standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    };

    _clickChartElement = (event, chartContext, config )=> {  //when clicked, switch to the Account
        const selectedAccount = config.w.config.xaxis.categories[config.dataPointIndex];
        this._showAccount(selectedAccount);
    }

    _barChartOptions = {
      
        chart: {...this._standardChartOptions,   
            events: { dataPointSelection: this._clickChartElement }   
        }, 
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: "90%"
            }
        },
        tooltip: {
            y: {
                formatter: (value) => { return "$" + Currency.round(value); } 
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value) => { return "$" + Currency.round(value, 0); }
        },
    };

    _revenueChartOpts = {
        ...this._barChartOptions,
        xaxis: {
            categories: [],
            position: 'top',
            labels: {
                formatter: (value) => { return "$" + Currency.round(value, 0); }
            }
        },
    };

    _patronSalesChartOpts = {
        ...this._barChartOptions,
        xaxis: {
            categories: [],
            position: 'top',
            labels: {
                formatter: (value) => { return "$" + Currency.round(value, 0); }
            }
        },
    }
    
    
    static START_YEAR = 2024;

    static currentYear = (new Date()).getFullYear();
    static currentMonth = (new Date()).getMonth() + 1;  //getMonth() is zero based

    static prevMonth() {
        let month = this.currentMonth - 1; 
        let year = this.currentYear;
        if (month < 1) {
            month = 12;
            year = year-1;
        }
        return {month: month, year: year};
    }


     //options for the search time range pulldown
    _searchDateRangeOptions = [
        {label: "This Month", startMonth: AggregatePage.currentMonth, endMonth: AggregatePage.currentMonth, year: AggregatePage.currentYear},
        {label: "Last Month", startMonth: AggregatePage.prevMonth().month, endMonth: AggregatePage.prevMonth().month, year: AggregatePage.prevMonth().year},
        {label: "Year to Date", startMonth: 1, endMonth: AggregatePage.currentMonth, year: AggregatePage.currentYear},  
    ];
    

    constructor(props) {
        super(props);
        this.state.pivottable = null;
        this.state.revenueChartData = null;
        this.state.revenueChartOptions = null;
        this.state.patronSalesChartData = null;
        this.state.patronSalesChartOptions = null;

        // Add the previous 3 years to the options, but only since we started the Portal
        for (let year = AggregatePage.currentYear-1; year >= AggregatePage.currentYear-3 && year >= AggregatePage.START_YEAR; year--) {
            this._searchDateRangeOptions.push({label: String(year), year: year});
        }

        this._searchDateRangeOptions.push({label: "All"});

        this.state.selectedDateRange = this._searchDateRangeOptions[0];

        this.state.taxYearPopoverOpen = false;
    }
    
    _refresh() {
        this._fetchAggregates();  
        if (Global.isSuperAdmin())
            this._fetchAllAccountAggregates();
    }
    
    
    /**
     * When the page loads, immediately fetch the list of Aggregates
     */
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("accountChangeEvent", this._accountHasChanged);
        this._refresh();
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("accountChangeEvent", this._accountHasChanged);
    }

    //callback when account has changed
    _accountHasChanged = () => {
        this.setState({pivottable: null});
        this._fetchAggregates();  
    }
    
    
    _fetchAggregates = () => {
        
        let queryString = "";   //empty, initially
        if (Global.accountID)
            queryString += "accountNo=" + Global.accountID + "&"; 
                        
        if (this.state.selectedDateRange) {
            if (this.state.selectedDateRange.startMonth)
                queryString += "startMonth=" + this.state.selectedDateRange.startMonth + "&";
            if (this.state.selectedDateRange.endMonth)
                queryString += "endMonth=" + this.state.selectedDateRange.endMonth + "&";
            if (this.state.selectedDateRange.year)
                queryString += "year=" + this.state.selectedDateRange.year + "&";
        }
        
        if (queryString)
            queryString = "?" + queryString.slice(0, -1);   //add the ? and remove the trailing &

        this.setState({pivottable: null});  // re-render nothing, to clear
        this.incrementBusy();
        this.secureJSONFetch("/act/aggregate", {}, this._fetchAggregatesCallback, this._fetchErrorCallback, queryString);     
    }
    
    //Callback for fetching Aggreages - response is a list of Aggregates
    _fetchAggregatesCallback = (response) => {
        if (response) {            
            const list = response.map(ag => new Aggregate(ag));
            list.sort((a, b) => a.compare(b));
            
            const pivot = new PivotTable(list);
            this.setState({pivottable: pivot});

        }            
        this.decrementBusy();
    }
    

    _fetchAllAccountAggregates = () => {
        
        let queryString = "";   //empty, initially
    
        if (this.state.selectedDateRange) {
            if (this.state.selectedDateRange.startMonth)
                queryString += "startMonth=" + this.state.selectedDateRange.startMonth + "&";
            if (this.state.selectedDateRange.endMonth)
                queryString += "endMonth=" + this.state.selectedDateRange.endMonth + "&";
            if (this.state.selectedDateRange.year)
                queryString += "year=" + this.state.selectedDateRange.year + "&";
        }
        
        if (queryString)
            queryString = "?" + queryString.slice(0, -1);   //add the ? and remove the trailing &

        this.incrementBusy();
        this.secureJSONFetch("/act/aggregateAll", {}, this._fetchAllAccountAggregatesCallback, this._fetchErrorCallback, queryString);     
    }
    
    //Callback for fetching Aggreages - response is a list of Aggregates
    _fetchAllAccountAggregatesCallback = (response) => {
        if (response) {            
            const revenueList = response.map(ag => new Aggregate(ag)).map(aggregate => new AggregateSum(aggregate));
            
            revenueList.sort((a, b) => a.compareByRevenue(b));

            const patronSalesList = [...revenueList];       // shallow copy - ok, we are just resorting the same objects for display
            patronSalesList.sort((a, b) => a.compareByPatronSales(b));

            // Create the Revenue chart data
            const revenueSeries = [];
            const revenueOpts = this._revenueChartOpts;
            revenueOpts.colors = [ThemeColors.profitGreen]; 
            revenueOpts.xaxis.categories.length = 0;    //clear

            for (let rev of revenueList) {
                if (rev.revenue > 0) { // only include nonzero accounts
                    revenueOpts.xaxis.categories.push(rev.accountNo.toString())
                    revenueSeries.push(rev.revenue);
                }
            }

            const revenueChartData = [
                {
                  name: "Revenue by Account",
                  data: revenueSeries
                }
            ];


            // Create the Patron Sales chart data
            const patronSalesSeries = [];
            const patronSalesOpts = this._patronSalesChartOpts;
            patronSalesOpts.colors = ['#0000F0']; 
            patronSalesOpts.xaxis.categories.length = 0;    //clear

            for (let ps of patronSalesList) {
                if (ps.patronSales > 0) { // only include nonzero accounts
                    patronSalesOpts.xaxis.categories.push(ps.accountNo.toString())
                    patronSalesSeries.push(ps.patronSales);
                }
            }

            const patronSalesChartData = [
                {
                  name: "Patron Sales by Account",
                  data: patronSalesSeries
                }
            ];

            this.setState({revenueChartData: revenueChartData, revenueChartOptions: revenueOpts,
                          patronSalesChartData: patronSalesChartData, patronSalesChartOptions: patronSalesOpts});

        }            
        this.decrementBusy();         
    }


    _showAccount = (accountNo) => {
        this.secureJSONFetch("/act/account/" + accountNo, {}, this._fetchAccountCallback, this._fetchErrorCallback); 
    }

    _fetchAccountCallback = (response) => {
        if (response) {            
            const act = new Account(response);
            this.setState({accountInPopover: act, accountPopoverOpen: true});
        }            
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    _newSelection = () => {
        setTimeout(() => this._refresh(), 1);   //let state update, then refetch
    }

    _exportFullAccountReport = () => {
        this.secureJSONFetch("/act/account/download", {method: "POST"},
                             this._exportSuccess, this._fetchErrorCallback); 
    }

    _exportOverdueAccountReport = () => {
        this.secureJSONFetch("/act/account/downloadOverdue", {method: "POST"},
                             this._exportSuccess, this._fetchErrorCallback); 
    }

    _taxYearPopoverOkCallback = (year, threshold) => {
        this.setState({taxYearPopoverOpen: false});
        if (threshold === 0) {
            this.showConfirmAlert("Warning", "A threshold of zero will cause import errors. Use this for informational purposes only.",
                                'black',
                                "Cancel", 
                                () => this._exportTaxReport(year, threshold),
                                "Proceed",
                                'green');

        }
        else 
            this._exportTaxReport(year, threshold);
    }

    _exportTaxReport = (year, threshold) => {
        this.secureJSONFetch("/act/account/exportTax?taxYear=" + year + "&threshold=" + threshold, {method: "POST"},
                             this._exportSuccess, this._fetchErrorCallback);
    }

    _exportSuccess = () => {
        this.showConfirmAlert("Report Generation in Progress", "The report will be emailed to you in a few minutes. Did not receive it? Check journal for errors.", 'green'); 
    }


    _generateReportsMenu = () => {
  
        const items = (() => {

            let menuItems = [];

            menuItems.push({label: "Full Account Report", selectCallback: this._exportFullAccountReport});
            menuItems.push({label: "Overdue Account Report", selectCallback: this._exportOverdueAccountReport});
            menuItems.push({label: "1099 Tax Data", selectCallback: () => this.setState({taxYearPopoverOpen: true})});
                       
            return menuItems;
        })();

        return (
            <PopupMenu menuIcon={<MenuIcon/>} menuItems={items} lighterHover={true}/> 
        );   
    }

    //------------------------------- RENDER ----------------------------------------------
    
    render() {
                
        const minSize = window.innerHeight*0.2;

        // Sum all fees, credits, and subscription charges
        let charges;
        if (this.state.pivottable)
            charges = this.state.pivottable.fees.total.amount - this.state.pivottable.credits + this.state.pivottable.subscriptions.total.amount;

        const revenueCount = this.state.revenueChartData && this.state.revenueChartData.length > 0 ? this.state.revenueChartData[0].data.length : 0;
        const patronSalesCount = this.state.patronSalesChartData && this.state.patronSalesChartData.length > 0 ? this.state.patronSalesChartData[0].data.length : 0;
    

        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}   

                <AccountViewPopover isOpen={this.state.accountPopoverOpen} account={this.state.accountInPopover} onClose={() => this.setState({accountPopoverOpen: false})}/>

                <TaxYearPopover isOpen={this.state.taxYearPopoverOpen} promptForThreshold={true}
                                okCallback={this._taxYearPopoverOkCallback} 
                                cancelCallback={() => this.setState({taxYearPopoverOpen: false})}/>    

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Autocomplete
                        size='small'
                        style={{marginTop: 20, marginBottom: 30, width: 300}}
                        value={this.state.selectedDateRange}
                        onChange={(event, newValue) => { this.setState({selectedDateRange: newValue}); }}
                        inputValue={this.state.selectedDateRangeValue}
                        onInputChange={(event, newValue) => { this.setState({selectedDateRangeValue: newValue}); this._newSelection(); }}
                        options={this._searchDateRangeOptions}
                        getOptionLabel={(option) => {return option.label}}
                        blurOnSelect
                        disableClearable
                        openText="Select Date Range"
                        renderInput={(params) => <TextField {...params} label="Date Range" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />
                    {Global.isSuperAdmin() ? 
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
                            <Typography variant="body1" style={{marginRight: 20}}>Generate Reports</Typography>
                            {this._generateReportsMenu()}
                        </div>
                        : null
                    }
                </div>

                {this.state.pivottable ? 
                    <Fragment>
                        <Grid container direction="row" spacing={2} style={{padding: 10, marginBottom: 10}}>
                            <Grid item xs={3}>
                                <SummaryWidget label={"Patron Sales (" + this.state.pivottable.patronSales.total.count + ")"}
                                            value={"$ " + Currency.round(this.state.pivottable.patronSales.total.amount)}
                                            tooltip="Total of all sales made to Patrons, less any refunds"
                                            borderColor='green'/>
                            </Grid>
                            <Grid item xs={3}>
                                <SummaryWidget label={"Charges"}
                                            value={"$ " + Currency.round(charges)}
                                            tooltip="Total of all assessed fees, less any credits"
                                            borderColor='black'/>
                            </Grid>
                            <Grid item xs={3}>
                                <SummaryWidget label={"Payments (" + this.state.pivottable.payments.total.count + ")"}
                                            value={"$ " + Currency.round(this.state.pivottable.payments.total.amount)}
                                            tooltip="Totals of all payments made to Access Granted"
                                            borderColor='black'/>
                            </Grid>
                            <Grid item xs={3}>
                                <SummaryWidget label={"Payouts (" + this.state.pivottable.payouts.total.count + ")"}
                                            tooltip="Totals of all payouts made from Access Granted"
                                            value={"$ " + Currency.round(this.state.pivottable.payouts.total.amount)}
                                            borderColor='black'/>
                            </Grid>
                        </Grid>

                        {Global.isSuperAdmin() ?
                            <Grid container direction="row" spacing={2} style={{padding: 10, marginBottom: 10}}>
                                <Grid item xs={4}>
                                    <SummaryWidget label="Accrued Revenue"
                                                value={"$ " + Currency.round(this.state.pivottable.revenue)}
                                                tooltip="Accrued amounts due from client to AGS (not necessarily booked)"
                                                borderColor='green'/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SummaryWidget label="Estimated AGS Costs"
                                                value={"$ " + Currency.round(this.state.pivottable.costs)}
                                                tooltip="Estimated costs on Accrued Revenue"
                                                borderColor='red'/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SummaryWidget label="Estimated Profit"
                                                tooltip="Acrrued Revenue less Estimated Costs"
                                                value={"$ " + Currency.round(this.state.pivottable.profit)}
                                                borderColor='green'/>
                                </Grid>
                            </Grid>
                            : null
                        }
                    </Fragment>
                    : null}

                <MonthlyGraphWidget label="Patron Sales" pivotitemmap={this.state.pivottable ? this.state.pivottable.patronSales.map : null}/>
                <MonthlyGraphWidget label="Fees and Other Charges" pivotitemmap={this.state.pivottable ? this.state.pivottable.fees.map : null}/>
                <MonthlyGraphWidget label="Subscriptions" pivotitemmap={this.state.pivottable ? this.state.pivottable.subscriptions.map : null}/>
                <MonthlyGraphWidget label="Payouts" pivotitemmap={this.state.pivottable ? this.state.pivottable.payouts.map : null}/>
                <MonthlyGraphWidget label="Payments" pivotitemmap={this.state.pivottable ? this.state.pivottable.payments.map : null}/>
                 
                {Global.isSuperAdmin() ?
                    <Fragment>
                        <MonthlyGraphWidget label="Profit" hideKeyDropDown={true} defaultAllColor={ThemeColors.profitGreen} pivotitemmap={this.state.pivottable ? this.state.pivottable.monthlyProfit.map : null}/>

                        <Paper>
                            {this.state.revenueChartData ?
                                <div style={{marginTop: 40, display: this.state.revenueChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Typography variant="button" align='center' style={{fontSize: 16}}>{"Revenue by Account (" + revenueCount + " Accounts)"}</Typography>  
                                    </div>
                                    <Chart
                                        options={this.state.revenueChartOptions}
                                        series={this.state.revenueChartData}
                                        type="bar"
                                        height={Math.max(revenueCount * 20, minSize)}
                                    />
                                </div>
                                : null
                            }

                            {this.state.patronSalesChartData ?
                                <div style={{marginTop: 40, display: this.state.patronSalesChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Typography variant="button" align='center' style={{fontSize: 16}}>{"Patron Sales by Account (" + patronSalesCount + " Accounts)"}</Typography>  
                                    </div>
                                    <Chart
                                        options={this.state.patronSalesChartOptions}
                                        series={this.state.patronSalesChartData}
                                        type="bar"
                                        height={Math.max(patronSalesCount * 20, minSize)}
                                    />
                                </div>
                                : null
                            }
                        </Paper>
                    </Fragment>
                    : null
                }

                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 80, marginBottom: 20}) : <div style={{height: 100}}/>}

            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(AggregatePage));

