import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Container, Typography, Button, Checkbox } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import AGTheme, { ThemeColors } from '../Theme'
import { AccountSnapshot } from '../models/Account'
import { ManageDecimalField, RestComponent, Currency, StyledTooltip } from 'react-frontend-utils'
import { logo } from '../App'

import { AccountSnapshotView } from '../components/AccountSnapshotView';
import PaymentCheckout from '../components/PaymentCheckout';

import { OpenInNewTab } from '../App'

const AppBarButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(Button);

export class Payment extends RestComponent {
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }

    _accountNo; 
    _hmac

    _paymentAmountRef = React.createRef();

    constructor(props) {
        super(props);
    
        this._accountNo = props.accountNo ? decodeURIComponent(props.accountNo) : null;  //Arrives with the query string set to the desired accountNo
        this._hmac = props.hmac ? decodeURIComponent(props.hmac) : null;    //Arrives with an hmac
       
        this.state.accountSnapshot = null;
        this.state.paymentAmount = 0.0;             
        this.state.clientSecret = null;             //The secret that is passed to stripe

        this.state.showStripeCheckout = false;
        this.state.showCheckPayment = false;
        this.state.paymentComplete = false;
        this.state.setupAutomatic = false;
        this.state.consentAutomatic = false;
        this.state.seePaymentOptionsAnyway = false;
        this.state.seePaymentOptionsAnywayButtonPressed = false;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the full application
     */
    componentDidMount() {
        super.componentDidMount();       
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        if (!this._accountNo || !this._hmac) {
            this.showConfirmAlert("No Account", "You did not arrive with a valid link from your Account", 'red');
        }
        else
            this._fetchAccountSnapshot(); 
        
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 600 });  //custom, split between bootstrap and mui
    }

    
    _fetchAccountSnapshot = () => {
        
        this.incrementBusy();
        
        const queryString = "?accountNo=" + this._accountNo + "&hmac=" + encodeURIComponent(this._hmac);
        
        this.secureJSONFetch("/actpayments/accountsnapshot", {},
                             this._accountSnapshotCallback, this._fetchErrorCallback, queryString); 
    }
    
    _accountSnapshotCallback = (response) => {

        if (response) {            
            const snap = new AccountSnapshot(response);
            this.setState({accountSnapshot: snap, paymentAmount: snap.snapshotBalance > 0 ? snap.snapshotBalance : 0.0});            
        }
        this.decrementBusy();
    }
   
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
   
    _doCheckPayment = () => {
        this.setState({showCheckPayment: true});
    }

    _showCheckCoupon = () => {
            
            const couponWindow = window.open("", "Payment Coupon", 'location=off,toolbar=no,scrollbars=yes,resizable=yes,' +
                                      'top=' + window.screenTop + ',left=' + window.screenLeft +',width=900,height=600');
             
            let html = '<html><body>' +
                            '<hr style="border: 1px dashed; margin-bottom: 20px"/>' +
                            '<h2 style="text-align: center">Payment Coupon</h1>' +
                            '<h2 style="text-align: center">' + this.state.accountSnapshot.extendedName + '</h2>' +
                            '<div style="margin-left: 40; margin-right: 40; font-size: 14pt;">' +
                                '<div style="margin-top: 5">Account: <span style="font-weight: bold">' + this._accountNo  + '</span></div>' + 
                                '<div style="margin-top: 5">Make Check Payable to: <span style="font-weight: bold">Access Granted Systems, LLC</span></div>' + 
                                '<div style="margin-top: 5">Mail to:</div>' + 
                                '<div style="margin-left: 20; margin-top: 5; font-size: 18pt; text-transform: uppercase">' +
                                    '<div>Access Granted Systems</div>' + 
                                    '<div>11 Bee Tree Mill Ct</div>' + 
                                    '<div>Parkton, MD 21120</div>' + 
                                '</div>' +
                            '</div>' +
                            '<hr style="border: 1px dashed; margin-top: 20px"/>' +
                            '<p style="font-style: italic; font-size: 12pt">Print and mail the above coupon with your check</p>' + 
                        '</body></html';

                                  
            setTimeout(() => {  //wait for popup to appear
                couponWindow.document.open();  //clear
                couponWindow.document.write(html);
                couponWindow.document.close();
            }, 500);

            setTimeout(() => {  
                couponWindow.print();
            }, 2500);
    }
    
    _doStripePayment = (automatic) => {

        const url = "/actpayments/submit";
        const queryString = "?accountNo=" + this._accountNo + "&hmac=" + encodeURIComponent(this._hmac) + "&amount=" + this.state.paymentAmount + "&auto=" + (automatic ? "true" : "false");

        this.incrementBusy();
        this.secureJSONFetch(url, 
                             {method: "POST"}, 
                             (response) => this._submitComplete(response, automatic),
                             this._submitErrorCallback,
                             queryString);    
        
    }

    _submitErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
        this.setState({clientSecret: null, showCheckPayment: false, setupAutomatic: false, consentAutomatic: false});
    }
    
    _submitComplete = (response, automatic) => {
        if (response)  //response is a client secret if payment required
            this.setState({clientSecret: response, showStripeCheckout: true, showCheckPayment: false, setupAutomatic: automatic, consentAutomatic: false});

        this._submitDate = new Date();
        this.decrementBusy();
    }
  
    _paymentChanged = (fieldName, userValue) => {
        
        if (userValue > 0.0 && userValue > this.state.accountSnapshot.snapshotBalance)
            this.showConfirmAlert("Warning", "The payment amount your entered is larger than your current balance", 'red');
        
        this.setState({paymentAmount: userValue});
    }
  
    //Patron cancels the payment
    _returnFromPayment = () => {
        this.setState({clientSecret: null, showStripeCheckout: false, showCheckPayment: false, setupAutomatic: false, consentAutomatic: false});
    }

    _gotoAccount = () => {
        let url = "//" + window.location.hostname + ":" + window.location.port;
        OpenInNewTab(url);   
    }
    


    render() {

        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = this.state.isMobile ? 8 : 20;

        const paymentDue = this.state.paymentAmount >= 0.01;     // no payment due for fraction of a cent

        let showAnywaysButton = !paymentDue || this.state.accountSnapshot.autoPayOn;    // show if we have no payment, or we're on autopay
    
        if (this.state.seePaymentOptionsAnywayButtonPressed)
            showAnywaysButton = false;    // but never show the button if we've pressed the buttom

        return (
            <ThemeProvider theme={AGTheme}>
                <Fragment>

                    {this.getConfirmAlertComponent()  /*inject an alert component*/}  
                    
                    <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                        <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                            <Toolbar disableGutters={true}>
                                {logo}
                                <Typography variant="h5" style={this.styles.appTitle}>Account Payment</Typography>
                                <StyledTooltip title="Login to the Account Portal">
                                    <AppBarButton color="inherit" style={{marginRight: 30}} onClick={this._gotoAccount}>Go to Account</AppBarButton>
                                </StyledTooltip>
                            </Toolbar>
                        </div>
                    </AppBar>
                    
                    {serverErrorMessage}

                    {this.state.accountSnapshot && !this.state.showStripeCheckout ?
                            <Container maxWidth={false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                                <Typography variant="h4" align='center' style={{marginTop: 10, marginBottom: 10}}>{this.state.accountSnapshot.extendedName}</Typography>

                                {!this.state.paymentComplete ?
                                    <Fragment>
                                        <AccountSnapshotView accountSnapshot={this.state.accountSnapshot}/>
      
                                        {this.state.accountSnapshot.autoPayOn ? 
                                            <Typography variant="h5" align='center' style={{marginTop: 10, marginBottom: 10}}>You are enrolled in Auto-Pay. Your payment will be made automatically.</Typography>
                                            : null
                                        }
                                        {!paymentDue ?
                                            <Typography variant="h5" align='center' style={{marginTop: 10, marginBottom: 10}}>No payment due at this time</Typography>
                                            : null
                                        }                                          
                                                
                                        {showAnywaysButton ?
                                            <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 10}}>
                                                <Button variant='outlined' color='primary' onClick={() => this.setState({seePaymentOptionsAnyway: true, seePaymentOptionsAnywayButtonPressed: true})}>{"Show Payment Options Anyway"}</Button>
                                            </div>
                                            : null
                                        }

                                        {(!this.state.accountSnapshot.autoPayOn && paymentDue) || this.state.seePaymentOptionsAnyway ?

                                            <Fragment>
                                                <ManageDecimalField style={{margin: 'auto', marginTop: 40, maxWidth: 400}}
                                                                                        json="ActualPayment" 
                                                                                        fontSize={32}
                                                                                        label={"Enter Payment Amount (USD)"}
                                                                                        initialValue={this.state.paymentAmount}
                                                                                        autoAccept={true}
                                                                                        ref={this._paymentAmountRef}
                                                                                        hasNever={false}
                                                                                        hasNegative={false}
                                                                                        hideButtons={true}
                                                                                        decimalPlaces={2}
                                                                                        onFieldChange={this._paymentChanged}/>


                                                <div style={{display: 'flex', justifyContent: 'center', gap: 30, marginTop: 50, marginBottom: 30}}>
                                                    <Button variant='contained' color='primary' onClick={this._doCheckPayment}>{"Show Check Payment Instructions"}</Button>
                                                    <Button variant='contained' color='primary' onClick={() => this._doStripePayment(false)}>{this.state.accountSnapshot.autoPayOn ? "Make a One Time Payment" : "Pay Now with Card ➔"}</Button>
                                                    {!this.state.accountSnapshot.autoPayOn ? <Button variant='contained' color='primary' onClick={() => this._doStripePayment(true)}>{"Setup Automatic Payments ➔"}</Button> : null}
                                                </div>
                                            </Fragment>
                                            : null
                                        }

                                        {this.state.accountSnapshot.autoPayOn && this.state.seePaymentOptionsAnyway ?
                                            <Typography variant="body1" align='left' style={{marginLeft: '25%', marginRight: '25%', fontStyle: 'italic', color: 'gray', marginTop: 40}}>
                                                Need to change your automatic payment method? Go to your Account page to Unenroll in Auto-Pay. When your next payment is due, you can re-enroll by paying with a new card. 
                                            </Typography>
                                            : null
                                        }

                                    </Fragment>
                                    : null
                                }
                            </Container>
                            : null
                    }


                    {this.state.showStripeCheckout ? 

                        <Fragment>

                            {this.state.setupAutomatic ? 
                                <Fragment>
                                    <Typography variant="h5" align='center' style={{marginTop: 20, marginBottom: 10}}>Setup Automatic Payments</Typography>
                                    <Typography variant="body1" align='left' style={{marginLeft: '25%', marginRight: '25%', fontStyle: 'italic', color: 'gray', marginBottom: 20}}>
                                        Pay your current amount due and save your card. Your card information will be securely stored with Stripe and Access Granted Systems will
                                        automatically charge your card each month when you have a payment due. You may change your card or cancel automatic payments at any time by 
                                        visiting your Account page or contacting your account representative.
                                    </Typography>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 10}}>
                                        <Checkbox checked={this.state.consentAutomatic} color='primary'
                                                    onChange={(event) => { this.setState({consentAutomatic: event.target.checked})}}/>   
                                        <Typography variant='body1' align='left' style={{fontWeight: 'bold', fontSize: 18}}>I hereby authorize Access Granted Systems to charge my card for future amounts due</Typography>
                                    </div>
                                </Fragment>
                                :
                                <Typography variant="h5" align='center' style={{marginTop: 20, marginBottom: 10}}>Make a One-time Payment</Typography>
                            }

                            <PaymentCheckout returnToAccount={this._returnFromPayment} disabled={this.state.setupAutomatic && !this.state.consentAutomatic}
                                        paymentDescription={"Amount to Charge Today: " + Currency.round(this.state.paymentAmount) + " USD"}
                                        paymentComplete={() => this.setState({showStripeCheckout: false, paymentComplete: true}) /* Payment complete, will trigger success message*/}
                                        clientSecret={this.state.clientSecret}
                                        submitDate={this._submitDate}
                                        email={this.state.accountSnapshot.billingEmail}/>
                        </Fragment>
                        : null
                    }

                    {this.state.paymentComplete ?

                        <div style={{textAlign: 'center', marginTop: 40}}>
                            <Typography variant="h4" style={{color: 'green', fontWeight: 'bold', marginBottom: 40}}>
                                <span style={{marginRight: 20}}>✓</span>Payment Complete
                            </Typography>  
                            <Typography variant="body1" align='center' style={{marginTop: 10, marginBottom: 10}}>Thank you for your payment. You can see this payment posted by logging into your account.</Typography>
                        </div>
                        : null
                    }

                    {this.state.showCheckPayment ?
                        <Fragment>
                            <div style={{marginTop: 40, marginLeft: window.innerWidth/4}}>
                                <Typography variant="body1" style={{marginTop: 10}}>Make check payable to: <span style={{fontWeight: 'bold'}}>Access Granted Systems, LLC</span> and mail to:</Typography>
                                    <div style={{marginTop: 10, marginLeft: 20}}>
                                        <Typography variant="h5" style={{marginTop: 2, textTransform: 'uppercase'}}>Access Granted Systems</Typography>
                                        <Typography variant="h5" style={{marginTop: 2, textTransform: 'uppercase'}}>11 Bee Tree Mill Ct</Typography>
                                        <Typography variant="h5" style={{marginTop: 2, textTransform: 'uppercase'}}>Parkton, MD 21120</Typography>
                                    </div>
                                <Typography variant="body1" style={{marginTop: 10}}>
                                    <span style={{marginRight: 20, fontStyle: 'italic'}}>Please write your account number on your check:</span>
                                    <span style={{fontWeight: 'bold'}}>{this._accountNo}</span>
                                </Typography>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', gap: 30, marginTop: 50, marginBottom: 30}}>
                                <Button variant='outlined' color='primary' onClick={this._showCheckCoupon}>Print Payment Coupon</Button>
                            </div>
                        </Fragment>
                        : null
                    }


                </Fragment>
            </ThemeProvider>    
        );
    }
  
}

export default withCookies(withRouter(Payment));



