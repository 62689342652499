import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Typography, Button, IconButton, Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LoopIcon from '@material-ui/icons/Loop';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline'
import PieChartIcon from '@material-ui/icons/PieChart';
import IsoIcon from '@material-ui/icons/Iso';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { Global } from '../models/Global'
import AGTheme, { ThemeColors } from '../Theme'
import { RestComponent, HomepageContext, PopupMenu, StyledTooltip } from 'react-frontend-utils'
import { User, Permissions } from 'react-frontend-utils'
import { AccountSelectPopover } from '../components/AccountSelectPopover';
import { WelcomePage } from '../pages/WelcomePage'
import TransactionsListPage from '../pages/TransactionsListPage'
import StatementsListPage from '../pages/StatementsListPage'
import AccountPage from '../pages/AccountPage'
import SnapshotPage from '../pages/SnapshotPage'
import FeePage from '../pages/FeePage'
import ExpiredPage from '../pages/ExpiredPage'
import { logo } from '../App'
import SubscriptionPage from '../pages/SubscriptionPage';
import JournalListPage from '../pages/JournalListPage';
import AggregatePage from '../pages/AggregatePage';
import StatisticsPage from '../pages/StatisticsPage';
import CashFlowPage from '../pages/CashFlowPage';


//This is the main page for the Web Portal. It contains the AppBar and MainMenu and handles login/logout.
//Below the AppBar, the HomePage selects one Page out of the available Pages to display, based on what
//is selected by the user. The WecomePage is the initial page shown before login.


//These are the available Pages that can be selected from the MainMenu that will be displayed below the AppBar
export const Pages = {
    WELCOME: "WELCOME",
    EXPIRED: "EXPIRED",
    TRANSACTIONS: "TRANSACTIONS",
    STATEMENTS: "STATEMENTS",
    ACCOUNT: "ACCOUNT",
    FEES: "FEES",
    SUBSCRIPTIONS: "SUBSCRIPTIONS",
    REPORTS: "REPORTS",
    SNAPSHOTS: "SNAPSHOTS",
    STATISTICS: "STATISTICS",
    CASHFLOW: "CASH FLOW",
    JOURNAL: "JOURNAL",
    
    //Returns true if the type is one of the MainMenuSwitch types, false otherwise
    isOneOf: (type) => {
        switch (type) {
            case Pages.WELCOME:
            case Pages.EXPIRED:
            case Pages.TRANSACTIONS:
            case Pages.STATEMENTS:
            case Pages.ACCOUNT:
            case Pages.FEES:
            case Pages.SUBSCRIPTIONS:
            case Pages.REPORTS:
            case Pages.SNAPSHOTS:
            case Pages.STATISTICS:
            case Pages.JOURNAL:
            case Pages.CASHFLOW:
                return true;
            default:
                return false;
        }
    },

    isAgsOnlyPage: (type) => {
        switch (type) {
            case Pages.FEES:
            case Pages.SUBSCRIPTIONS:
            case Pages.STATISTICS:
            case Pages.JOURNAL:
            case Pages.CASHFLOW:
                return true;
            default:
                return false;
        }
    }
};
Object.freeze(Pages);



const AppBarIconButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(IconButton);

const AppBarButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(Button);


const poolPassIcon = (<PeopleIcon fontSize="small"/>);
const passesIcon = (<AccountBoxIcon fontSize="small"/>);
const applicationsIcon = (<AssignmentIcon fontSize="small"/>);
const helpIcon = (<HelpOutlineIcon fontSize="small"/>);


const gotoPoolPassPortal = () => {
    window.location.href ="https://portal.pool-pass.com";
};

const gotoApplicationsPortal = () => {
    window.location.href = "https://applications.accessgrantedsystems.net";        
};

const gotoPassesPortal = () => {
    window.location.href = "https://passes.accessgrantedsystems.net";
};  

const gotoHelp= () => {
    window.location.href = "https://support.accessgrantedsystems.com/portal";        
};

class Home extends RestComponent {
  
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }
 
    _accountChangedEvent;


    constructor(props) {
        super(props);
        this.state.isAuthenticated = false;       //true if the current user has been authenticated via OAuth
        this.state.serverError = null;
        this.state.isMobile = false;
        this.state.currentUser = null;
        this.state.selectedPage = Pages.WELCOME;  //default
        this.state.accountPopoverOpen = false;

        this._accountChangedEvent = new Event('accountChangeEvent');
    }


    /**
     * When the Home page loads, see if the user is currently authenticated
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        window.addEventListener("accountRequestChangeEvent", this._accountChangeRequest);
        this._fetchCurrentUser();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
        window.removeEventListener("accountRequestChangeEvent", this._accountChangeRequest);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 600 });  //custom, split between bootstrap and mui
    }


    _fetchCurrentUser = () => {
        this.secureJSONFetch("/ppcs/currentUser", {}, this._checkUserCallback); 
    }


    /**
     * Callback to be executed from fetching the current user
     * @param {Object} response null if there is no current authenticated user, or a JSON object describing the user
     */
    _checkUserCallback = (response) => {
        if (response === null) {
            this.setState(({isAuthenticated: false, currentUser: null}));  //user is not authenticated
            Global.user = null;
            console.log("No user is currently logged in");
     
        } 
        else {
            const user = new User(response);  //create the global user
            this.setState({currentUser: user, isAuthenticated: true});
            
            console.log("User: " + user.name()  + " (" + user.id + "/" + user.role + ") logged in, available databases are: " + user.databases.toString());
            Global.user = user;

            let lastDatabase = Global.getLastDatabase();
            if (!lastDatabase || !user.databases.includes(lastDatabase)) {
                if (Global.user.isSuperAdmin(true))
                    lastDatabase = null;  //all
                else {
                    lastDatabase = Global.user.databases[0];  //use first
                    console.log("No last database set, last database unavailable, or using All Databases as non superadmin, now using " + lastDatabase);
                }
                Global.setLastDatabase(lastDatabase);
            }

            this._changeAccount(lastDatabase, true);            
        }
    }

   
    /**
     * User Action by pressing the LOGIN button
     * Attempt to login by accessing a protected resource - browser is redirected to OAuth login page
     */
    _login = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
          port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/ppcs/private"; 
    }


        
    /**
     * User action by pressing the LOGOUT button
     * Logout - response indicates a link to follow after logout
     */
    _logout = () => {
        
        this.secureJSONFetch("/ppcs/logout", {method: "POST"}, (result) => {
            window.location.href = result.logoutUrl + "?id_token_hint=" + result.idToken + "&post_logout_redirect_uri=" + window.location.origin;
            this.setState({currentUser: null, isAuthenticated: false});
            Global.user = null;
            Global.accountName = null;
            Global.accountID = null;
        },
        (error) => {
            this.setState({serverError: error.toString()});
        });  
    }
    
    sessionExpired = () => {
        this.setState({selectedPage: Pages.EXPIRED, currentUser: null, isAuthenticated: false});
    }

    _gotoAccount = (next) => {
        const currentDatabase = Global.getLastDatabase();
        let nextDatabase;
        if (!currentDatabase)
            nextDatabase = Global.user.databases[0]; //use first
        else {
            for (let i=0; i<Global.user.databases.length; i++) {
                if (Global.user.databases[i] === currentDatabase) {
                    let nextDatabaseIndex = next ? i+1 : i-1;
                    if (nextDatabaseIndex >= Global.user.databases.length)      // rollover
                        nextDatabaseIndex = 0;
                    if (nextDatabaseIndex < 0)      // rollover
                        nextDatabaseIndex = Global.user.databases.length-1;

                    nextDatabase = Global.user.databases[nextDatabaseIndex];
                }
            }
        }

        this._changeAccount(nextDatabase, false);
    }


    //Goto Transactions Page
    _gotoTransactionsPage = () => {
        this.setState({selectedPage: Pages.TRANSACTIONS});    
    }
    
    _gotoStatementsPage = () => {
        this.setState({selectedPage: Pages.STATEMENTS});
    }

    _gotoAccountPage = () => {
        this.setState({selectedPage: Pages.ACCOUNT});            
    }

    _gotoFeesPage = () => {
        this.setState({selectedPage: Pages.FEES});            
    }

    _gotoSubscriptionsPage = () => {
        this.setState({selectedPage: Pages.SUBSCRIPTIONS});
    }
    
    _gotoReportsPage = () => {
        this.setState({selectedPage: Pages.REPORTS});
    }

    _gotoSnapshotsPage = () => {
        this.setState({selectedPage: Pages.SNAPSHOTS});
    }

    _gotoStatisticsPage = () => {
        this.setState({selectedPage: Pages.STATISTICS});
    }

    _gotoCashFlowPage = () => {
        this.setState({selectedPage: Pages.CASHFLOW});
    }

    _gotoJournalPage = () => {
        this.setState({selectedPage: Pages.JOURNAL});
    }


    /**
     * Callback from the MainMenu component when a user selects an item from the menu 
     * @param {Pages item} selectedItem one of the Pages items
     */
    mainMenuCallback = (selectedItem) => {
        
        if (Pages.isOneOf(selectedItem)) {
            this.setState({selectedPage: selectedItem}); 
            console.log("Switching page to " + selectedItem);
        }
        else {
            console.log("Invalid Page passed to mainMenuCallback");
        }
             
    }
    
    _mainMenu = () => {
  
    
        const items = (() => {

            let menuItems = [];

            //Goto the Applications page without an ID
            menuItems.push({label: "Transactions", 
                            icon: <MonetizationOnOutlinedIcon fontSize="small"/>, 
                            isSelected: (this.state.selectedPage === Pages.TRANSACTIONS), 
                            selectCallback: this._gotoTransactionsPage
                            });
                       
            menuItems.push({label: "Statements",
                               icon: <DescriptionIcon fontSize="small"/>,
                               isSelected: (this.state.selectedPage === Pages.STATEMENTS), 
                               selectCallback: this._gotoStatementsPage
                               });

            menuItems.push({label: "Account",
                            icon: <AccountBalanceIcon fontSize="small"/>,
                            isSelected: (this.state.selectedPage === Pages.ACCOUNT), 
                            isDisabled: !Global.accountID,
                            selectCallback: this._gotoAccountPage
                            });

            menuItems.push({label: "Reports",
                            icon: <BarChartIcon fontSize="small"/>,
                            isSelected: (this.state.selectedPage === Pages.REPORTS), 
                            selectCallback: this._gotoReportsPage
                            });

            menuItems.push({label: "Balances",
                            icon: <TimelineIcon fontSize="small"/>,
                            isSelected: (this.state.selectedPage === Pages.SNAPSHOTS), 
                            selectCallback: this._gotoSnapshotsPage
                            });

            menuItems.push(null);

            if (Global.isAGSAccountAdmin()) {    //pages for only admin
                               
                menuItems.push({label: "Cash Flow",
                                icon: <IsoIcon fontSize="small"/>,
                                isSelected: (this.state.selectedPage === Pages.CASHFLOW), 
                                selectCallback: this._gotoCashFlowPage
                                });


                menuItems.push({label: "Statistics",
                                icon: <PieChartIcon fontSize="small"/>,
                                isSelected: (this.state.selectedPage === Pages.STATISTICS), 
                                selectCallback: this._gotoStatisticsPage
                                });

                menuItems.push({label: "Fees",
                                icon: <LocalAtmIcon fontSize="small"/>,
                                isSelected: (this.state.selectedPage === Pages.FEES), 
                                selectCallback: this._gotoFeesPage
                                });

                menuItems.push({label: "Subscriptions",
                                icon: <LoopIcon fontSize="small"/>,
                                isSelected: (this.state.selectedPage === Pages.SUBSCRIPTIONS), 
                                selectCallback: this._gotoSubscriptionsPage
                                });

                menuItems.push({label: "Journal",
                                icon: <DescriptionOutlinedIcon fontSize="small"/>,
                                isSelected: (this.state.selectedPage === Pages.JOURNAL), 
                                selectCallback: this._gotoJournalPage
                                });

                menuItems.push(null);
            }

            menuItems.push({label: "Manage Memberships", 
                                icon: poolPassIcon, 
                                isSelected: false, 
                                selectCallback: gotoPoolPassPortal
                            });

            menuItems.push({label: "Manage Applications", 
                            icon: applicationsIcon, 
                            isSelected: false, 
                            selectCallback: gotoApplicationsPortal
                            });
                            
            menuItems.push({label: "Manage Passes", 
                            icon: passesIcon, 
                            isSelected: false, 
                            selectCallback: gotoPassesPortal
                            });

            menuItems.push({label: "Help", 
                            icon: helpIcon, 
                            isSelected: false, 
                            selectCallback: gotoHelp}
                            );

            return menuItems;
        })();

        return (
            <PopupMenu menuIcon={<MenuIcon/>} menuItems={items} lighterHover={true}/> 
        );   
    }


    _showHealth = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/monitoring"; 
    }
    
    _showLogs = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/actuator/logfile"; 
    }


    // Events posted by other pages can request the account be changed
    _accountChangeRequest = (event) => {
        if (event.type === 'accountRequestChangeEvent' && event.community) {
            console.log("Another page requested Account change to: ", event.community);
            this._changeAccount(event.community);
        }
    }

    
    _changeAccount = (community, jumpToTransactionsPage = false) => {
        this.setState({accountPopoverOpen: false});

        if (!community) {
            Global.accountID = null;
            Global.accountName = "All Accounts";
            Global.setLastDatabase(null);
            console.log("Selected All Accounts");
            window.dispatchEvent(this._accountChangedEvent);   //let everyone know the account has changed!
            this.forceUpdate();
            if (jumpToTransactionsPage)
                this._gotoTransactionsPage();   //for login
            return;
        }
        else
            console.log("New community is " + community);

        Global.setLastDatabase(community);

        //Fetch the Account
        this.secureJSONFetch("/act/account?group=" + community, {}, (response) => this._fetchAccountCallback(response, jumpToTransactionsPage), this._fetchErrorCallback); 
    }

    _fetchAccountCallback = (response, jumpToTransactionsPage) => {
        if (response) {
            Global.accountID = response.accountNo;
            Global.accountName = response.extendedName;
            dispatchEvent(this._accountChangedEvent);   //let everyone know the account has changed!
            if (jumpToTransactionsPage)
                this._gotoTransactionsPage();   //for login
            this.forceUpdate();
        }
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    _oktaSync = () => {
        this.secureJSONFetch("/act/account/oktasync", {method: "POST"}, null, this._fetchErrorCallback); 
    }

    _switchClientView = () => {
        Global.isClientView = !Global.isClientView;

        // We don't want to show our current AGS only page to the client, so switch to Transactions
        if (Global.isClientView && Pages.isAgsOnlyPage(this.state.selectedPage))
            this._gotoTransactionsPage();
        this.forceUpdate();
    }

    render() {

        //Set the login button based on the state of the authentication
        const loginLogoutButton = this.state.isAuthenticated ?
            <AppBarButton color="inherit" onClick={() => { this._logout(); }}>LOGOUT</AppBarButton>
            :
            <AppBarButton color="inherit" onClick={() => { this._login(); }}>LOGIN</AppBarButton>;


        //Only shows the main menu when authenticated 
        const showMainMenu = this.state.isAuthenticated;

        let limitMaxWidth = false;
        
       
        //Selects the current page to view
        const viewingPage = (() => {
                       
            switch (this.state.selectedPage) {

                case Pages.WELCOME:   
                    return <WelcomePage onLogin={this._login}/>;
              
                case Pages.EXPIRED:   
                    return <ExpiredPage/>;
                    
                case Pages.TRANSACTIONS:
                    return <TransactionsListPage/>;
    
                case Pages.STATEMENTS:
                    return <StatementsListPage/>;
             
                case Pages.ACCOUNT:
                    return <AccountPage nextAccountRequest={() => this._gotoAccount(true)} prevAccountRequest={() => this._gotoAccount(false)}/>;

                case Pages.FEES:
                    return <FeePage/>;

                case Pages.SUBSCRIPTIONS:
                    return <SubscriptionPage/>;

                case Pages.REPORTS:
                    return <AggregatePage/>;

                case Pages.STATISTICS:
                    return <StatisticsPage/>;

                case Pages.CASHFLOW:
                    return <CashFlowPage/>;

                case Pages.SNAPSHOTS:
                    return <SnapshotPage/>;

                case Pages.JOURNAL:
                    return <JournalListPage/>;

                default:
                    return <div>Page Not Found</div>;

            }
        })();
        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = this.state.isMobile ? 8 : 20;
        
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        {Global.user ? 
                            <AccountSelectPopover isOpen={this.state.accountPopoverOpen} okCallback={(community) => this._changeAccount(community)} 
                                                  cancelCallback={() => this.setState({accountPopoverOpen: false})}/>   
                                : null
                        }
                        
                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" style={this.styles.appTitle}>Account Portal</Typography>

                                     {Global.user && Global.user.hasPermissionTo(Permissions.ADMINISTER_ACCOUNTS) ?
                                        <StyledTooltip title={Global.isClientView ? "Click to switch to AGS Superadmin View" : "Click to switch to Client's View"}>
                                            <AppBarButton color="inherit" style={{marginRight: 30}} onClick={this._switchClientView}>{Global.isClientView ? "Client View" : "AGS View"}</AppBarButton>
                                        </StyledTooltip>
                                        : null
                                    }        

                                     {Global.user ? 
                                        <StyledTooltip title="Switch Account">
                                            <AppBarButton color="inherit" style={{marginRight: 30}} onClick={() => this.setState({accountPopoverOpen: true})}>⇋ ACCOUNT</AppBarButton>
                                        </StyledTooltip>
                                        : null
                                     }

                                     {Global.isSuperAdmin(true) ? 
                                        <div>
                                            <AppBarIconButton edge="end" onClick={this._showLogs} style={{marginRight: 5}} >
                                                <DescriptionOutlinedIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                            <AppBarIconButton edge="end" onClick={this._showHealth} style={{marginRight: 5}} >
                                                <FavoriteBorderIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                            <AppBarButton color="inherit" style={{marginRight: 30}} onClick={this._oktaSync}>OK SYNC</AppBarButton>
                                        </div>
                                        : null
                                     }
                                     
                                      
                                     {loginLogoutButton}
                                     <div style={{padding: 5}}/>
                                     {showMainMenu ? this._mainMenu() : null}


                                </Toolbar>
                                <div style={{display: "flex", flexDirection: 'column'}}>
                                    <Typography variant="subtitle1" style={{textAlign: 'left', fontSize: this.state.isMobile ? 13 : 16}}>{this.state.currentUser ? ("User: " + this.state.currentUser.name()) : null}</Typography>
                                    <Typography variant="subtitle1" style={{textAlign: 'left', fontSize: this.state.isMobile ? 13 : 16}}>{this.state.currentUser ? ("Account: " + Global.accountName) : null}</Typography>
                                </div>
                            </div>
                        </AppBar>


                        {serverErrorMessage}
                        
                        <Container maxWidth={limitMaxWidth ? 'lg' : false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                            {viewingPage} 
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(Home));


