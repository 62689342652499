import React from 'react';
import { Container, Popover, Button, TextField, Box, Typography } from '@material-ui/core'

import { ThemeColors } from '../Theme'
import { ManageDateField, DateUtils, AlertingComponent } from 'react-frontend-utils'


export class ManualStatementCreatePopover extends AlertingComponent {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

    _maxYear = (new Date()).getFullYear();  //this year

 
    _okCallback;
    _cancelCallback;

    _dateFieldRef = React.createRef();

    state = {
        commentEntry: "",
        closeDate: "Now"
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    _closeDateChanged = (json, val) => {
        
        const today = DateUtils.startOfToday();
        const inputDate = val ? DateUtils.parseJsonDate(val, false) : null;

        if (inputDate && inputDate >= today)  
            this._dateFieldRef.current.change("Now", true); 
        else
            this._dateFieldRef.current.accept();
     
        this.setState({closeDate: val});
    }
    
    _commentFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({commentEntry: val});
    }
    
    
    _okButtonPressed = () => {
        const dateStr = this.state.closeDate === "Now" ? null : this.state.closeDate;
        this._okCallback(dateStr, this.state.commentEntry);
        this.setState({closeDate: "Now", commentEntry: ""});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({closeDate: "Now", commentEntry: ""});  //clear text for next time
    }
    
    _dateParseError = (label, error) => {}

    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 700)
            maxWidth = 700;
                
        const initialVal = this.state.closeDate ? this.state.closeDate : DateUtils.jsonDateString(DateUtils.startOfToday());

        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    {this.props.account ?
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Trigger Statement Creation: Account " + this.props.account}</Typography>  
                        :
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Trigger Statement Creation: All Accounts"}</Typography>  
                    }
                    <div style={{marginTop: 60}}/>

                    <TextField label="Note" autoFocus multiline={true} rows={7} value={this.state.commentEntry} onChange={this._commentFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />

                    <div style={{marginTop: 40, marginBottom: 5, width: '90%'}}>
                        <ManageDateField hasNever={true} neverText="Now" json="closeDateField"
                                    onFieldChange={this._closeDateChanged} 
                                    onParseError={this._dateParseError}
                                    label="Close Date" 
                                    ref={this._dateFieldRef}
                                    initialValue={initialVal}
                                    
                                    changedBackgroundColor='white'
                                    calendarColor={ThemeColors.calendarColor}
                                    isReadOnly={false} editable={true}/>
                    </div>

                    <Typography variant='body2' style={{fontStyle: 'italic', marginBottom: 30}}>{"Statement will close " + (this.state.closeDate === "Now" ? "Immediately" : ("on " + this.state.closeDate + " at the end of the day (23:59:59 UTC)") )}</Typography>   

                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}