import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import Chart from "react-apexcharts";

import { Tooltip, Grid, Typography, Paper, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';

import { Snapshot } from '../models/Snapshot';
import { Global } from '../models/Global'
import { ThemeColors } from '../Theme'
import { Account } from '../models/Account';

import { AccountViewPopover } from '../components/AccountViewPopover';
import { RestComponent, DateUtils, Currency, SummaryWidget, DateRangeSelector } from 'react-frontend-utils'




export class SnapshotPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        }
    };
    
    _standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    };
   

    _snapshotChartOptions = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.balanceBlue, '#FF0000'], 
        stroke: {
            curve: 'stepline',
            width: 2
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {year: 'yyyy', month: "yyyy MMM", day: "MMM-dd"}
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return "$" + Currency.round(value, 0); }
            }
        },
        tooltip: {
            x: {
                format: "yyyy MMM-dd"
            },
            y: {
                formatter: (value) => { return "$" + Currency.round(value); } 
            }
        }
    };


    _clickChartElement = (event, chartContext, config )=> {  //when clicked, switch to the Account
        const selectedAccount = config.w.config.xaxis.categories[config.dataPointIndex];
        this._showAccount(selectedAccount);
    }

    _balChartOptions = {
      
        chart: {...this._standardChartOptions,   
                    events: { dataPointSelection: this._clickChartElement }   
                },
        colors: [ThemeColors.balanceBlue],    
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: "90%"
            }
        },
        xaxis: {
            categories: [],
            position: 'top',
            labels: {
                formatter: (value) => { return "$" + Currency.round(value, 0); }
            }
        },
        tooltip: {
            y: {
                formatter: (value) => { return "$" + Currency.round(value); } 
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value) => { return "$" + Currency.round(value, 0); }
        },
    };
    _overdueChartOptions = {
      
        chart: {...this._standardChartOptions,   
            events: { dataPointSelection: this._clickChartElement }   
        }, 
        colors: ['#FF0000'],  
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: "90%"
            }
        },
        xaxis: {
            categories: [],
            position: 'top',
            labels: {
                formatter: (value) => { return "$" + Currency.round(value, 0); }
            }
        },
        tooltip: {
            y: {
                formatter: (value) => { return "$" + Currency.round(value); } 
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value) => { return "$" + Currency.round(value, 0); }
        },
    };

       
    static currentYear = (new Date()).getFullYear();

    //options for the search time range pulldown
    _searchTimeRangeOptions = [
       {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
       {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
       {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
       {label: "Last 180 days", startTime: DateUtils.startOfToday().valueOf() - (180 * DateUtils.MS_PER_DAY)},
       {label: "Last 365 days", startTime: DateUtils.startOfToday().valueOf() - (365 * DateUtils.MS_PER_DAY)},
       {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
    ];
   
    _initialTimeRange = this._searchTimeRangeOptions[3];      //30 days


    constructor(props) {
        super(props);
        this.state.accountPopoverOpen = false;

        this.state.searchFromDate = DateUtils.jsonDateString(new Date(this._initialTimeRange.startTime));           //JsonDateString or null

        this.state.searchToDate = null;                                         //JsonDateString or null
        this.state.snapshotChartData = null;

        this.state.balanceChartData = null;
        this.state.balanceChartOptions = null;
        this.state.overdueChartData = null;
        this.state.overdueChartOptions = null;

        this.state.accountCount = 0;
        this.state.overdueAccountsCount = 0;
        this.state.overdueSum = 0;
        this.state.futurePayouts = 0;
        this.state.futureDue = 0;
        this.state.snapshotDate = null;
    }
    
    
    
    
    /**
     * When the page loads, immediately fetch the list of Aggregates
     */
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("accountChangeEvent", this._accountHasChanged);
        
        this._fetchSnapshots();  
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("accountChangeEvent", this._accountHasChanged);
    }

    //callback when account has changed
    _accountHasChanged = () => {
        this.setState({snapshotChartData: null});
        this._fetchSnapshots();  
    }
    
    
    _fetchSnapshots = () => {
        
        let queryString = "";   //empty, initially
        if (Global.accountID)
            queryString += "accountNo=" + Global.accountID + "&"; 
                        
        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
        }
            
        if (queryString)
            queryString = "?" + queryString.slice(0, -1);   //add the ? and remove the trailing &

        this.incrementBusy();
        this.secureJSONFetch("/act/snapshot", {}, this._fetchSnapshotsCallback, this._fetchErrorCallback, queryString);    

        if (Global.isSuperAdmin()) {
            this.incrementBusy();
            this.secureJSONFetch("/act/snapshot/latest", {}, this._fetchLatestSnapshotsCallback, this._fetchErrorCallback);   
        } 
        else {
            this.setState({balanceChartData: null, overdueChartData: null, balanceChartOptions: null, overdueChartOptions: null});    // clear
        }
    }
    
    //Callback for fetching Snapshots - response is a list of Snapshots over time
    _fetchSnapshotsCallback = (response) => {
        if (response) {            
            const list = response.map(sn => new Snapshot(sn));
            list.sort((a, b) => a.compareByDate(b));
      
            const balanceSeries = []; 
            const overdueSeries = []; 
                    
            for (let snapshot of list) {
                balanceSeries.push([snapshot.date, snapshot.balance]);
                overdueSeries.push([snapshot.date, snapshot.pastDue]);                 
            }    
                
                
            const snapshotChartData = [
                {
                  name: "Balance",
                  data: balanceSeries
                },
                {
                  name: "Overdue",
                  data: overdueSeries
                }
            ];
            this.setState({snapshotChartData: snapshotChartData});

        }            
        this.decrementBusy();
    }


    //Callback for fetching Latest Snapshots - response is a list of the latest Snapshot for each account
    _fetchLatestSnapshotsCallback = (response) => {
        if (response) {            
            const list = response.map(sn => new Snapshot(sn));
      
            // Create the Account Balance chart data
            const balanceOpts = this._balChartOptions;
            balanceOpts.xaxis.categories.length = 0;    //clear
            const balanceSeries = []; 
            let futurePayouts = 0;
            let futureDue = 0;
            let mostRecent = null;

            list.sort((a, b) => a.compareByBalance(b));     //sort by balance, ascending             
            for (let snapshot of list) {

                if (!mostRecent || mostRecent < snapshot.date)
                    mostRecent = snapshot.date;

                if (snapshot.balance === 0)                 //only show nonzero accounts
                    continue;
                balanceOpts.xaxis.categories.push(snapshot.accountNo.toString())
                balanceSeries.push(snapshot.balance);
                if (snapshot.balance < 0)
                    futurePayouts += snapshot.balance;
                else
                    futureDue += snapshot.balance;
            }
            
            const balanceChartData = [
                {
                  name: "Balance",
                  data: balanceSeries
                }
            ];

            // Create the Overdue Balance chart data
            const overdueSeries = [];
            const overdueOpts = this._overdueChartOptions;
            overdueOpts.xaxis.categories.length = 0;    //clear
            let overdueAccountsCount = 0;
            let overdueSum = 0;

            list.sort((a, b) => a.compareByOverdue(b));
            list.reverse();   //sort by overdue, ascending             

            for (let snapshot of list) {
                if (snapshot.pastDue > 0) { // only include nonzero past due accounts
                    overdueOpts.xaxis.categories.push(snapshot.accountNo.toString())
                    overdueSeries.push(snapshot.pastDue);
                    overdueSum += snapshot.pastDue;
                    overdueAccountsCount++;
                }
            }

            const overdueChartData = [
                {
                  name: "Past Due",
                  data: overdueSeries
                }
            ];

            this.setState({balanceChartData: balanceChartData, balanceChartOptions: balanceOpts, 
                           overdueChartData: overdueChartData, overdueChartOptions: overdueOpts,
                           accountCount: list.length, overdueAccountsCount: overdueAccountsCount,
                           overdueSum: overdueSum, futurePayouts: -futurePayouts, futureDue: futureDue,
                           snapshotDate: mostRecent});

        }            
        this.decrementBusy();
    }
    
    _showAccount = (accountNo) => {
        this.secureJSONFetch("/act/account/" + accountNo, {}, this._fetchAccountCallback, this._fetchErrorCallback); 
    }

    _fetchAccountCallback = (response) => {
        if (response) {            
            const act = new Account(response);
            this.setState({accountInPopover: act, accountPopoverOpen: true});
        }            
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

  
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }

    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }


    //------------------------------- RENDER ----------------------------------------------
    
    render() {
                
        const minSize = window.innerHeight*0.2;
        const pixelsForBalanceChart = this.state.accountCount * 20;
        const balanceChartHeight = pixelsForBalanceChart > minSize ? pixelsForBalanceChart : minSize;

        const pixelsForOverdueChart = this.state.overdueAccountsCount * 20;
        const overdueChartHeight = pixelsForOverdueChart > minSize ? pixelsForOverdueChart : minSize;

        const balanceCount = this.state.balanceChartData && this.state.balanceChartData.length > 0 ? this.state.balanceChartData[0].data.length : 0;
        const overdueCount = this.state.overdueChartData && this.state.overdueChartData.length > 0 ? this.state.overdueChartData[0].data.length : 0;


        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}   
                     
                <AccountViewPopover isOpen={this.state.accountPopoverOpen} account={this.state.accountInPopover} onClose={() => this.setState({accountPopoverOpen: false})}/>

                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Snapshot Search</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item md={4} sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                minYear={2020}
                                                ref={this._dateRangeRef}
                                                initialTimeRange={this._initialTimeRange.label}
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>

                    </Grid>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                        <Tooltip title="Search and Display Daily Snapshots">
                            <Button fullWidth onClick={this._fetchSnapshots} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Tooltip>

                    </div>

                </Paper>

                {this.state.snapshotChartData ? 
                    <Fragment>
                        <div style={{display: this.state.snapshotChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant="button" align='center' style={{fontSize: 16}}>Daily Balance and Overdue Amounts</Typography>  
                            </div>
                            <Chart
                                options={this._snapshotChartOptions}
                                series={this.state.snapshotChartData}
                                type="line"
                                height={window.innerHeight*0.40}
                            />
                        </div>
                         
                    </Fragment>
                    : null
                }

                {this.state.balanceChartData && this.state.overdueChartData ? 
                    <Fragment>

                        <Grid container direction="row" spacing={2} style={{padding: 10, marginTop: 80, justifyContent: 'center'}}>
                            <Grid item xs={3}>
                                <SummaryWidget label={"Total Future Payouts"}
                                            value={"$ " + Currency.round(this.state.futurePayouts)}
                                            tooltip="Total across accounts of amount of payouts to be made in the future"
                                            borderColor='black'/>
                            </Grid>
                            <Grid item xs={3}>
                                <SummaryWidget label={"Total Outstanding Due"}
                                            value={"$ " + Currency.round(this.state.futureDue)}
                                            tooltip="Total across accounts of outstanding amounts due"
                                            borderColor='green'/>
                            </Grid>
                            <Grid item xs={3}>
                                <SummaryWidget label={"Total Past Due"}
                                            value={"$ " + Currency.round(this.state.overdueSum)}
                                            tooltip="Total across accounts of overdue amounts"
                                            borderColor='red'/>
                            </Grid>
                        </Grid>

                        {this.state.snapshotDate ?
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography align='center' style={{marginBottom: 20, fontSize: 14, fontStyle: 'italic', color: 'grey'}}>{"Data as of Local Time: " + DateUtils.timeFormat(this.state.snapshotDate, DateUtils.DateFormatType.US)}</Typography>  
                            </div>
                            : null
                        }

                        <div style={{marginTop: 20, display: this.state.balanceChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant="button" align='center' style={{fontSize: 16}}>{"Account Balances (" + balanceCount + " Accounts)"}</Typography>  
                            </div>
                            <Chart
                                options={this.state.balanceChartOptions}
                                series={this.state.balanceChartData}
                                type="bar"
                                height={balanceChartHeight}
                            />
                        </div>

                        <div style={{marginTop: 20, display: this.state.overdueChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant="button" align='center' style={{fontSize: 16}}>{"Past Due Amounts (" + overdueCount + " Accounts)"}</Typography>  
                            </div>
                            {this.state.overdueAccountsCount > 0 ?
                                <Chart
                                    options={this.state.overdueChartOptions}
                                    series={this.state.overdueChartData}
                                    type="bar"
                                    height={overdueChartHeight}
                                /> :
                                <Typography variant="body2" align='center' style={{fontSize: 16, marginBottom: 20}}>No Accounts Past Due</Typography>  
                            }
                        </div>
                         
                    </Fragment>
                    : null
                }

                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 80, marginBottom: 20}) : <div style={{height: 100}}/>}

            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(SnapshotPage));

