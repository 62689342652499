import React from 'react';
import { Grid } from '@material-ui/core'
import { ThemeColors } from '../Theme';

import { Currency, SummaryWidget } from 'react-frontend-utils' 

export function AccountSnapshotView(props) {

    if (!props.accountSnapshot)
        return null;

    return (

        <Grid container direction="row" spacing={2} style={{padding: 10, marginBottom: 10}}>
            <Grid item xs={4}>
                <SummaryWidget label="Last Statement Balance"
                            value={"$ " + Currency.round(props.accountSnapshot.prevStatementBalance)}
                            borderColor='black'/>
            </Grid>
            <Grid item xs={4}>
                <SummaryWidget label="Current Account Balance"
                            value={"$ " + Currency.round(props.accountSnapshot.snapshotBalance)}
                            borderColor={ThemeColors.appBarBackground}/>
            </Grid>
            <Grid item xs={4}>
                <SummaryWidget label="Past Due"
                            value={"$ " + Currency.round(props.accountSnapshot.pastDue)}
                            borderColor={props.accountSnapshot.pastDue > 0 ? 'red' : 'black'}/>
            </Grid>
        </Grid>
    );
}