import React, { Fragment } from 'react';
import { Container, Popover, Button, Box, Typography } from '@material-ui/core'
import { ThemeColors } from '../Theme';

import { Currency } from 'react-frontend-utils' 

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 10
    },
    button: {
        marginLeft: 10,
        marginRight: 10
    },
    table: {
        borderCollapse: 'collapse',
        borderSpacing: '0, 5px',
        width: '100%',
        marginBottom: 10,
        marginTop: 10
    },
    tableHeader: {
        borderBottom: '1px solid ' + ThemeColors.appBarBackground,
        textAlign: 'left',
        paddingBottom: 5,
        paddingRight: 10,
        marginBottom: 8,
        fontSize: 13,
        color: ThemeColors.darkGray,
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    tableDataWithBorder: {
        textAlign: 'left',
        fontSize: 13,
        paddingRight: 10,
        borderBottom: '1px solid lightGray'
    },
    tableData: {
        textAlign: 'left',
        fontSize: 14,
        paddingRight: 10,
        verticalAlign: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    }
};


function renderSubscriberRow(subscriber, key) {
        
    //Render the header
    if (!subscriber) {
        return (
            <tr key={key} style={styles.tableStyle}>
                <th style={styles.tableHeader}>Subscription</th>
                <th style={{...styles.tableHeader, textAlign: 'right'}}>Amount</th>
            </tr>
        );
    }
                    
    return (
        <Fragment key={key}>
            <tr style={styles.tableDataWithBorder}>
                <td style={styles.tableData}>{subscriber.subscription.description}</td>  
                <td style={{...styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(subscriber.subscription.amount)}</td>
            </tr>
        </Fragment>
    );
}

function renderFeeRow(fee, key) {
        
    //Render the header
    if (!fee) {
        return (
            <tr key={key} style={styles.tableStyle}>
                <th style={styles.tableHeader}>Fee Type</th>
                <th style={{...styles.tableHeader, textAlign: 'right'}}>Amount Multiplier</th>
                <th style={{...styles.tableHeader, textAlign: 'right'}}>Fixed Amount</th>
            </tr>
        );
    }
                        
    return (
        <Fragment key={key}>
            <tr style={styles.tableDataWithBorder}>
                <td style={styles.tableData}>{fee.type.label}</td>       
                <td style={{...styles.tableData, textAlign: 'right'}}>{Currency.round(fee.multipler, 3)}</td>
                <td style={{...styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(fee.fixed)}</td>
            </tr>
        </Fragment>
    );
}


function requestAccountChange(groupName) {
    const requestEvent = new Event('accountRequestChangeEvent');
    requestEvent.community = groupName;
    console.log("Request switch to Account: ", groupName);
    window.dispatchEvent(requestEvent);        //send the request to the Home page
}

export function AccountViewPopover(props) {

    let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
    if (maxWidth > 700)
        maxWidth = 700;

    if (!props.account)
        return null;

    const refundLimitRemain = Currency.round(props.account.currentRefundLimit);

    return (
        <Popover open={props.isOpen} 
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
            
            <Box style={{margin: 20, width: maxWidth}}>
                <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid ' + ThemeColors.appBarBackground}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Account " + props.account.accountNo}</Typography>  
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{props.account.extendedName}</Typography>  
                </div>
                    
                <div style={{marginTop: 20}}/>

                <Typography variant="body2" >{"Database Name: " + props.account.groupName}</Typography>                  
                <Typography variant="body2" >{"Community Address: " + props.account.address}</Typography>                  
                <div style={{marginTop: 10}}/>
                <Typography variant='body2' style={{color: refundLimitRemain <= 0 ? 'red' : 'black'}}>{"Remaining Limit on Refunds this Month: $" + refundLimitRemain}</Typography> 

                <div style={{marginTop: 20}}/>

                <div style={styles.roundedContainer}>
                    <table style={styles.table}>
                        <thead>
                            {renderSubscriberRow(null, 0) /*render header*/ }
                        </thead>
                        <tbody>
                            {props.account.subscribers.map((subscriber, index) => renderSubscriberRow(subscriber, index+1))}  
                        </tbody>
                    </table>
                </div>

                <div style={{marginTop: 20}}/>

                <div style={styles.roundedContainer}>
                    <table style={styles.table}>
                        <thead>
                            {renderFeeRow(null, 0) /*render header*/ }
                        </thead>
                        <tbody>
                            {props.account.fees.map((fee, index) => renderFeeRow(fee, index+1))}  
                        </tbody>
                    </table>
                </div>


                <Container style={styles.container}>    
                    <Button onClick={() => {requestAccountChange(props.account.groupName); props.onClose();}} color="primary" variant="outlined" style={styles.button}>Switch to this Account</Button>
                    <Button onClick={props.onClose} color="primary" variant="outlined" style={styles.button}>Close</Button>
                </Container>
            </Box>
        </Popover>
    );


}