import React from 'react';
import { Container, Popover, Button, TextField, Box, Typography } from '@material-ui/core'

import { ManageDateField, DateUtils, AlertingComponent, Currency } from 'react-frontend-utils';
import { Global } from '../models/Global'

export class CheckProcessingPopover extends AlertingComponent {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    };

 
    _okCallback;
    _cancelCallback;
    

    _calendarColor = 'gray';
    _dateFormat = DateUtils.DateFormatType.US;
    
    _onDateChange;
    _onParseError;

    _maxYear = (new Date()).getFullYear();  //this year
    _minYear = this._maxYear - 1;
    
    _checkDateFieldRef = React.createRef();

    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
        this.state.checkNo = null;
        this.state.checkDate = null;
        this.state.amount = null;
        this.state.notes = null;
    }
   
    _numberFieldChanged = (event) => {
        
        let val = parseFloat(event.target.value);
        if (val < 0)
            val = 0;            
        this.setState({amount: val});
    }
    
    _commentFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({commentEntry: val});
    }

    _notesFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({notes: val});
    }

    _checkNoFieldChanged = (event) => {
        let val = parseInt(event.target.value);
        if (val < 0)
            val = 0;            
        this.setState({checkNo: val});
    }
    
    
    _okButtonPressed = () => {

        this.showConfirmAlert("Confirm Check Post", 
                                Global.accountName + " will be credited for payment by check in the amount of $" + Currency.round(this.state.amount, 2) + ".",
                                'black',
                                "Cancel", 
                                this._confirmOk,
                                "Confirm",
                                'green');

    }

    _confirmOk = () => {

        if (this.state.amount > 2000) {
            this.showConfirmAlert("Confirm Large Check", 
                                    "This check in the amount $" + Currency.round(this.state.amount, 2) + " is atypically large. Are you sure you want to post it?",
                                    'red',
                                    "Cancel", 
                                    this._confirmDoPost,
                                    "Confirm",
                                    'green');   
        }
        else
            this._confirmDoPost();
    }

    _confirmDoPost = () => {
        this._okCallback(this.state.amount, this.state.checkDate, this.state.checkNo, this.state.commentEntry);
        this.setState({amount: null, checkDate: null, checkNo: null, commentEntry: null});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({amount: null, checkDate: null, checkNo: null, commentEntry: null});  //clear text for next time
    }
    
    //If the end date is set to null ("Today" button) or a date after today, adjust it to today
    _checkDateFieldChanged = (json, val) => {
       
        const today = DateUtils.startOfToday();
        const inputDate = val ? DateUtils.parseJsonDate(val, false) : null;

        if (inputDate && inputDate > today)  
            this._checkDateFieldRef.current.change(DateUtils.dateFormat(today, this._dateFormat), true); 
        else           
            this._checkDateFieldRef.current.accept();  
            
        const date = this._checkDateFieldRef.current.get();
        this.setState({checkDate: date});

    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.7;
        if (maxWidth > 900)
            maxWidth = 900;
                
        let val = this.state.amount === null ? 0 : this.state.amount;

        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                {this.getConfirmAlertComponent()}   

                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>{"Process Check Payment: Account " + Global.accountID}</Typography>  
                    <Typography variant="body1" style={{fontWeight: 'bold'}}>{Global.accountName}</Typography>  

                    <Typography variant="body1" align='center' style={{fontStyle: 'italic', color: 'grey', marginBottom: 30, marginTop: 20}}>{"Verify this check is for the correct Account. Enter the check amount, date, and check number."}</Typography>  

                    <Container style={{...this.styles.container, justifyContent: 'center', marginBottom: 20}}>    
                        <TextField label="Check Amount (USD)" type="number" value={val} inputProps={{style: {fontSize: 22}}}
                                    onChange={this._numberFieldChanged} variant="outlined" fullWidth={false} InputLabelProps={{ shrink: true}} />
                    </Container>
                    <Container style={{...this.styles.container, justifyContent: 'center', marginBottom: 20, gap: 40}}>    

                        <ManageDateField hasNever={false} json="checkDateField" maxYear={this._maxYear} minYear={this._minYear}
                                    onFieldChange={this._checkDateFieldChanged} 
                                    onParseError={this._dateParseError}
                                    label="Check Date" 
                                    ref={this._checkDateFieldRef}
                                    style={{maxWidth: 300}}
                                    changedBackgroundColor='white'
                                    dateFormat={this._dateFormat}
                                    calendarColor={this._calendarColor}
                                    isReadOnly={false} editable={true}/>

                        <TextField label="Check Number" size="small" type="number" value={this.state.checkNo} onChange={this._checkNoFieldChanged} variant="outlined" fullWidth={false} InputLabelProps={{ shrink: true}} />
                    </Container>


                    <TextField label="Note" autoFocus multiline={true} rows={5} value={this.state.commentEntry} onChange={this._commentFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
        
        
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!val || this.state.checkNo == null} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}