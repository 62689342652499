import { DateUtils } from 'react-frontend-utils'
import { ThemeColors } from '../Theme'


export class Journal {

    static Type = {

        TRANSACTION:    {enumName: "TRANSACTION", bit: 0, label: "Transaction"},
        STATEMENT:      {enumName: "STATEMENT", bit: 1, label: "Statement"},
        SUBSCRIPTION:   {enumName: "SUBSCRIPTION", bit: 2, label: "Subscription"}, 
        FEE:            {enumName: "FEE", bit: 3, label: "Fee"},
        ACCOUNT:        {enumName: "ACCOUNT", bit: 4, label: "Account"}, 
        PAYOUT:         {enumName: "PAYOUT", bit: 5, label: "Payout"},
        USER:           {enumName: "USER", bit: 6, label: "User"},
        API:            {enumName: "API", bit: 7, label: "API"},
        PAYMENT:        {enumName: "PAYMENT", bit: 8, label: "Payment"}
    }
    
    static Severity = {

        NOTICE:     {enumName: "NOTICE", bit: 0, label: "Notice", backgroundColor: ThemeColors.noticeGreen},  
        WARN:       {enumName: "WARN", bit: 1, label: "Warn", backgroundColor: ThemeColors.warnYellow},
        ERROR:      {enumName: "ERROR", bit: 2, label: "Error", backgroundColor: ThemeColors.errorRed}
    }
  
    
      
    date;               //Converted to local
    type;
    severity;
    accountNo;
    entry;

    constructor(json) {
        if (json) {        
            this.date = DateUtils.parseJsonDate(json.date, true); //convert from UTC            
            this.type = Journal.typeFromString(json.type);
            this.severity = Journal.severityFromString(json.severity);
            this.accountNo = json.accountNo;
            this.entry = json.entry;           
        }
    }

    
    
    static typeFromString(str) {
        for (let index in Journal.Type) {
            const type = Journal.Type[index];
            if (type.enumName === str) {
                return type;
            }
        }  
        return null;
    }

    static severityFromString(str) {
        for (let index in Journal.Severity) {
            const sev = Journal.Severity[index];

            if (sev.enumName === str) {
                return sev;
            }
        }  
        return null;
    }
    
}





