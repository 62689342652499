import React, { Fragment } from 'react';
import { Container, Popover, Button, Box, Typography } from '@material-ui/core'
import { ThemeColors } from '../Theme';

import { Currency } from 'react-frontend-utils' 

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 10
    },
    button: {
        marginLeft: 10,
        marginRight: 10
    },
    table: {
        borderCollapse: 'collapse',
        borderSpacing: '0, 5px',
        width: '100%',
        marginBottom: 10,
        marginTop: 10
    },
    tableHeader: {
        borderBottom: '2px solid ' + ThemeColors.appBarBackground,
        textAlign: 'left',
        paddingBottom: 5,
        paddingRight: 10,
        marginBottom: 8,
        fontSize: 13,
        color: ThemeColors.darkGray,
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    tableDataWithBorder: {
        textAlign: 'left',
        fontSize: 13,
        paddingRight: 10,
        borderBottom: '1px solid lightGray'
    },
    tableData: {
        textAlign: 'left',
        fontSize: 14,
        paddingRight: 10,
        verticalAlign: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    }
};

export function DefaultFeeDisplayPopover(props) {

    let maxWidth = window.innerWidth * 0.7;  //.7 times the window, up to 900
    if (maxWidth > 900)
        maxWidth = 900;

    if (!props.defaultFees)
        return null;

    return (
        <Popover open={props.isOpen} 
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
            
            <Box style={{margin: 20, width: maxWidth}}>
                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Default Fees</Typography>  
                <div style={{marginTop: 20}}/>

                <table style={styles.table}>
                    <thead>
                        <tr style={styles.tableStyle}>
                            <th style={styles.tableHeader}>Fee Type</th>
                            <th style={{...styles.tableHeader, textAlign: 'right'}}>Amount Multiplier</th>
                            <th style={{...styles.tableHeader, textAlign: 'right'}}>Fixed Amount</th>
                            <th style={{...styles.tableHeader, textAlign: 'right'}}>AGS Cost</th>                            
                        </tr>
                    </thead>
                    <tbody>

                        {props.defaultFees.map((fee, index) => 
                            <Fragment key={index}>
                                <tr style={styles.tableDataWithBorder}>
                                    <td style={styles.tableData}>{fee.type.label}</td>    
                                    <td style={{...styles.tableData, textAlign: 'right'}}>{Currency.round(fee.multipler, 3)}</td>
                                    <td style={{...styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(fee.fixed)}</td>
                                    <td style={{...styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(fee.agsCost)}</td>                                    
                                </tr>
                            </Fragment>    
                        )}
                    </tbody>
                </table>

                <Container style={styles.container}>    
                    <Button onClick={props.onClose} color="primary" variant="outlined" style={styles.button}>Close</Button>
                </Container>
            </Box>
        </Popover>
    );


}