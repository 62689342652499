import React, { Fragment } from 'react';

import { Typography, Grid } from '@material-ui/core'
import { Popover, Button, Box } from '@material-ui/core'

import { ThemeColors } from '../Theme';
import { Currency, SummaryWidget, DateUtils } from 'react-frontend-utils'


const styles =  {
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        marginBottom: 10,
    },
    tableHeader: {
        borderBottom: '2px solid ' + ThemeColors.appBarBackground,
        textAlign: 'left',
        paddingBottom: 5,
        paddingRight: 10,
        marginBottom: 8,
        fontSize: 14,
        color: ThemeColors.darkGray,
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    tableDataWithBorder: {
        textAlign: 'left',
        fontSize: 12,
        color: ThemeColors.darkGray,
        paddingRight: 10,
        borderBottom: '1px solid lightGray'
    },
    tableData: {
        textAlign: 'left',
        fontSize: 14,
        paddingRight: 10,
        verticalAlign: 'center'
    }
};


export function StatementView(props) {
    
    const st = props.statement;
    if (!st)
        return null;

    const dateAndTime = DateUtils.timeFormat(st.closeDate);

    let balanceForward = st.balanceForward;
    let balLabel;
    let balColor;
    let explanation;
    let hasPayNowButton = false;;

    switch (st.type) {
        case "INVOICE":
            balLabel = "Amount Due";
            balColor = 'black';
            if (st.autoPayEnabled)
                explanation = "You are enrolled in Auto-Pay. Your payment will be made automatically.";
            else {
                explanation = "You have a payment due";
                hasPayNowButton = true;
            }
            break;
        case "PAYOUT_NOTICE":
        case "STATEMENT":
            balLabel = "Account Balance";
            explanation = "No payment due at this time";
            break;
        default:
            balLabel = "Account Balance";
            explanation = "Unknown Statement Type";
            break;
    }

    return (
        <Popover open={st} 
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/4}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}} >

            <Box style={{margin: 20, width: window.innerWidth * 0.9, minHeight: window.innerHeight * 0.6, border: '3px solid black', borderRadius: 5, padding: 15}}>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" style={{fontWeight: 'bold', marginBottom: 10}}>{"Account " + st.accountNo}</Typography>   
                    <Typography variant="h5" style={{fontWeight: 'bold', marginBottom: 10}}>{"Statement " + dateAndTime}</Typography>   
                    <Button onClick={props.onClose} color="primary" variant="outlined" style={{marginBottom: 50}}>Close</Button>
                </div>
                    
                <Grid container direction="row" spacing={2} style={{padding: 10, marginBottom: 10}}>
                    <Grid item xs={3}>
                        <SummaryWidget label="Previous Statement Balance"
                                    value={"$ " + Currency.round(st.prevBalance)}
                                    borderColor='black'/>
                    </Grid>
                    <Grid item xs={3}>
                        <SummaryWidget label="Past Due"
                                    value={"$ " + Currency.round(st.pastDue)}
                                    borderColor={st.pastDue > 0 ? 'red' : 'black'}/>
                    </Grid>
                    <Grid item xs={3}>
                        <SummaryWidget label="New Activity"
                                    value={"$ " + Currency.round(st.transactionSum)}
                                    borderColor={ThemeColors.appBarBackground}/>
                    </Grid>
                    <Grid item xs={3}>
                        <SummaryWidget label={balLabel}
                                    value={"$ " + Currency.round(balanceForward)}
                                    borderColor={balColor}/>
                    </Grid>
                </Grid>


                <div style={{display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: 30}}>
                    <Typography variant="h5" style={{display: 'flex', justifyContent: 'right', marginRight: 20}}>{explanation}</Typography>   
                    {hasPayNowButton ? <Button variant='contained' style={{color: 'green', fontSize: '12pt'}} onClick={() => props.onPayButton(st.accountNo)}>Make a Payment</Button> : null}
                </div>

                <table style={styles.table}>
                    <thead>
                        <tr key={0} style={styles.tableStyle}>
                            <th style={{...styles.tableHeader, width: 180}}>Date</th>
                            <th style={{...styles.tableHeader, width: 300}}>Transaction ID</th>
                            <th style={{...styles.tableHeader, width: 250}}>Type</th>
                            <th style={styles.tableHeader}>Detail</th>
                            <th style={{...styles.tableHeader, textAlign: 'right'}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>

                        {st.coveredTransactions.map((t, index) => 
                            <Fragment key={index+1}>
                                <tr style={styles.tableStyle}>
                                    <td style={{...styles.tableData, fontWeight: 'bold'}}>{DateUtils.timeFormat(t.date)}</td>
                                    <td style={{...styles.tableData, width: 300}}>{t.id}</td>
                                    <td style={{...styles.tableData, fontWeight: 'bold'}}>{t.type.label}</td>                                                    
                                    <td style={styles.tableData}>{t.detail.detailClass + (t.detail.subType ? (": " + t.detail.subType) : "")}</td>
                                    <td style={{...styles.tableData, fontWeight: 'bold', textAlign: 'right'}}>{"$ " + Currency.round(t.amount)}</td>
                                </tr>
                                <tr style={styles.tableStyle}>
                                    <td colSpan={5} align='center' style={styles.tableDataWithBorder}>{t.renderDetailForStatement()}</td>
                                </tr>
                            </Fragment>        
                        )}
                    </tbody>
                </table>

            </Box>
        </Popover>
    );
}


