import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import Chart from "react-apexcharts";

import { Tooltip, Grid, Typography, Paper, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';

import { CashFlow } from '../models/CashFlow';
import { Revenue } from '../models/Revenue';
import { ThemeColors } from '../Theme'

import { RestComponent, DateUtils, Currency, DateRangeSelector } from 'react-frontend-utils'




export class CashFlowPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        }
    };
    
    _standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    };
   

    _cashFlowChartOptions = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.profitGreen, ThemeColors.payoutRed, ThemeColors.balanceBlue, ThemeColors.webhookCyan], 
        stroke: {
            curve: 'straight',
            width: 2
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {year: 'yyyy', month: "yyyy MMM", day: "MMM-dd"}
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return "$" + Currency.round(value, 0); }
            }
        },
        tooltip: {
            x: {
                format: "yyyy MMM-dd"
            },
            y: {
                formatter: (value) => { return "$" + Currency.round(value); } 
            }
        }
    };

    _revenueChartOptions = {
      
        chart: {...this._standardChartOptions, stacked: true},
        colors: [ThemeColors.profitGreen, ThemeColors.balanceBlue], 
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%"
            }
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {year: 'yyyy', month: "yyyy MMM", day: "MMM-dd"}
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return "$" + Currency.round(value, 0); }
            }
        },
        tooltip: {
            x: {
                format: "yyyy MMM-dd"
            },
            y: {
                formatter: (value) => { return "$" + Currency.round(value); } 
            }
        }
    };


    static currentYear = (new Date()).getFullYear();

    //options for the search time range pulldown
    _searchTimeRangeOptions = [
       {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
       {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
       {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
       {label: "Last 180 days", startTime: DateUtils.startOfToday().valueOf() - (180 * DateUtils.MS_PER_DAY)},
       {label: "Last 365 days", startTime: DateUtils.startOfToday().valueOf() - (365 * DateUtils.MS_PER_DAY)},
       {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
    ];
   
    _initialTimeRange = this._searchTimeRangeOptions[3];      //30 days


    constructor(props) {
        super(props);

        this.state.searchFromDate = DateUtils.jsonDateString(new Date(this._initialTimeRange.startTime));           //JsonDateString or null

        this.state.searchToDate = null;                                         //JsonDateString or null
        this.state.cashFlowChartData = null;
        this.state.revenueChartData = null;
    }
    
    
    
    
    /**
     * When the page loads, immediately fetch the list of Cash Flow
     */
    componentDidMount() {
        super.componentDidMount();        
        this._fetchCashFlow();  
        this._fetchRevenue();
    }


    _getQueryString = () => {
        let queryString = "";   //empty, initially
                         
        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
        }
            
        if (queryString)
            queryString = "?" + queryString.slice(0, -1);   //add the ? and remove the trailing &

        return queryString;
    }
    
    _fetchCashFlow = () => {
        this.incrementBusy();
        this.secureJSONFetch("/act/cashflow", {}, this._fetchCashFlowCallback, this._fetchErrorCallback, this._getQueryString());    
    }
    
    //Callback for fetching CashFlow - response is a list of CashFlow over time
    _fetchCashFlowCallback = (response) => {
        if (response) {            
            const list = response.map(cf => new CashFlow(cf));
            list.sort((a, b) => a.compareByDate(b));
      
            const collectableSeries = []; 
            const futurePayoutSeries = []; 
            const stripeAvailableSeries = []; 
            const cashBalanceSeries = []; 
                    
            for (let cashflow of list) {
                collectableSeries.push([cashflow.date, cashflow.collectable]);
                futurePayoutSeries.push([cashflow.date, cashflow.futurePayouts]);                 
                stripeAvailableSeries.push([cashflow.date, cashflow.stripeAvailable]);                 
                cashBalanceSeries.push([cashflow.date, cashflow.stripeAvailable + cashflow.stripePending]);                 
            }    
                
                
            const cashFlowChartData = [
                {
                    name: "Collectable",
                    data: collectableSeries
                },
                {
                    name: "Future Payout",
                    data: futurePayoutSeries
                },
                {
                    name: "Cash Available",
                    data: stripeAvailableSeries
                },
                {
                    name: "Cash Balance",
                    data: cashBalanceSeries
                }
            ];
            this.setState({cashFlowChartData: cashFlowChartData});

        }            
        this.decrementBusy();
    }

    _fetchRevenue= () => {
        this.incrementBusy();
        this.secureJSONFetch("/act/revenue", {}, this._fetchRevenueCallback, this._fetchErrorCallback, this._getQueryString());       
    }
    
    //Callback for fetching Revenue - response is a list of Revenue over time
    _fetchRevenueCallback = (response) => {
        if (response) {            
            const list = response.map(r => new Revenue(r));
            list.sort((a, b) => a.compareByDate(b));
      
            const profitSeries = []; 
            const costSeries = []; 
           
            for (let revenue of list) {
                profitSeries.push([revenue.date, revenue.profit]);
                costSeries.push([revenue.date, revenue.cost]);
            }    
                
                
            const revenueChartData = [
                {
                    name: "Profit",
                    data: profitSeries
                },
                {
                    name: "Cost",
                    data: costSeries
                },
            ];
            this.setState({revenueChartData: revenueChartData});

        }            
        this.decrementBusy();
    }


    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

  
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }

    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    

    //------------------------------- RENDER ----------------------------------------------
    
    render() {
                
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}   
                     
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Cash Flow Search</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item md={4} sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                minYear={2020}
                                                ref={this._dateRangeRef}
                                                initialTimeRange={this._initialTimeRange.label}
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>

                    </Grid>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                        <Tooltip title="Search and Display Cash Flows and Revenue">
                            <Button fullWidth onClick={this._fetchCashFlow} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Tooltip>

                    </div>

                </Paper>

                {this.state.cashFlowChartData ? 
                    <Fragment>
                        <div style={{display: this.state.cashFlowChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant="button" align='center' style={{fontSize: 16}}>Daily Cash Flow</Typography>  
                            </div>
                            <Chart
                                options={this._cashFlowChartOptions}
                                series={this.state.cashFlowChartData}
                                type="line"
                                height={window.innerHeight*0.40}
                            />
                        </div>
                         
                    </Fragment>
                    : null
                }

                {this.state.revenueChartData ? 
                    <Fragment>
                        <div style={{marginTop: 50, display: this.state.revenueChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant="button" align='center' style={{fontSize: 16}}>Daily Revenue/Profit</Typography>  
                            </div>
                            <Chart
                                options={this._revenueChartOptions}
                                series={this.state.revenueChartData}
                                type="bar"
                                height={window.innerHeight*0.40}
                            />
                        </div>
                         
                    </Fragment>
                    : null
                }
                
                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 80, marginBottom: 20}) : <div style={{height: 100}}/>}

            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(CashFlowPage));

