import React from 'react';
import { TextField } from '@material-ui/core'
import { AlertingComponent } from 'react-frontend-utils';


// AddressFields are a set of fields that represent an address, used to set various items in an Account.  A user must have
// "canEditAccount" permission to edit these fields.
export class AddressFields extends AlertingComponent {

    constructor(props) {
        super(props);
        this.state.name = "";
        this.state.address1 = "";
        this.state.address2 = "";
        this.state.city =  "";
        this.state.state =  "";
        this.state.zip =  "";
        this.state.country =  "";
    }

    clear() {
        this.setState({name: "", address1: "", address2: "", city: "", state: "", zip: "", country: ""});
    }


    setPiecesFromString = (str) => {

        if (!str)
            str = "";
        const pieces = str.split(';');
        this.state.name = pieces[0] ? pieces[0] : "";
        this.state.address1 = pieces[1]? pieces[1] : "";
        this.state.address2 = pieces[2]? pieces[2] : "";
        this.state.city = pieces[3]? pieces[3] : "";
        this.state.state = pieces[4]? pieces[4] : "";
        this.state.zip = pieces[5]? pieces[5] : "";
        this.state.country = pieces[6] ? pieces[6] : "";
    }

    toString = () => {

        return this.state.name + ";" +
                this.state.address1 + ";" +
                this.state.address2 + ";" +
                this.state.city + ";" +
                this.state.state + ";" +
                this.state.zip + ";" +
                this.state.country;
    }

    //if any of the required fields fields are empty, return true
    isMissingEntries = () => {
        return !this.state.name || !this.state.address1 || !this.state.city || !this.state.state || !this.state.zip;
    }

    //if all fields are empty
    isEmpty = () => {
        return !this.state.name && !this.state.address1 && !this.state.address2 && !this.state.city && !this.state.state && !this.state.zip && !this.state.country;
    }

    _renderField = (label, piece, required = false) => {
        return <TextField label={label + (required ? " *" : "")} variant='outlined' fullWidth={true} value={this.state[piece]}
                                            inputProps={{readOnly: this.props.readOnly}}
                                            InputLabelProps={{ shrink: true }} size="small" 
                                            onChange={(event) => { 
                                                const newVal = event.target.value;
                                                if (newVal.includes(";")) {
                                                    this.showConfirmAlert("Error", label + " cannot contain a semicolon", 'red');
                                                    return;
                                                }
                                                this.setState({[piece]: newVal})
                                            }}
                />
    }

    render() {

        return <div>
                    <div style={{display: 'flex', justifyContent: 'left', gap: 5, alignItems: 'center', marginBottom: 10}}>
                        {this._renderField(this.props.nameLabel, "name", true)}
                        {this._renderField("Address Line 1", "address1", true)}
                        {this._renderField("Address Line 2", "address2")}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'left', gap: 5, alignItems: 'center'}}>
                        {this._renderField("City", "city", true)}
                        {this._renderField("State", "state", true)}
                        {this._renderField("Postal Code", "zip", true)}
                        {this._renderField("Country", "country")}
                    </div>
                </div>
    }


}

