import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Typography} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { PayoutAct } from '../models/Account';



export class NewConnectedAccountRequestPopover extends Component {


    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

 
    _okCallback;
    _cancelCallback;
    

    state = {
        dba: "",
        country: PayoutAct.Country.US,
        countryValue: PayoutAct.Country.US,
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    _numberFieldChanged = (event) => {
        
        let val = parseFloat(event.target.value);
        if (val < 0)
            val = 0;            
        this.setState({amount: val});
    }
    
    _commentFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({commentEntry: val});
    }
    
    _dbaFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({dba: val});
    }
    
    _okButtonPressed = () => {
        this._okCallback(this.state.dba, this.state.country.code);
        this.setState({country: PayoutAct.Country.US, dba: ""});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({country: PayoutAct.Country.US, dba: ""});  //clear text for next time
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 700)
            maxWidth = 700;
                
        let countries = Object.keys(PayoutAct.Country).map(key => PayoutAct.Country[key]);

        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Create New Connected Account</Typography>  
                    <div style={{marginTop: 20}}/>

                    <TextField style={{marginBottom: 20}} label="Doing Business As" value={this.state.dba} onChange={this._dbaFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />

                    <Autocomplete
                        size='small'
                        value={this.state.country}
                        getOptionLabel={(country) => country.label }
                        onChange={(event, newValue) => { this.setState({country: newValue}); }}
                        inputValue={this.state.typeValue}
                        onInputChange={(event, newValue) => { this.setState({countryValue: newValue}); }}
                        options={countries}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Country" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    /> 


                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!this.state.dba} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}