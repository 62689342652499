import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { IconButton, Tooltip, Grid, Checkbox, Typography, Paper, Button, Collapse, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';

import { ThemeColors } from '../Theme'
import { Transaction } from '../models/Transaction'
import { Account } from '../models/Account';
import { PagedTransactionList } from '../models/PagedTransactionList'
import { Global } from '../models/Global'

import { RestComponent } from 'react-frontend-utils' 

import { DateUtils, ManageBitwiseCheckboxes, DateRangeSelector } from 'react-frontend-utils'
import { Currency } from 'react-frontend-utils'
import { ManualTransactionPopover } from '../components/ManualTransactionPopover';
import { AccountViewPopover } from '../components/AccountViewPopover';
import { TransactionViewPopover } from '../components/TransactionViewPopover';



export class TransactionsListPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        id: {
            marginLeft: 'auto',
            maxWidth: 300,
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            marginRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 150,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
       
    };
    
    

    
     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 2 days", startTime: DateUtils.startOfToday().valueOf() - (2 * DateUtils.MS_PER_DAY)},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "All", startTime: null}
    ];
    
    _fetchNewestFirst = true;  //initially, fetch newest first

    constructor(props) {
        super(props);
    
                
        this.state.transactionsPage = null;         //last fetched page - a PagedTransactionList
               
        this.state.searchFromDate = null;           //JsonDateString or null
        this.state.searchToDate = null;            //JsonDateString or null
        this.state.searchIncludesTest = false;      //true to include test transactions
        
        this.state.sinceLastStatement = false;       //search since last statement
        this.state.typeCheckboxes = 0;             //Bitwise selected types to search for, from checkboxes only!
        this.state.revealSet = new Set();         //Set of tranactions to reveal detail
        this.state.textSearch = null;
        this.state.idQuickSearch = "";

        this.state.manualTransactionPopoverOpen = false;
        this.state.accountPopoverOpen = false;
        this.state.accountInPopover = null;

        this.state.transactionViewPopoverOpen = false;
        this.state.transactionViewPopoverTransaction = null;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of transactions
     */
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("accountChangeEvent", this._accountHasChanged);
        
        //fetch all transactions, first page
        this._fetchTransactions();  
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("accountChangeEvent", this._accountHasChanged);
    }

    //callback when account has changed
    _accountHasChanged = () => {
        this._fetchTransactions();  
    }
    
    
    //Fetch a specific page, using search fields if set.
    //If forDownload is set, download as csv, otherwise normal fetch to display
    _fetchTransactions = (page = 0, forDownload = false) => {
        

        let queryString = "?";
        if (Global.accountID)
            queryString += "accountNo=" + Global.accountID + "&"; 
                        
        if (this.state.typeCheckboxes)  //state checkboxes are set
            queryString += "types=" + this.state.typeCheckboxes + "&";
        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
        }
        if (this.state.sinceLastStatement)
            queryString += "sinceLastStatement=true&";

        if (!this.state.searchIncludesTest)
            queryString += "live=true&";     //live only

        if (this.state.textSearch)
            queryString += "text=" + encodeURIComponent(this.state.textSearch) + "&";

        queryString += "page=" + page + "&sortNewestFirst=" + this._fetchNewestFirst;
        

        if (forDownload) {
            const filename = "Transaction Export on " + DateUtils.downloadTimeString() + ".csv";
            this.secureFileDownload("/act/transaction/download" + queryString, filename, null, this._fetchErrorCallback); 
        }
        else {
            this.incrementBusy();
            this.secureJSONFetch("/act/transaction", {}, this._fetchTransactionsCallback, this._fetchErrorCallback, queryString); 
        }
                
    }
    
    //Callback for fetching Transactions - response is json of PagedTransactionList
    _fetchTransactionsCallback = (response) => {
        if (response) {            
            const tl = new PagedTransactionList(response);
            this.setState({transactionsPage: tl});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }



    _findByIDkeyDown = (event) => {
        if (event.key === 'Enter') { 
            event.target.blur(); 
            this._transactionReferencePopup(this.state.idQuickSearch);
        }
        else if (event.key === 'Escape') {  //set to initial text
            this.setState({idQuickSearch: ""});
            event.target.blur(); 
        }
    }
  
    _manualTransaction = (type, amount, note, correctedTransactionId, payoutElectronically) => {
        
        if (!Global.accountID)
            return;

        this.setState({manualTransactionPopoverOpen: false});

        const mt = {transactionType: type, note: note, amount: amount, correctedTransactionId: correctedTransactionId, payoutElectronically: payoutElectronically};
    
        this.incrementBusy();
        this.secureJSONFetch("/act/transaction/" + Global.accountID + "/manual" , {method: "POST", body: JSON.stringify(mt)}, 
        this._postTransactionCallback, this._fetchErrorCallback); 
    }


    _postTransactionCallback = (response) => {
        this.decrementBusy();
        this._refresh();
    }

 
    //If the transaction is in the reveal list, remove it, if it is not, add it (toggle the transaction iew)
    _revealTransactionDetail = (t) => {
        const toReveal = this.state.revealSet;
        
        if (toReveal.has(t))
            toReveal.delete(t);
        else
            toReveal.add(t);
        
        this.setState({revealSet: toReveal});
    }
    

 
    //fetch the previous page
    _prevPage = () => {  
        
        if (!this.state.transactionsPage || this.state.transactionsPage.isFirst())
            return;
        
        this._fetchTransactions(this.state.transactionsPage.pageNo-1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    //fetch the next page
    _nextPage = () => {  
        
        if (!this.state.transactionsPage || this.state.transactionsPage.isLast())
            return;
        
        this._fetchTransactions(this.state.transactionsPage.pageNo+1); 
        window.scrollTo(0, 0);  //jump to the top
    }
    
    
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
    
    _checkboxStateChanged = (json, newValue) => {
        this.setState({typeCheckboxes: newValue});
    }
    

    _download = () => {
        this._fetchTransactions(0, true);  //fetch for download        
    }

    _refresh = () => {
        this._fetchTransactions(this.state.transactionsPage ? this.state.transactionsPage.pageNo : 0);   //just fetch the same page again
    }
    
    _search = () => {
        this._fetchTransactions(0, false);
    }

    _showAccount = (accountNo) => {
        this.secureJSONFetch("/act/account/" + accountNo, {}, this._fetchAccountCallback, this._fetchErrorCallback); 
    }

    _fetchAccountCallback = (response) => {
        if (response) {            
            const act = new Account(response);
            this.setState({accountInPopover: act, accountPopoverOpen: true});
        }            
    }

    _transactionReferencePopup = (id) => {
        this.secureJSONFetch("/act/transaction/" + id, {}, this._fetchSingleTransactionCallback, this._fetchErrorCallback); 
    }
    

    _fetchSingleTransactionCallback = (response) => {
        if (response) {
            const t = new Transaction(response);
            this.setState({transactionViewPopoverTransaction: t, transactionViewPopoverOpen: true})
        }
    }


    //Render a row of the table with the specified Transaction. In the Transaction is null, render the header row
    _renderRow = (transaction, key) => {
        
        //Render the header
        if (!transaction) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                    <th style={{...this.styles.tableHeader, width: 180}}>Date</th>
                    <th style={{...this.styles.tableHeader, width: 250, textAlign: 'center'}}>Type</th>
                    <th style={this.styles.tableHeader}></th>
                    <th style={{...this.styles.tableHeader, textAlign: 'center'}}>Account</th>
                    <th style={this.styles.tableHeader}>Detail</th>
                    {Global.isAGSAccountAdmin() ? <th style={{...this.styles.tableHeader, textAlign: 'right'}}>AGS Cost</th> : null}
                    <th style={{...this.styles.tableHeader, textAlign: 'right'}}>Amount</th>
                </tr>
            );
        }
      
      
        const dateAndTime = DateUtils.timeFormat(transaction.date);
        const isRevealed = this.state.revealSet.has(transaction);
        const leftIcon = isRevealed ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
        const detailSubType = transaction.detail.subType ? (": " + transaction.detail.subType) : "";

        const columns = Global.isAGSAccountAdmin() ? 8 : 7;      
                                
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableStyle}>

                    <td style={this.styles.tableData}>
                        <IconButton edge="start" onClick={() => this._revealTransactionDetail(transaction)}>
                            {leftIcon}
                        </IconButton>
                    </td>

                    <td style={this.styles.tableData}>{dateAndTime}</td>

                    <td style={this.styles.tableData}>
                        <Tooltip title={transaction.type.tooltip}>
                            <div style={{...this.styles.status, backgroundColor: transaction.type.backgroundColor}}>
                                {transaction.type.label}
                            </div>
                        </Tooltip>
                    </td>
                    <td style={this.styles.tableData}>{transaction.renderBadges()}</td>
                                      
                    <td style={{...this.styles.tableData, textAlign: 'center', marginRight: 0}}>
                        <Button onClick={() => this._showAccount(transaction.accountNo)}>{transaction.accountNo}</Button> 
                    </td>
                    <td style={this.styles.tableData}>{transaction.detail.detailClass + detailSubType}</td>
                    {Global.isAGSAccountAdmin() ? <td style={{...this.styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(transaction.agsCost)}</td> : null}
                    <td style={{...this.styles.tableData, textAlign: 'right'}}>{"$ " + Currency.round(transaction.amount)}</td>

                </tr>
                <tr style={this.styles.tableStyle}>
                    <td colSpan={columns} align='center' style={this.styles.tableDataWithBorder}>
                        <Collapse in={isRevealed}>
                            {transaction.renderDetailForTransactionList(this._transactionReferencePopup)}
                        </Collapse>
                    </td>
                </tr>
            </Fragment>
        );
    }
    
    
    _checkboxLabels = (function() {
        const checkboxLabels = [];
        for (let index in Transaction.Type) {
            const type = Transaction.Type[index];

            checkboxLabels.push({name: type.label, tooltip: null});
        } 
        return checkboxLabels;
    })();  //iffe
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
                                
        const page = this.state.transactionsPage;
       
        const showing = (page && page.transactions.length > 0) ? "Displaying " + (page.index + 1) + "-" + (page.index + page.transactions.length) : null;            
                
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}   

                <TransactionViewPopover isOpen={this.state.transactionViewPopoverOpen} transaction={this.state.transactionViewPopoverTransaction} 
                                        onClose={() => this.setState({transactionViewPopoverOpen: false})} onTransactionClick={this._transactionReferencePopup}/>

                <AccountViewPopover isOpen={this.state.accountPopoverOpen} account={this.state.accountInPopover} onClose={() => this.setState({accountPopoverOpen: false})}/>

                <ManualTransactionPopover isOpen={this.state.manualTransactionPopoverOpen} okCallback={this._manualTransaction} account={Global.accountID}
                                          cancelCallback={() => this.setState({manualTransactionPopoverOpen: false})} />      

                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'right', alignItems: 'center'}}>
                
                    <Tooltip title="View a specific Transaction by ID in a popup">
                                <TextField label="Transaction ID Quick View" style={{width: 300, marginRight: 'auto', marginBottom: 20}} fullWidth={false}
                                            value={this.state.idQuickSearch}
                                            onChange={(event) => {this.setState({idQuickSearch: event.target.value})}}
                                            onKeyDown={this._findByIDkeyDown}
                                            variant="outlined" InputLabelProps={{ shrink: true}} />
                    </Tooltip>

                    {this.state.isBusy ? this.getBusyComponent('left', {marginLeft: 20, padding: 15}, 30) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}

                    {Global.isAGSAccountAdmin() && Global.accountID ?
                        <Button size='small' onClick={() => this.setState({manualTransactionPopoverOpen: true})} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                            Post Manual Transaction
                        </Button>
                        : null
                    }
           
                </div>
                
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Search Transactions</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item md={4} sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                minYear={2020}
                                                ref={this._dateRangeRef}
                                                initialTimeRange="All"
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <ManageBitwiseCheckboxes style={{marginTop: -17}} json="typeCheckboxes" label="Types" labels={this._checkboxLabels} onChange={this._checkboxStateChanged} initialValue={this.state.typeCheckboxes} />
                            
                            <Tooltip title="Only include Transactions since last Statement">
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginLeft: 0}}>
                                        <Checkbox checked={this.state.sinceLastStatement} color='primary' disabled={Global.accountID === null}
                                                    onChange={(event) => { this.setState({sinceLastStatement: event.target.checked})}}/>   
                                        <Typography variant='body2' align='left' style={{color: 'gray'}}>Only Since Last Statement</Typography>   
                                </div>
                            </Tooltip>
                            <Tooltip title="Show Test Transactions as well as Live Transactions">
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: 0}}>
                                        <Checkbox checked={this.state.searchIncludesTest} color='primary'
                                                    onChange={(event) => { this.setState({searchIncludesTest: event.target.checked})}}/>   
                                        <Typography variant='body2' align='left' style={{color: 'gray'}}>Show Test Transactions</Typography>   
                                </div>
                            </Tooltip>
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <Tooltip title="Search text fields: patron/applicant information and notes (may be slow)">
                                <TextField label="Text Search" inputProps={{style: {fontSize: 14}}}
                                           onChange={(event) => this.setState({textSearch: event.target.value.trim()})} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        <Tooltip title="Search and Display Transactions below">
                            <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Tooltip>

                        <Tooltip title="Search and Download Transactions">
                            <Button fullWidth onClick={this._download} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<GetAppIcon/>}>
                                Download
                            </Button>
                        </Tooltip>

                    </div>

                </Paper>
                
                <div style={{marginTop: 15}}/>
                
                {page ?
                     <div>   
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {page.transactions.map((transaction, index) => this._renderRow(transaction, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {!page.isFirst() ? <Button onClick={this._prevPage} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {!page.isLast() ? <Button onClick={this._nextPage} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>                        

                    </div>
                    
                    
                : null}
                
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(TransactionsListPage));

