import { DateUtils } from 'react-frontend-utils'

export class Snapshot {

    //These from ID
    accountNo;
    date;

    balance;
    pastDue;
   
    constructor(json) {
        if (json) {
            this.accountNo = json.id.accountNo;
            this.date = DateUtils.parseJsonDate(json.id.date, true); //convert from UTC            
            this.balance = json.snapshotBalance;
            this.pastDue = json.pastDue;
        }
    }

    compareByDate = (other) => {
        return this.date - other.date;
    }

    compareByBalance = (other) => {     
        return this.balance - other.balance;
    }

    compareByOverdue = (other) => {
        return this.pastDue - other.pastDue;
    }

}

         