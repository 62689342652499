import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Typography, Tooltip, Checkbox } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Transaction } from '../models/Transaction';



export class ManualTransactionPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

 
    _okCallback;
    _cancelCallback;
    
    _initialType = Transaction.Type.PAYMENT;

    state = {
        type: this._initialType,
        typeValue: this._initialType,
        amount: null,
        commentEntry: null,
        correctedTransactionId: null,
        payoutElectronically: false,
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    _numberFieldChanged = (event) => {
        
        let val = parseFloat(event.target.value);
        if (val < 0)
            val = 0;            
        this.setState({amount: val});
    }
    
    _commentFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({commentEntry: val});
    }
    
    _correctedTransactionIdChanged = (event) => {
        let val = event.target.value;
        this.setState({correctedTransactionId: val});
    }
    
    _okButtonPressed = () => {

        let val = this.state.amount;  
        if (val === null)  //never changed
            val = 0;

        const payoutElectronically = this.state.type === Transaction.Type.PAYOUT ? this.state.payoutElectronically : false;

        this._okCallback(this.state.type.enumName, val, this.state.commentEntry, this.state.correctedTransactionId, payoutElectronically);
        this.setState({amount: null, commentEntry: null, correctedTransactionId: null});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({amount: null, commentEntry: null, correctedTransactionId: null});  //clear text for next time
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 700)
            maxWidth = 700;
                
        let val = this.state.amount === null ? 0 : this.state.amount;

        let types = Object.keys(Transaction.Type).map(key => Transaction.Type[key]);

        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{"Post Manual Transaction: Account " + this.props.account}</Typography>  
                    <div style={{marginTop: 20}}/>

                    <Autocomplete
                        size='small'
                        value={this.state.type}
                        getOptionLabel={(type) => type.label }
                        onChange={(event, newValue) => { this.setState({type: newValue}); }}
                        inputValue={this.state.typeValue}
                        onInputChange={(event, newValue) => { this.setState({typeValue: newValue}); }}
                        options={types}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Type" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    /> 


                    <Typography variant="body1" style={{fontStyle: 'italic', color: this.state.type.manualDescriptionColor, marginTop: 20}}>{this.state.type.manualDescription}</Typography>  

                    <Container style={this.styles.container}>    
                        <TextField label="Amount (USD)" type="number" value={val} onChange={this._numberFieldChanged} variant="outlined" fullWidth={false} InputLabelProps={{ shrink: true}} />
                    </Container>

                    <Tooltip title="If this Transaction is to amend or cancel an existing Transaction, enter that Transaction's ID here. It will be recorded for reference">
                        <TextField style={{marginBottom: 10}} label="Corrected Transaction ID" value={this.state.correctedTransactionId} onChange={this._correctedTransactionIdChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
                    </Tooltip>

                    {this.state.type === Transaction.Type.PAYOUT ?
                        <Tooltip title="Make an electronic payout immediately after Transaction is recorded">
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginBottom: 10}}>
                                <Checkbox checked={this.state.payoutElectronically} color='primary'
                                        onChange={(event) => { this.setState({payoutElectronically: event.target.checked})}}/>   
                                <Typography variant='body2' align='left' style={{color: 'gray'}}>Payout Electronically</Typography>
                            </div>
                        </Tooltip>   
                        : null
                    }

                    <TextField style={{marginTop: 10}} label="Note" autoFocus multiline={true} rows={5} value={this.state.commentEntry} onChange={this._commentFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
        
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!val} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}