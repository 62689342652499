import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Grid } from '@material-ui/core'
import { Typography, Paper } from '@material-ui/core'

import Chart from "react-apexcharts";

import { Account } from '../models/Account'
import { AccountStatWidget } from '../components/AccountStatWidget'

import { RestComponent, SummaryWidget } from 'react-frontend-utils' 

export class StatisticsPage extends RestComponent {

    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '12pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        delayLabel: {
            marginLeft: 15,
            marginBottom: 20,
            textAlign: 'right',
            fontStyle: 'italic',
            color: 'gray',
            fontSize: '12pt',
        }

    };

    _acquireYearChartOpts = {
        chart : {
            stacked: false,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 600,
                animateGradually: {
                    enabled: false
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        colors: ['#5a3fd2'],  
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "70%"
            }
        },
        xaxis: {
            categories: [],
        },
        dataLabels: {
            enabled: true,
        }
    };

    
    static START_YEAR = 2015;
    static currentYear = (new Date()).getFullYear();

    constructor(props) {
        super(props);
        this.state.accountStats = null;

        this._acquireYearChartOpts.xaxis.categories.push("N/A");
        for (let i = StatisticsPage.START_YEAR; i <= StatisticsPage.currentYear; i++)
            this._acquireYearChartOpts.xaxis.categories.push(i.toString());
    }

    componentDidMount() {
        super.componentDidMount();
        this._fetchStatistics();
    }


    _fetchStatistics = () => {
                
        this.incrementBusy();
        this.secureJSONFetch("/act/statistics", {}, this._fetchStatisticsCallback, this._fetchErrorCallback);     
    }
    
    _fetchStatisticsCallback = (response) => {
        if (response) {            
            this.setState({accountStats: response});
        }            
        this.decrementBusy();
    }
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }


    render() {
        const stat = this.state.accountStats;

        if (!stat)
            return this.getConfirmAlertComponent();

        const colorNone = '#AAAAAA';
        const colorGreen = '#45bf4b';
        const colorYellow = '#e8d900';
        const colorBlue = '#1f99ff';

        const seriesColors = ['#37b37f', colorBlue, '#a348db', '#ff7099', colorYellow];

        const freePlanPie = [{label: "Paid Plan", value: stat.totalAccounts - stat.checkInDisabledCount},
                           {label: "Free Plan", value: stat.checkInDisabledCount}];
        const planColors = [colorGreen, colorYellow];

        const marketplacePie = [{label: "Enabled", value: stat.marketplaceEnabledCount},
                                {label: "Disabled", value: stat.totalAccounts - stat.marketplaceEnabledCount}];
        const marketColors = [colorGreen, colorNone];

        const noApplications = stat.totalAccounts -  stat.applicationFullServiceCount - stat.applicationSelfServiceCount;
        const applicationsPie = [{label: "Self Service", value: stat.applicationSelfServiceCount},
                                 {label: "Full Service", value: stat.applicationFullServiceCount},
                                 {label: "None", value: noApplications}];
        const applicationColors = [colorBlue, colorGreen, colorNone];


        const ePassesPie = [{label: "Enabled", value: stat.ePassesEnabledCount},
                          {label: "Disabled", value: stat.totalAccounts - stat.ePassesEnabledCount}];
        const ePassesColors = [colorGreen, colorNone];

        const noPassPrintingCount = stat.totalAccounts -  stat.directMailCount - stat.bulkMailCount;
        const classicPassMailingPie = [{label: "Direct Mailed", value: stat.directMailCount},
                                       {label: "Bulk Mailed", value: stat.bulkMailCount},
                                       {label: "None", value: noPassPrintingCount}];
        const passesColors = [colorBlue, colorGreen, colorNone];


        const mergedDocPie = [{label: "Enabled", value: stat.mergedDocumentCount},
                              {label: "Disabled", value: stat.totalAccounts - stat.mergedDocumentCount}];
        const mergedColors = [colorGreen, colorNone];
                    
        let statePie = [];
        let stateColors = [];
        // Fill the arrays based on the State enum
        for (const stateEnum of Object.values(Account.State)) {
            statePie[stateEnum.bit] = {label: stateEnum.label, value: 0};
            stateColors[stateEnum.bit] = stateEnum.color;
        }

        for (const stateStat of stat.stateCount) {
            const stateEnum = Account.stateFromString(stateStat.state);
            if (!stateEnum) {
                console.error("Unknown state: " + stateStat.state);
                continue;
            }
            statePie[stateEnum.bit].value = stateStat.count;
        }
                
        let accountRepPie = [];
        for (const rep of stat.accountRepCount)
            accountRepPie.push({label: rep.name ? rep.name : "Unassigned", value: rep.count});
        
        let payoutModePie = [];
        for (const mode of stat.payoutModeCount)
            payoutModePie.push({label: mode.payoutMode, value: mode.count});
            
        const autoPayPie = [{label: "Enrolled", value: stat.autoPaySignedUpCount},
                            {label: "Not Enrolled", value: stat.totalAccounts - stat.autoPaySignedUpCount}];
        const autoPayColors = [colorGreen, colorNone];


        const customFeePie = [{label: "Has Custom Fees", value: stat.hasCustomFees},
                              {label: "Uses Default Fees", value: stat.totalAccounts - stat.hasCustomFees}];
        const customFeeColors = [colorYellow, colorBlue];

   

        let yearAcquiredSeries = [];
        for (let i = 0; i < this._acquireYearChartOpts.xaxis.categories.length; i++)
            yearAcquiredSeries[i] = 0;

        for (const acq of stat.yearAcquiredCount) {
            if (acq.year === 0)
                yearAcquiredSeries[0] += acq.count;        // first element is "N/A"
            else {
                const index = acq.year - StatisticsPage.START_YEAR + 1;
                if (index < 1 || index > StatisticsPage.currentYear) {     // unknown year
                    console.error("Year out of range: " + acq.year);
                    yearAcquiredSeries[0] += acq.count;
                }
                else
                    yearAcquiredSeries[index] += acq.count;
            }
        }

        const yearAcquiredChartData = [
            {
                name: "Accounts Acquired",
                data: yearAcquiredSeries
            }
        ];


        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}  

                <Typography variant="body2" style={this.styles.delayLabel}>Statistics delayed by up to 30 minutes</Typography>  

                <Grid container direction="row" spacing={2} style={{padding: 10, marginTop: 10, justifyContent: 'center'}}>
                    <Grid item xs={3}>
                        <SummaryWidget label={"Total Real Accounts"}
                                    value={stat.totalAccounts}
                                    tooltip="Number of real Accounts (not Trial, Test, or Void)"
                                    borderColor='black'/>
                    </Grid>
                    <Grid item xs={3}>
                        <SummaryWidget label={"Databases Suspended"}
                                    value={stat.suspendedCount}
                                    tooltip="Number of real Accounts suspended in PoolPass"
                                    borderColor='red'/>
                    </Grid>
                </Grid>

                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Features</Typography>  

                    <Grid container direction="row" spacing={2} style={{padding: 10, marginTop: 10, justifyContent: 'center'}}>

                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Account State"} items={statePie} pieColors={stateColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Membership Portal Plan"} items={freePlanPie} pieColors={planColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Marketplaces Enabled"} items={marketplacePie} pieColors={marketColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Application Processing"} items={applicationsPie} pieColors={applicationColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"ePasses Enabled"} items={ePassesPie} pieColors={ePassesColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Pass Printing"} items={classicPassMailingPie} pieColors={passesColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Merged Documents"} items={mergedDocPie} pieColors={mergedColors}/>
                        </Grid>

                    </Grid>
                </Paper>


                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Settings</Typography>  

                    <Grid container direction="row" spacing={2} style={{padding: 10, marginTop: 10, justifyContent: 'center'}}>

                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Account Representative"} items={accountRepPie} pieColors={seriesColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Payout Mode"} items={payoutModePie} pieColors={seriesColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Automatic Payments"} items={autoPayPie} pieColors={autoPayColors}/>
                        </Grid>
                        <Grid item lg={3} md={6}>
                            <AccountStatWidget label={"Custom Fees"} items={customFeePie} pieColors={customFeeColors}/>
                        </Grid>

                    </Grid>
                </Paper>

                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Acquisition Year</Typography>  

                    <Grid container direction="row" spacing={2} style={{padding: 10, marginTop: 10, justifyContent: 'center'}}>

                        <Grid item xs={12}>
                            <Chart
                                options={this._acquireYearChartOpts}
                                series={yearAcquiredChartData}
                                type="bar"
                                height={window.innerHeight*0.4}
                            />
                        </Grid>

                    </Grid>
                </Paper>

            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(StatisticsPage));
