import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Typography, Link } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import AGTheme, { ThemeColors } from '../Theme'
import { RestComponent } from 'react-frontend-utils'
import { logo } from '../App'



export class Onboard extends RestComponent {
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }

    _id; 
    _hmac;
    _extime;
    _database;

    _paymentAmountRef = React.createRef();

    constructor(props) {
        super(props);
        this._updateSize();
            window.addEventListener("resize", this._updateSize);
       
        this._id = props.id ? decodeURIComponent(props.id) : null;  //Arrives with the query string set to the desired id
        this._hmac = props.hmac ? decodeURIComponent(props.hmac) : null;    //Arrives with an hmac
        this._extime = props.extime ? decodeURIComponent(props.extime) : null;
        this._database = props.database ? decodeURIComponent(props.database) : null;

        this.state.onboardLink = null;
        this.state.didFetch = false;
    }
    
    
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 600 });  //custom, split between bootstrap and mui
    }

    /**
     * When the page loads, immediately fetch the onboarding link
     */
    componentDidMount() {
        super.componentDidMount();       
  
        if (!this._id || !this._hmac || !this._extime) {
            this.showConfirmAlert("No Onboard ID", "You did not arrive with a valid link from your Onboarding ID", 'red');
        }
        else
            this._fetchOnboardLink(); 
        
    }
    
    _fetchOnboardLink = () => {
        
        this.incrementBusy();
        
        const queryString = "?hmac=" + this._hmac + "&extime=" + this._extime + "&database=" + this._database;
        
        this.secureJSONFetch("/stripeonboard/" + this._id + "/onboardLink", {},
                             this._fetchOnboardLinkCallback, this._fetchErrorCallback, queryString); 
    }
    
    _fetchOnboardLinkCallback = (response) => {

        if (response) {            
            this.setState({onboardLink: response, didFetch: true});       
        }
        this.decrementBusy();
    }
   
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
        this.setState({didFetch: true});       
    }
   
    
    render() {

        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = this.state.isMobile ? 8 : 20;

        return (
            <ThemeProvider theme={AGTheme}>
                <Fragment>

                    {this.getConfirmAlertComponent()  /*inject an alert component*/}  
                    
                    <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                        <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                            <Toolbar disableGutters={true}>
                                {logo}
                                <Typography variant="h5" style={this.styles.appTitle}>Payout Account Onboarding</Typography>
                            </Toolbar>
                        </div>
                    </AppBar>
                    
                    {serverErrorMessage}

                    <Typography variant="h4" align='center' style={{marginTop: 20, marginBottom: 20}}>Welcome to Access Granted Systems Connected Payout Account</Typography>
                      
                    {this.state.onboardLink ?
                        <Fragment>
                            <Typography variant="body1" align='center' style={{marginLeft: '15%', marginRight: '15%'}}> When your community collects fees on an application or marketplace we can transfer payouts to your bank electronically. Our electronic payouts are 
                                powered by Stripe. Click the link below to get started.</Typography>

                            <Typography align='center' style={{marginTop: 40}}>
                                <Link rel="noopener noreferrer" target="_blank" href={this.state.onboardLink} variant="h5" align='center'>{this.state.onboardLink}</Link>
                            </Typography>
                        
                            <Typography variant='body2' align='center' style={{marginTop: 30, fontStyle: 'italic', color: 'gray'}}>This link can only be clicked once. Refresh the browser page to generate a new link.</Typography>
                        </Fragment>
                        :
                        <Fragment>
                            {this.state.didFetch ?
                                <Typography variant="body1" align='center' style={{color: 'red', marginLeft: '15%', marginRight: '15%'}}>Your link is expired or invalid. Please request another from your account representative.</Typography>
                                :
                                null
                            }
                        </Fragment>
                    }

                    {this.getBusyComponent("center", {marginTop: 15})}

                </Fragment>
            </ThemeProvider>    
        );
    }
  
}

export default withCookies(withRouter(Onboard));



