import React, { useState, useRef } from 'react';


import { IconButton, Tooltip, Typography } from '@material-ui/core'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SignatureCanvas from 'react-signature-canvas'
import { ThemeColors } from '../Theme';


export function SignatureForm(props) {
        
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);  //initial window width, when rendered 

    const sigCanvas = useRef(null);  //Reference to the Signature component so we can call its API
        
    const strokeComplete = () => {  //called when user finishes one stroke, we have at least a fragment 
        
        const trimmedImage = sigCanvas.current.toDataURL('image/png');
        props.signatureDrawn(trimmedImage);
    }

    const clear = () => {
        sigCanvas.current.clear();  //clear the canvas
        props.signatureDrawn(null);

    };

    const iconLabelSize =  11;
    const iconSize = "large";

    const hasSignature = props.signature && props.signature.length > 0;

    let canvasWidth = window.innerWidth;
    const canvasHeight = 300;

    //when resized, the internal canvas clears (annoying!) so we must redraw the image - the ratio is required!
    if (window.innerWidth !== windowWidth) { 
        setWindowWidth(window.innerWidth);
        if (hasSignature && sigCanvas.current)
            setTimeout(sigCanvas.current.fromDataURL(props.signature, {ratio: 1}), 10);
    }

    return (
        <div style={{marginBottom: 10}}>
        
            <div style={{border: '1px solid gray', backgroundColor: ThemeColors.signaturePadColor, minHeight: canvasHeight}}>
                <SignatureCanvas ref={sigCanvas} 
                                    penColor={ThemeColors.signatureStrokeColor} 
                                    onEnd={strokeComplete}
                                    canvasProps={{width: canvasWidth, height: canvasHeight}}
                                />
                
            </div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right', marginRight:10}}>
                <Tooltip title="Clear Signature">
                    <IconButton disabled={!hasSignature} onClick={clear} >
                        <ClearOutlinedIcon fontSize={iconSize} style={{color: hasSignature ? ThemeColors.darkRed : 'lightGray'}}/>
                    </IconButton>
                </Tooltip>
                <Typography align='center' style={{color: ThemeColors.darkGray, fontSize: iconLabelSize}}>Clear</Typography>
            </div>
        </div>

    );
}