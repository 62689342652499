import { DateUtils } from 'react-frontend-utils'

export class Revenue {

    date;
    cost;
    profit;
   
    constructor(json) {
        if (json) {
            this.date = DateUtils.parseJsonDate(json.date, true); //convert from UTC            
            this.profit = json.revenue - json.agsCost;
            this.cost = json.agsCost;
        }
    }

    compareByDate = (other) => {
        return this.date - other.date;
    }

    


}

         