


//Holds information on each Aggregate item - the key (Marketplace, Invoice, etc.) the count for the month, amount, and cost
export class Ag {
    key;
    count;
    amount;
    frontendCost;

    constructor(json) {
        if (json) { 
            this.key = json.key;
            this.count = json.count;
            this.amount = json.amount;
            this.frontendCost = json.frontendCost;
        }
        else {
            this.count = 0;
            this.amount = 0;
            this.frontendCost = 0;
        }
    }

    //Accumulate totals by adding those from another Ag object
    accumulate(ag) {
        this.count += ag.count;
        this.amount += ag.amount;
        this.frontendCost += ag.frontendCost;
    }
}

// Aggregate class holding Aggregate information received from the backend. This is not used directly by the front end, because an Aggregate contains
// data from a single month across across all Transaction types (payout, patronSales, etc.) and sub-categories (Invoice, Marketplace, etc.).  
// In order to properly graph this we must create a PivotTable object that collects homogeneous data across a time period.
export class Aggregate {

    //These from ID
    accountNo;
    date;

    credits;

    //Arrays of Ag, one per category that has a positive amount
    payments;
    payouts;
    patronSales;
    fees;
    subscriptions;

    constructor(json) {
        if (json) {
            this.accountNo = json.id.accountNo;
            this.date = new Date(parseInt(json.id.date.year), parseInt(json.id.date.month) - 1, 1);  //0-based month index

            this.credits = json.credits;

            this.payments = json.payments.map(payment => new Ag(payment));
            this.payouts = json.payouts.map(payout => new Ag(payout));
            this.patronSales = json.patronSales.map(patronSale => new Ag(patronSale));
            this.fees = json.fees.map(fee => new Ag(fee));
            this.subscriptions = json.subscriptions.map(subscription => new Ag(subscription));
        }
    }


    compare = (other) => {
        return this.date - other.date;
    }
    
}



// Here we are going to construct a PivotTable by taking an array of Aggregate objects, where each Aggregate object represents data from all 
// categories in a single month, and create "pivotItems" containing data across months.
//
// A pivotItem is a Transaction type (patronSales, fees, etc.) that contains data across months for each category and the total sum across categories,
// and the total for the type across all categories and months:
//      total: the total amount for the Transaction type
//      map: a map of category (key) to an array (value), where each element in the array holds a total, and an array of [month, amount] (the date and amount for that month)
//      totalMonthSeries: this temporarily holds a map of months to totals across all categories for that month, for summing purposes. Once the sum
//                        is done, it is used to add an "All" category key to the map and this is no longer used
//  
//
// Note that we don't graph Credits, those are just summed
export class PivotTable {

    patronSales = {total: new Ag(), map: new Map(), totalMonthSeries: new Map()};
    fees =  {total: new Ag(), map: new Map(), totalMonthSeries: new Map()};
    subscriptions =  {total: new Ag(), map: new Map(), totalMonthSeries: new Map()};
    credits = 0;
    payments =  {total: new Ag(), map: new Map(), totalMonthSeries: new Map()};
    payouts =  {total: new Ag(), map: new Map(), totalMonthSeries: new Map()};

    monthlyProfit = {total: null, map: new Map()};  //pseudo series, for graphings

    //Here we are taking items from a single month (the date), finding the category
    //key in the pivotItem map, and adding to its monthseries array
    //if this is an item that generates profit we'll add the profit to it (amount less AGS cost), otherwise, we just take away the ags cost)
    static updatePivotMap(pivotItem, date, agArray, profitMap, isProfitItem) {

        let total = 0;
        let profit = 0;
        for (const ag of agArray) {
            const newMonthEntry = [date, ag.amount];    //the amount on this month, for this Ag category

            if (ag.key === "All") { //sanity check
                console.error("Cannot have a key named 'All'");
                return;
            }

            if (!pivotItem.map.has(ag.key)) {    //don't have this category key yet? add it to map with an initial array
                pivotItem.map.set(ag.key, {total: ag.amount, monthSeries: [newMonthEntry]});
            }
            else {  //update, by pushing the amount of this month to the key's series
                const value = pivotItem.map.get(ag.key);
                value.monthSeries.push(newMonthEntry);  //add this month's data to the monthSeries array
                value.total += ag.amount;   //up our total
            }
            pivotItem.total.accumulate(ag); 
            total += ag.amount;
            if (isProfitItem)
                profit += ag.amount - ag.frontendCost;      // is a profit item, add to our profit, less our cost
            else
                profit -= ag.frontendCost;      // not a profit item, just take away our cost from our profit
        }

        //Update our total month series with the total for this month
        if (!pivotItem.totalMonthSeries.has(date))
            pivotItem.totalMonthSeries.set(date, total);
        else {
            let existingTotal = pivotItem.totalMonthSeries.get(date);
            pivotItem.totalMonthSeries.set(date, total + existingTotal);
        }

        if (!profitMap.has(date))
            profitMap.set(date, profit);
        else {
            let existingProfit = profitMap.get(date);
            profitMap.set(date, existingProfit + profit);
        } 
        
        return pivotItem.map;
    }


    static addTotalMonthSeriesAsAllKey(pivotItem) {
        if (pivotItem.map.size < 2) //let's skip an "All" key if there's not more than 1 key
            return;

        //Generate an array of dates to total values from the totalMonthSeries map
        const allDateKeys = Array.from(pivotItem.totalMonthSeries.keys());
        let series = [];
        for (const date of allDateKeys) {
            series.push([date, pivotItem.totalMonthSeries.get(date)]);
        }

        //Now create our "All" key which is the sums of all the other keys
        pivotItem.map.set("All", {total: pivotItem.total.amount, monthSeries: series});
    }

    constructor(aggregateArray) {

        const profitMap = new Map();

        //For each aggregate (unique month) in the aggregateArray, update our pivotMap
        for (let aggregate of aggregateArray) {

            PivotTable.updatePivotMap(this.patronSales, aggregate.date, aggregate.patronSales, profitMap, false);
            PivotTable.updatePivotMap(this.fees, aggregate.date, aggregate.fees, profitMap, true);
            PivotTable.updatePivotMap(this.subscriptions, aggregate.date, aggregate.subscriptions, profitMap, true);
            PivotTable.updatePivotMap(this.payments, aggregate.date, aggregate.payments, profitMap, false);
            PivotTable.updatePivotMap(this.payouts, aggregate.date, aggregate.payouts, profitMap, false);

            this.credits += aggregate.credits;

            // Take away credits from our profit
            if (!profitMap.has(aggregate.date))
                profitMap.set(aggregate.date, -aggregate.credits);
            else {
                let existingProfit = profitMap.get(aggregate.date);
                profitMap.set(aggregate.date, existingProfit - aggregate.credits);
            } 

        }

        //Take the totalMonthSeries maps, and create an "All" category key in the map
        PivotTable.addTotalMonthSeriesAsAllKey(this.patronSales);
        PivotTable.addTotalMonthSeriesAsAllKey(this.fees);
        PivotTable.addTotalMonthSeriesAsAllKey(this.subscriptions);
        PivotTable.addTotalMonthSeriesAsAllKey(this.payments);
        PivotTable.addTotalMonthSeriesAsAllKey(this.payouts);


        //Create a series for the profit
         const allDateKeys = Array.from(profitMap.keys());
         let series = [];
         for (const date of allDateKeys)
             series.push([date, profitMap.get(date)]);
 
         //Now create our "All" key which is the sums of all the other keys
         this.monthlyProfit.map.set("All", {monthSeries: series});


        //Full sums
        this.revenue = this.fees.total.amount + this.subscriptions.total.amount - this.credits;
        this.costs = this.patronSales.total.frontendCost + 
                    this.fees.total.frontendCost + 
                    this.subscriptions.total.frontendCost + 
                    this.payments.total.frontendCost + 
                    this.payouts.total.frontendCost;

        this.profit = this.revenue - this.costs;
    }



}



export class AggregateSum {

    accountNo;
    revenue;
    patronSales;

    constructor(aggregate) {

        this.accountNo = aggregate.accountNo;

        // sum up all types of fees and subscriptions for this account
        const revenueAg = new Ag();
        for (const ag of aggregate.fees)
            revenueAg.accumulate(ag);
        for (const ag of aggregate.subscriptions)
            revenueAg.accumulate(ag);

        const pSalesAg = new Ag();
        for (const ag of aggregate.patronSales)
            pSalesAg.accumulate(ag);

        this.revenue = revenueAg.amount - aggregate.credits;
        this.patronSales = pSalesAg.amount;
    }

    //Sort largest to smallest
    compareByRevenue = (other) => {
        return other.revenue - this.revenue;
    }

    //Sort largest to smallest
    compareByPatronSales = (other) => {
        return other.patronSales - this.patronSales;
    }

}