import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { IconButton, Tooltip, Grid, Typography, Paper, Button, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';

import { ThemeColors } from '../Theme'
import { Journal } from '../models/Journal'
import { PagedJournalList } from '../models/PagedJournalList'
import { Account } from '../models/Account';

import { RestComponent } from 'react-frontend-utils' 

import { DateUtils, ManageBitwiseCheckboxes, DateRangeSelector } from 'react-frontend-utils'
import { ManualJournalPopover } from '../components/ManualJournalPopover';
import { AccountSelectPopover } from '../components/AccountSelectPopover';
import { AccountViewPopover } from '../components/AccountViewPopover';



export class JournalListPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        id: {
            marginLeft: 'auto',
            maxWidth: 300,
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            paddingRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 150,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
       
    };
    
    

    
     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 2 days", startTime: DateUtils.startOfToday().valueOf() - (2 * DateUtils.MS_PER_DAY)},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "All", startTime: null}
    ];
    
    _fetchNewestFirst = true;  //initially, fetch newest first

    constructor(props) {
        super(props);
    
                
        this.state.journalsPage = null;         //last fetched page - a PagedJournalList
               
        this.state.searchFromDate = null;           //JsonDateString or null
        this.state.searchToDate = null;            //JsonDateString or null
        
        this.state.typeCheckboxes = 0;             //Bitwise selected types to search for, from checkboxes only!
        this.state.severityCheckboxes = 0;         //Bitwise selected severities to search for, from checkboxes only!
        this.state.textSearch = "";

        this.state.accountNo = null;
        this.state.manualJournalPopoverOpen = false;
        this.state.accountPopoverOpen = false;

        this.state.accountViewOpen = false;
        this.state.accountInPopover = null;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of journals
     */
    componentDidMount() {
        super.componentDidMount();
        //fetch all journals, first page
        this._fetchJournals();  
    }
    
    
    //Fetch a specific page, using search fields if set.
    //If forDownload is set, download as csv, otherwise normal fetch to display
    _fetchJournals = (page = 0, forDownload = false) => {
        

        let queryString = "?";
        if (this.state.accountNo)
            queryString += "accountNo=" + this.state.accountNo + "&"; 
                        
        if (this.state.typeCheckboxes)  //type checkboxes are set
            queryString += "types=" + this.state.typeCheckboxes + "&";
        if (this.state.severityCheckboxes)  //severity checkboxes are set
            queryString += "severities=" + this.state.severityCheckboxes + "&";
        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
        }
        if (this.state.textSearch)
            queryString += "entry=" + this.state.textSearch + "&";

        queryString += "page=" + page + "&sortNewestFirst=" + this._fetchNewestFirst;
        
        this.incrementBusy();

        if (forDownload) {
            const filename = "Journal Export on " + DateUtils.downloadTimeString();
            this.secureFileDownload("/act/journal/download", filename, null, this._fetchErrorCallback, 'red'); 
        }
        else
            this.secureJSONFetch("/act/journal", {}, this._fetchJournalsCallback, this._fetchErrorCallback, queryString); 
                
    }
    
    //Callback for fetching Journals - response is json of PagedJournalList
    _fetchJournalsCallback = (response) => {
        if (response) {            
            const jl = new PagedJournalList(response);
            this.setState({journalsPage: jl});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
    _showAccount = (accountNo) => {
        this.secureJSONFetch("/act/account/" + accountNo, {}, this._fetchAccountForViewCallback, this._fetchErrorCallback); 
    }

    _fetchAccountForViewCallback = (response) => {
        if (response) {            
            const act = new Account(response);
            this.setState({accountInPopover: act, accountViewOpen: true});
        }            
    }


    _manualJournal = (severity, accountNo, entry) => {
        
        this.setState({manualJournalPopoverOpen: false});

        const userEntry = {severity: severity, accountNo: accountNo, entry: entry};
    
        this.incrementBusy();
        this.secureJSONFetch("/act/journal" , {method: "POST", body: JSON.stringify(userEntry)}, 
        this._manualJournalCallback, this._fetchErrorCallback); 
    }


    _manualJournalCallback = (response) => {
        this.decrementBusy();
        this._refresh();
    }

 
    //fetch the previous page
    _prevPage = () => {  
        
        if (!this.state.journalsPage || this.state.journalsPage.isFirst())
            return;
        
        this._fetchJournals(this.state.journalsPage.pageNo-1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    //fetch the next page
    _nextPage = () => {  
        
        if (!this.state.journalsPage || this.state.journalsPage.isLast())
            return;
        
        this._fetchJournals(this.state.journalsPage.pageNo+1); 
        window.scrollTo(0, 0);  //jump to the top
    }
    
    
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
    
    _typeCheckboxStateChanged = (json, newValue) => {
        this.setState({typeCheckboxes: newValue});
    }

    _severityCheckboxStateChanged = (json, newValue) => {
        this.setState({severityCheckboxes: newValue});
    }
    

    _selectAccount = (community) => {
        this.setState({accountPopoverOpen: false});

        if (community == null) {
            this.setState({accountNo: null});
            return;
        }

        //Fetch the Account
        this.secureJSONFetch("/act/account?group=" + community, {}, this._fetchAccountCallback, this._fetchErrorCallback); 
    }

    _fetchAccountCallback = (response) => {
        if (response) {
            this.setState({accountNo: response.accountNo});
        }
    }

    _download = () => {
        this._fetchJournals(0, true);  //fetch for download        
    }

    _refresh = () => {
        this._fetchJournals(this.state.journalsPage ? this.state.journalsPage.pageNo : 0);   //just fetch the same page again
    }
    
    _search = () => {
        this._fetchJournals(0, false);
    }

    
    //Render a row of the table with the specified Journal. In the Journal is null, render the header row
    _renderRow = (journal, key) => {
        
        //Render the header
        if (!journal) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, width: 180}}>Date</th>
                    <th style={{...this.styles.tableHeader, width: 250, textAlign: 'center'}}>Type</th>
                    <th style={{...this.styles.tableHeader, width: 250, textAlign: 'center'}}>Severity</th>
                    <th style={this.styles.tableHeader}>Account</th>
                    <th style={this.styles.tableHeader}>Entry</th>
                </tr>
            );
        }
      
        const columns = 5;
        const dateAndTime = DateUtils.timeFormat(journal.date);
                                
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableStyle}>

                    <td style={this.styles.tableData}>{dateAndTime}</td>

                    <th style={{...this.styles.tableData, width: 250, textAlign: 'center'}}>{journal.type.label}</th>

                    <td style={this.styles.tableData}>
                        <div style={{...this.styles.status, backgroundColor: journal.severity.backgroundColor}}>
                            {journal.severity.label}
                        </div>
                    </td>

                    { journal.accountNo ?
                        <td style={{...this.styles.tableData, textAlign: 'center'}}>
                            <Button style={{height: 20}} onClick={() => this._showAccount(journal.accountNo)}>{journal.accountNo}</Button> 
                        </td>
                        :
                        <td style={{...this.styles.tableData, height: 20}}/>
                    }

                    <td style={this.styles.tableData}>{journal.entry}</td>

                </tr>
                <tr style={this.styles.tableStyle}>
                    <td colSpan={columns} align='center' style={this.styles.tableDataWithBorder}/>
                </tr>
            </Fragment>
        );
    }
    
    
    _typeCheckboxLabels = (function() {
        const checkboxLabels = [];
        for (let index in Journal.Type) {
            const type = Journal.Type[index];

            checkboxLabels.push({name: type.label, tooltip: null});
        } 
        return checkboxLabels;
    })();  //iffe


    _severityCheckboxLabels = (function() {
        const checkboxLabels = [];
        for (let index in Journal.Severity) {
            const sev = Journal.Severity[index];

            checkboxLabels.push({name: sev.label, tooltip: null});
        } 
        return checkboxLabels;
    })();  //iffe
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
                                
        const page = this.state.journalsPage;
       
        const showing = (page && page.journals.length > 0) ? "Displaying " + (page.index + 1) + "-" + (page.index + page.journals.length) : null;            
                
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}   

                <AccountSelectPopover isOpen={this.state.accountPopoverOpen} okCallback={(community) => this._selectAccount(community)} 
                                                  cancelCallback={() => this.setState({accountPopoverOpen: false})}/>  

                <ManualJournalPopover isOpen={this.state.manualJournalPopoverOpen} okCallback={this._manualJournal} cancelCallback={() => this.setState({manualJournalPopoverOpen: false})} />          


                <AccountViewPopover isOpen={this.state.accountViewOpen} account={this.state.accountInPopover} onClose={() => this.setState({accountViewOpen: false})}/>


                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'right', alignItems: 'center'}}>
                

                    {this.state.isBusy ? this.getBusyComponent('left', {marginLeft: 20, padding: 15}, 30) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}

                    <Button size='small' onClick={() => this.setState({manualJournalPopoverOpen: true})} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                        User Journal Entry
                    </Button>
                
                </div>
                
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Search Journals</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item md={4} sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                minYear={2020}
                                                ref={this._dateRangeRef}
                                                initialTimeRange="All"
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <ManageBitwiseCheckboxes style={{marginTop: -17}} json="typeCheckboxes" label="Types" labels={this._typeCheckboxLabels} onChange={this._typeCheckboxStateChanged} initialValue={this.state.typeCheckboxes} />
                            <TextField label="Entry (Text Search)" style={{marginTop: 30, minWidth: 300}} fullWidth={false}
                                            value={this.state.textSearch}
                                            onChange={(event) => {this.setState({textSearch: event.target.value})}}
                                            variant="outlined" InputLabelProps={{ shrink: true}} />
                        
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <ManageBitwiseCheckboxes style={{marginTop: -17}} json="severityCheckboxes" label="Severity" labels={this._severityCheckboxLabels} onChange={this._severityCheckboxStateChanged} initialValue={this.state.severityCheckboxes} />
                        
                            <Button fullWidth onClick={() => this.setState({accountPopoverOpen: true})} variant="outlined" color='primary' style={{marginTop: 30}} component="label">
                                {"Account: " + (this.state.accountNo ? this.state.accountNo : " All")}
                            </Button>
  
                        </Grid>

                    </Grid>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                        <Tooltip title="Search and Display Journals below">
                            <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Tooltip>

                        { /** Not going to implement this now
                        <Tooltip title="Search and Download Journals">
                            <Button fullWidth onClick={this._download} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<GetAppIcon/>}>
                                Download
                            </Button>
                        </Tooltip>
                         **/ }
                    </div>

                </Paper>
                
                <div style={{marginTop: 15}}/>
                
                {page ?
                     <div>   
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {page.journals.map((journal, index) => this._renderRow(journal, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {!page.isFirst() ? <Button onClick={this._prevPage} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {!page.isLast() ? <Button onClick={this._nextPage} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>                        

                    </div>
                    
                    
                : null}
                
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(JournalListPage));

