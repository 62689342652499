export class Subscription {

    static FirstPayment = {

        ON_SCHEDULE:        {enumName: "ON_SCHEDULE", bit: 0, label: "On Schedule", tooltip: "The first payment is scheduled normally on the next recur date"},  
        IMMEDIATE:          {enumName: "IMMEDIATE", bit: 1, label: "Immediately", tooltip: "The first payment is scheduled immediately"},
        PRORATE:            {enumName: "PRORATE", bit: 3, label: "Prorated", tooltip: "The first payment is scheduled immediately, but pro-rated"},
    };
    
    
      
    name;
    description;
    recurMonth;             
    recurDay; 
    firstPayment;
    amount;
    agsCost;            //only passed sometimes
 
    constructor(json) {
        if (json) {        
            this.name = json.name;
            this.description = json.description;
            this.recurMonth = json.recurMonth;
            this.recurDay = json.recurDay;
            this.amount = json.amount;
            this.agsCost = json.frontendCost;
            this.firstPayment = Subscription.firstPaymentFromString(json.firstPayment);                 
        }
    }

    
    
    static firstPaymentFromString(str) {
        for (let index in Subscription.FirstPayment) {
            const fp = Subscription.FirstPayment[index];

            if (fp.enumName === str) {
                return fp;
            }
        }  
        return null;
    }


    static nth(val) {
        switch (val) {
            case 1:
            case 21:
                return val + "st";
            case 2:
            case 22:
                return val + "nd";
            case 3:
            case 23:
                return val + "rd";
            default:
                return val + "th";
        }
    }


    static monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    static describe(recurMonth, recurDay, firstPayment) {
        const month = parseInt(recurMonth);
        const day = parseInt(recurDay);

        let infoText = "";
        if (day > 0 && day < 31 && month >= 0 && month <= 12) {
            if (month === 0)
                infoText = "This is a subscription that recurs each month, billed on the " + Subscription.nth(day) + " day of the month. ";
            else 
                infoText = "This is a subscription that recurs each year, billed on " + Subscription.monthNames[month-1] + " " + Subscription.nth(day) + ". ";
        
            if (firstPayment)
                infoText += firstPayment.tooltip + ".";

            if (firstPayment === Subscription.FirstPayment.IMMEDIATE)
                infoText += " The next payment is due the following " + (month === 0 ? "month." : "year.")

            if (firstPayment === Subscription.FirstPayment.PRORATE)
                infoText += " The pro-rata amount is calculated from now until the recur date next " + (month === 0 ? "month." : "year.")
        }

        return infoText;
    }

    describe() {
        return Subscription.describe(this.recurMonth, this.recurDay, this.firstPayment);
    }
    
}