
import { Statement } from './Statement'

export class PagedStatementList {
    
    
    pageNo;
    isLastPage;
    
    index;
    
    statements;
    
    //Construct from the backend Json Response
    constructor(json) {
        if (json) {
            this.isLastPage = json.last; 
            this.pageNo = json.pageNo; 
            this.index = json.index;
            
            if (json.items)
                this.statements = json.items.map(s => new Statement(s));
            else
                this.statements = [];
            
        }  
    } 
    
    isFirst = () => {
        return this.pageNo === 0;
    }
    
    isLast = () => {
        return this.isLastPage;
    }
    
}